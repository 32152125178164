import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, Snackbar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IProduct, IError, IProductOptions } from '../../../models'
import {
  getProductOptions,
  postProduct,
  cancelGetProductOptions,
  cancelPostProduct
} from '../../../services/ApiService'
import { ProductDetail } from './ProductDetail'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'

interface IProductRouteParams {
  /** The id of this user passed through via the route params */
  id: string
}

interface IProductDetailContainerProps
  extends RouteComponentProps<IProductRouteParams> {}

export const NewProductDetailContainer: FunctionComponent<IProductDetailContainerProps> = ({
  match
}) => {
  const [options, setOptions] = useState<IProductOptions | undefined>(undefined)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()

  const handleEditRequest = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    getProductOptions()
      .then((response) => {
        setOptions(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        setError({
          hasError: true,
          errorMessage: 'failed to load product option values'
        })
      })
  }, [])

  const handleSubmission = (product: IProduct) => {
    postProduct(product)
      .then(() => {
        history.goBack()
      })
      .catch((error) => {
        console.log(error)
        setError({
          hasError: true,
          errorMessage: 'failed to post new product'
        })
      })
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelGetProductOptions()
      cancelPostProduct()
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <ProductDetail
          handleEditRequest={handleEditRequest}
          product={undefined}
          options={options}
          isEditing={true}
          isNewProduct={true}
          submitProduct={handleSubmission}
        />
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

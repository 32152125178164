import React, { FunctionComponent } from 'react'
import {
  CircularProgress,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  ButtonGroup,
  Button
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { UserStatus, TUserStatus, findUserStatusType } from '../../types'

interface IASUserStatusButtonProps {
  selectedStatus: TUserStatus
  isRequestingStatus: boolean | undefined
  handleSelectedOption: (item: TUserStatus) => void
}

export const ASUserStatusButton: FunctionComponent<IASUserStatusButtonProps> = ({
  selectedStatus,
  isRequestingStatus,
  handleSelectedOption
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState<TUserStatus>(
    selectedStatus
  )

  const handleMenuItemClick = (value: string, index: number) => {
    const selectedStatus = findUserStatusType[value]
    setSelectedIndex(selectedStatus)
    setOpen(false)
    handleSelectedOption(selectedStatus)
  }

  const handleToggle = () => {
    !isRequestingStatus && setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        disableElevation={true}
        color="default"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleToggle}>Change status</Button>
        <Button
          color="default"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select status option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {!isRequestingStatus && <ArrowDropDownIcon />}
          {isRequestingStatus && <CircularProgress size="20px" />}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {Object.values(UserStatus).map(
                    (optionValue: string, index: number) => (
                      <MenuItem
                        key={`menu-item-${index}`}
                        selected={optionValue === selectedIndex}
                        onClick={() =>
                          handleMenuItemClick(
                            findUserStatusType[optionValue],
                            index
                          )
                        }
                        value={optionValue.valueOf()}
                      >
                        {optionValue.valueOf()}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

import AnalyticsService, { IAnalyticsProvider } from './AnalyticsService'
import ReactGA from 'react-ga'
import { Dictionary } from '../../types'
import { AnalyticsEvent } from './AnalyticsEvent'

/**
 * GoogleAnalyticsManagerProvider
 *
 * @usage
 * Install dependencies
 * Pass through the correct gaId token
 * npm install react-ga -S
 *
 * @further-information
 * https://github.com/react-ga/react-ga
 */

const objectToString = (properties?: object): string => {
  const keyProps: Dictionary = properties as Dictionary
  const keys = properties && Object.keys(keyProps)
  return keys ? keys.map((key) => `${key}:${keyProps[key]}`).join(',') : ''
}

export class GoogleAnalyticsManagerProvider extends AnalyticsService
  implements IAnalyticsProvider {
  initProvider = () => {
    this.logEvent(`GoogleAnalyticsManagerProvider initProvider`)
    ReactGA.initialize(this.tokenId, {
      debug: false,
      titleCase: false,
      gaOptions: { userId: 'test-user-123' }
    })
  }

  trackError = (error: Error) => {
    this.logEvent(`GoogleAnalyticsManagerProvider trackError ${error}`)
    ReactGA.event({
      category: 'Exception',
      action: 'CustomErrorEvent',
      label: `${error.name}: ${error.message}`
    })
  }

  trackEvent = (event: AnalyticsEvent, properties?: object) => {
    this.logEvent(`GoogleAnalyticsManagerProvider trackEvent ${event}`)
    const labelValues = objectToString(properties)
    ReactGA.event({
      category: event.toString(),
      action: 'CustomEvent',
      label: labelValues
    })
  }

  trackPageView = (path: string) => {
    this.logEvent(`GoogleAnalyticsManagerProvider trackPageView ${path}`)
    ReactGA.pageview(path)
  }
}

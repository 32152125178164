import React, { FunctionComponent, useEffect, useContext } from 'react'
import {
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  Button,
  Typography,
  Box,
  IconButton,
  Grid
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../context'
import { setActiveOrder } from '../../features'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflowX: 'auto',
    height: '100%',
    paddingTop: theme.spacing(6)
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 4, 3, 4),

    '&:focus': {
      outline: 'none'
    }
  }
}))

interface IASCancelConfirmationModalProps {
  open: boolean
  reject: () => void
}

export const ASCancelConfirmationModal: FunctionComponent<IASCancelConfirmationModalProps> = ({
  open,
  reject
}) => {
  const { dispatch } = useContext(AppContext)
  const classes = useStyles()
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const history = useHistory()

  const handleClose = () => {
    setOpenModal(false)
    reject()
  }

  const handleExit = () => {
    setActiveOrder(dispatch, undefined)
    history.push('/orders')
  }

  useEffect(() => {
    setOpenModal(open)
  }, [open])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`cancel-confirmation-modal ${classes.modal}`}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Box
              mb={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                color="primary"
                variant="h4"
                style={{ marginBottom: 0 }}
              >
                Confirm
              </Typography>
              <IconButton className="close-modal" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <Box mb="25px">
                  {' '}
                  <Typography variant="h6">
                    <strong>
                      Are you sure that you wish to leave this order?
                    </strong>
                    <br /> (unsaved changes will be lost)
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={6}>
                <Box mr="10px">
                  <Button
                    fullWidth={true}
                    disableElevation={true}
                    variant="contained"
                    color="primary"
                    onClick={() => handleExit()}
                  >
                    Yes
                  </Button>
                </Box>
              </Grid>
              <Grid item={true} xs={6}>
                <Box ml="10px">
                  <Button
                    fullWidth={true}
                    disableElevation={true}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleClose()}
                  >
                    NO
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react'
import {
  Button,
  createStyles,
  Grid,
  TextField,
  Theme,
  Typography,
  Box,
  MenuItem,
  InputAdornment
} from '@material-ui/core'
import {
  IProduct,
  ProductRouteModel,
  ProductModel,
  ProductRevisionModel,
  IResponseOptions
} from '../../../models'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import { useTheme } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import { ASStatus } from '../../../components/ASStatus'
import { yupResolver } from '@hookform/resolvers'
import { object, array, string, number } from 'yup'
import {
  findProductStatusTypeNumber,
  TProductStatus,
  findProductExpiryValuesNumber
} from '../../../types'
import moment from 'moment'
import { ASProductStatusButton } from '../../../components/ASProductStatusButton'
import { ASExpiryUnitDropdown } from '../../../components/ASExpiryUnitDropdown'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    solidHightLightFormField: {
      '& label.MuiFormLabel-root': {
        color: theme.palette.text.primary
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.text.primary
      }
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    table: {
      maxHeight: 300
    }
  })
)

const blankProduct: any = {
  id: 0,
  uid: '',
  productName: '',
  productCode: '',
  productNotes: [
    {
      id: 0,
      productNoteTypeId: 1,
      text: ''
    },
    {
      id: 0,
      productNoteTypeId: 2,
      text: ''
    }
  ],
  productRevisions: [
    {
      id: 0,
      productId: 0,
      vehicleId: 0,
      productRouteId: 0,
      containerId: 0,
      productStorageTypeId: 0,
      productPackingTypeId: 0,
      productCategoryId: 0,
      productRevisionStatusId: 1,
      productExpirySpanValue: 0,
      productExpirySpanValueTypeId: 1,
      concentrationMin: 0,
      concentrationMax: 0,
      concentrationDisplay: '',
      productUnitOfMeasurement: 0,
      concentrationText: '',
      productConcentrationTypeId: 0,
      productRevisionByUserId: 1,
      productRevisionCreateDateTime: '2020-07-23T08:19:11.880Z',
      productRevisionUpdateDateTime: '2020-07-23T08:19:11.880Z',
      productConcentrationType: {
        id: 0,
        description: ''
      },
      vehicle: {
        id: 0,
        description: ''
      },
      productRoute: {
        id: 0,
        description: ''
      },
      container: {
        id: 0,
        description: ''
      },
      productStorageType: {
        id: 0,
        description: ''
      },
      productPackingType: {
        id: 0,
        description: ''
      },
      productCategory: {
        id: 0,
        description: ''
      },
      productExpirySpanValueType: {
        id: 0,
        description: ''
      },

      status: {
        id: 0,
        description: ''
      }
    }
  ]
}

const errorMessage = {
  dropdown: 'Please select an option',
  required: 'Required field',
  concentration: 'Value required',
  number: 'A numeric value must be include'
}

const productSchema = object().shape({
  id: number().required(),
  productName: string().trim().required('Please provide a Product Name'),
  productCode: string().trim(),
  productNotes: array().of(
    object().shape({
      text: string().trim()
    })
  ),
  productRevisions: array()
    .compact()
    .of(
      object().shape({
        id: number(),
        productId: number(),
        vehicleId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productRouteId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        containerId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productStorageTypeId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productPackingTypeId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productCategoryId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productRevisionStatusId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productExpirySpanValue: number()
          .required(errorMessage.required)
          .typeError(errorMessage.number),
        productExpirySpanValueTypeId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        concentrationMin: number()
          .typeError(errorMessage.number)
          .when('productConcentrationTypeId', {
            is: 2,
            then: number()
              .required()
              .min(0.01, errorMessage.concentration)
              .typeError(errorMessage.number)
          }),
        concentrationMax: number()
          .typeError(errorMessage.number)
          .when('productConcentrationTypeId', {
            is: 2,
            then: number()
              .required()
              .min(0.01, errorMessage.concentration)
              .typeError(errorMessage.number)
          }),
        unitOfMeasurementId: number().when('productConcentrationTypeId', {
          is: 2,
          then: number()
            .min(1, errorMessage.dropdown)
            .required(errorMessage.required)
        }),
        concentrationDisplay: string(),
        concentrationText: string()
          .trim()
          .when('productConcentrationTypeId', {
            is: 3,
            then: string().trim().required(errorMessage.required)
          }),
        productConcentrationTypeId: number()
          .min(1, errorMessage.dropdown)
          .required(errorMessage.required),
        productRevisionCreateDateTime: string().trim(),
        productRevisionUpdateDateTime: string().trim()
      })
    )
})

interface IProductDetailProps {
  product?: IProduct
  options?: any
  isEditing?: boolean
  isNewProduct?: boolean
  isRequestingStatus?: boolean
  handleEditRequest: () => void
  submitProduct: (org: ProductModel) => void
  requestStatusChange?: (item: TProductStatus) => void
}

export const ProductDetail: FunctionComponent<IProductDetailProps> = ({
  product = blankProduct,
  isEditing = true,
  options,
  isNewProduct = false,
  isRequestingStatus,
  requestStatusChange,
  handleEditRequest,
  submitProduct
}) => {
  const [externalNote, setExternalNote] = useState<string>(
    product?.recentExternalNote?.text ? product.recentExternalNote.text : ''
  )
  const [internalNote, setInternalNote] = useState<string>(
    product?.recentInternalNote?.text ? product.recentInternalNote.text : ''
  )

  const classes = useStyles()
  const theme = useTheme()

  const initalProductRevision = product.productRevisions[
    product.productRevisions.length - 1
  ] as ProductRevisionModel

  const {
    register,
    handleSubmit,
    errors,
    formState,
    control,
    setValue,
    watch
  } = useForm<IProduct>({
    defaultValues: product,
    resolver: yupResolver(productSchema)
  })
  const [displayName, setDisplayName] = useState<string>(
    product.productRevisions[product.productRevisions.length - 1]
      .concentrationDisplay
  )
  const history = useHistory()

  const optionsObject: IResponseOptions | undefined =
    options && options ? { ...options } : undefined

  const isSubmitDisabled = Object.entries(formState.dirtyFields).length === 0

  const recentProductRevision =
    product.productRevisions &&
    product.productRevisions.filter(
      (item: ProductRevisionModel, index: number) => {
        if (index === product.productRevisions.length - 1) {
          return item
        } else return null
      }
    )

  const revisionErrorValues =
    errors &&
    Array.isArray(errors.productRevisions) &&
    errors.productRevisions[0] !== undefined &&
    errors?.productRevisions[0]

  const watchUomValue = watch(
    `productRevisions[${
      product.productRevisions.length - 1
    }].unitOfMeasurementId`
  )

  const watchConcentrationText = watch(
    `productRevisions[${product.productRevisions.length - 1}].concentrationText`
  )

  const watchConcentrationValue = watch(
    `productRevisions[${
      product.productRevisions.length - 1
    }].productConcentrationTypeId`
  )
  const watchMinValue = watch(
    `productRevisions[${product.productRevisions.length - 1}].concentrationMin`
  )
  const watchMaxValue = watch(
    `productRevisions[${product.productRevisions.length - 1}].concentrationMax`
  )

  const handleDisplayUpdate = useCallback(() => {
    if (
      optionsObject &&
      optionsObject.productUnitOfMeasurement &&
      watchConcentrationValue === 2
    ) {
      const uomValue =
        optionsObject.productUnitOfMeasurement[watchUomValue - 1].description &&
        watchUomValue > 1
          ? optionsObject?.productUnitOfMeasurement[watchUomValue - 1]
              ?.description
          : ''
      const displayValue = `${watchMinValue}${uomValue} - ${watchMaxValue}${uomValue}`
      setDisplayName(displayValue)
    } else {
      setDisplayName(watchConcentrationText)
    }
  }, [
    watchConcentrationValue,
    watchUomValue,
    watchMinValue,
    watchMaxValue,
    optionsObject,
    watchConcentrationText,
    setDisplayName
  ])

  useEffect(() => {
    watchUomValue && handleDisplayUpdate()
  }, [
    watchUomValue,
    setValue,
    watchConcentrationValue,
    watchMinValue,
    watchMaxValue,
    handleDisplayUpdate
  ])

  useEffect(() => {
    displayName &&
      setValue(
        `productRevisions[${
          product.productRevisions.length - 1
        }].concentrationDisplay`,
        displayName
      )
  }, [displayName, product.productRevisions.length, setValue])

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={theme.breakpoints.values.md}
      marginX="auto"
      mb={theme.spacing(1)}
    >
      {recentProductRevision && (
        <Grid container>
          <Grid item>
            <Box p={2} pt={1} mb={3}>
              <form onSubmit={handleSubmit(submitProduct)}>
                <div style={{ display: 'none' }}>
                  <TextField name="id" inputRef={register({})} />
                  <TextField name="uid" inputRef={register({})} />
                  <TextField
                    name={`productRevisions[${
                      product.productRevisions.length - 1
                    }].id`}
                    inputRef={register({})}
                  />

                  <TextField
                    name={`productRevisions[${
                      product.productRevisions.length - 1
                    }].productRevisionByUserId`}
                    inputRef={register({})}
                  />

                  <TextField
                    name={`productRevisions[${
                      product.productRevisions.length - 1
                    }].productRevisionCreateDateTime`}
                    inputRef={register({})}
                  />
                  <TextField
                    name={`productRevisions[${
                      product.productRevisions.length - 1
                    }].productRevisionUpdateDateTime`}
                    inputRef={register({})}
                  />
                  <TextField
                    name={`productRevisions[${
                      product.productRevisions.length - 1
                    }].concentrationDisplay`}
                    value={displayName}
                    inputRef={register({})}
                  />
                </div>

                <div className={classes.solidHightLightFormField}>
                  <Box>
                    <Button
                      component={Link}
                      startIcon={<LeftIcon />}
                      to="/products"
                    >
                      Go Back
                    </Button>
                  </Box>
                  <Box p={2} pl={0}>
                    <Box mb={1}>
                      <Grid container spacing={3} justify="space-between">
                        <Grid item>
                          <Typography variant="h4" color="primary">
                            Product entry administration
                          </Typography>
                        </Grid>
                        <Grid item>
                          {isNewProduct && (
                            <ASStatus status={findProductStatusTypeNumber[2]} />
                          )}

                          {!isNewProduct && (
                            <ASStatus
                              status={
                                findProductStatusTypeNumber[
                                  product.productRevisions[
                                    product.productRevisions.length - 1
                                  ].productRevisionStatusId
                                ]
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    bgcolor={theme.palette.text.hint}
                    borderRadius={3}
                    p={2}
                    mb={2}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          name="productName"
                          inputRef={register({})}
                          variant="outlined"
                          label="Product Name"
                          fullWidth
                          disabled={!isEditing}
                          error={errors.productName?.message ? true : false}
                          helperText={errors.productName?.message}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        {optionsObject && optionsObject.productCategories && (
                          <Controller
                            control={control}
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].productCategoryId`}
                            as={
                              <TextField
                                select
                                variant="outlined"
                                fullWidth
                                label="Category"
                                disabled={!isEditing}
                                error={
                                  revisionErrorValues &&
                                  revisionErrorValues.productCategoryId
                                    ? true
                                    : false
                                }
                                helperText={
                                  revisionErrorValues &&
                                  revisionErrorValues.productCategoryId?.message
                                }
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {optionsObject.productCategories.map(
                                  (option: ProductRouteModel, index) => (
                                    <MenuItem
                                      key={`option-value-${index}`}
                                      value={option.id}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="productCode"
                          inputRef={register({})}
                          variant="outlined"
                          fullWidth
                          id="code"
                          label="Code"
                          disabled={!isEditing}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    bgcolor={theme.palette.text.hint}
                    borderRadius={3}
                    p={2}
                    mb={2}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        {optionsObject &&
                          optionsObject.productDeliveryVehicles && (
                            <Controller
                              control={control}
                              name={`productRevisions[${
                                product.productRevisions.length - 1
                              }].vehicleId`}
                              as={
                                <TextField
                                  select
                                  variant="outlined"
                                  fullWidth
                                  label="Vehicle (Diluent)"
                                  disabled={!isEditing}
                                  error={
                                    revisionErrorValues &&
                                    revisionErrorValues.vehicleId
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    revisionErrorValues &&
                                    revisionErrorValues.vehicleId?.message
                                  }
                                >
                                  <MenuItem value={0}>Select</MenuItem>
                                  {optionsObject.productDeliveryVehicles.map(
                                    (option: ProductRouteModel, index) => (
                                      <MenuItem
                                        key={`option-value-${index}`}
                                        value={option.id}
                                      >
                                        {option.otherName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              }
                            />
                          )}
                      </Grid>
                      <Grid item xs={4}>
                        {optionsObject && optionsObject.productRoutes && (
                          <Controller
                            control={control}
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].productRouteId`}
                            as={
                              <TextField
                                select
                                variant="outlined"
                                fullWidth
                                label="Route"
                                disabled={!isEditing}
                                error={
                                  revisionErrorValues &&
                                  revisionErrorValues.productRouteId
                                    ? true
                                    : false
                                }
                                helperText={
                                  revisionErrorValues &&
                                  revisionErrorValues.productRouteId?.message
                                }
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {optionsObject.productRoutes.map(
                                  (option: ProductRouteModel, index) => (
                                    <MenuItem
                                      key={`option-value-${index}`}
                                      value={option.id}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {optionsObject && optionsObject.productContainers && (
                          <Controller
                            control={control}
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].containerId`}
                            as={
                              <TextField
                                select
                                variant="outlined"
                                fullWidth
                                label="Container"
                                disabled={!isEditing}
                                error={
                                  revisionErrorValues &&
                                  revisionErrorValues.containerId
                                    ? true
                                    : false
                                }
                                helperText={
                                  revisionErrorValues &&
                                  revisionErrorValues.containerId?.message
                                }
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {optionsObject.productContainers.map(
                                  (option: ProductRouteModel, index) => (
                                    <MenuItem
                                      key={`option-value-${index}`}
                                      value={option.id}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    bgcolor={theme.palette.text.hint}
                    borderRadius={3}
                    p={2}
                    mb={2}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        {optionsObject &&
                          optionsObject.productConcentrationTypes && (
                            <Controller
                              control={control}
                              name={`productRevisions[${
                                product.productRevisions.length - 1
                              }].productConcentrationTypeId`}
                              as={
                                <TextField
                                  select
                                  variant="outlined"
                                  fullWidth
                                  label="Concentration Type"
                                  disabled={!isEditing}
                                  error={
                                    revisionErrorValues &&
                                    revisionErrorValues.productConcentrationTypeId
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    revisionErrorValues &&
                                    revisionErrorValues
                                      .productConcentrationTypeId?.message
                                  }
                                >
                                  <MenuItem value={0}>Select</MenuItem>
                                  {optionsObject.productConcentrationTypes.map(
                                    (option: ProductRouteModel, index) => (
                                      <MenuItem
                                        key={`option-value-${index}`}
                                        value={option.id}
                                      >
                                        {option.description}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              }
                            />
                          )}
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name={`productRevisions[${
                            product.productRevisions.length - 1
                          }].concentrationText`}
                          inputRef={register({})}
                          variant="outlined"
                          fullWidth
                          label="Use Text"
                          multiline
                          rowsMax={3}
                          disabled={!isEditing}
                          error={
                            revisionErrorValues &&
                            revisionErrorValues.concentrationText
                              ? true
                              : false
                          }
                          helperText={
                            revisionErrorValues &&
                            revisionErrorValues.concentrationText?.message
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Display"
                          multiline
                          value={displayName}
                          disabled={true}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      {watchConcentrationValue !== 2 && (
                        <>
                          <TextField
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].concentrationMin`}
                            inputRef={register({})}
                            value={0}
                            style={{ display: 'none' }}
                          />

                          <TextField
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].concentrationMax`}
                            inputRef={register({})}
                            value={0}
                            style={{ display: 'none' }}
                          />

                          <TextField
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].unitOfMeasurementId`}
                            inputRef={register({})}
                            value={1}
                            style={{ display: 'none' }}
                          />
                        </>
                      )}

                      {watchConcentrationValue === 2 && (
                        <>
                          <Grid item xs={4}>
                            <TextField
                              name={`productRevisions[${
                                product.productRevisions.length - 1
                              }].concentrationMin`}
                              inputRef={register({})}
                              variant="outlined"
                              id="concentration-min"
                              type="number"
                              label="Concentration Min"
                              fullWidth
                              disabled={!isEditing}
                              inputProps={{ step: 'any' }}
                              error={
                                revisionErrorValues &&
                                revisionErrorValues.concentrationMin
                                  ? true
                                  : false
                              }
                              helperText={
                                revisionErrorValues &&
                                revisionErrorValues.concentrationMin?.message
                              }
                            />
                            {revisionErrorValues &&
                            revisionErrorValues.concentrationMin
                              ? true
                              : false}
                          </Grid>
                          <Grid item xs={4}>
                            <>
                              <TextField
                                name={`productRevisions[${
                                  product.productRevisions.length - 1
                                }].concentrationMax`}
                                inputRef={register({})}
                                variant="outlined"
                                id="max"
                                type="number"
                                label="Concentration Max"
                                fullWidth
                                disabled={!isEditing}
                                inputProps={{ step: 'any' }}
                                error={
                                  revisionErrorValues &&
                                  revisionErrorValues.concentrationMax
                                    ? true
                                    : false
                                }
                                helperText={
                                  revisionErrorValues &&
                                  revisionErrorValues.concentrationMax?.message
                                }
                              />
                            </>
                          </Grid>
                          <Grid item xs={4}>
                            {optionsObject &&
                              optionsObject.productUnitOfMeasurement && (
                                <Controller
                                  control={control}
                                  name={`productRevisions[${
                                    product.productRevisions.length - 1
                                  }].unitOfMeasurementId`}
                                  as={
                                    <TextField
                                      select
                                      variant="outlined"
                                      fullWidth
                                      label="UOM"
                                      disabled={!isEditing}
                                      error={
                                        revisionErrorValues &&
                                        revisionErrorValues.unitOfMeasurementId
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        revisionErrorValues &&
                                        revisionErrorValues.unitOfMeasurementId
                                          ?.message
                                      }
                                    >
                                      {optionsObject.productUnitOfMeasurement.map(
                                        (option: ProductRouteModel, index) => (
                                          <MenuItem
                                            key={`option-value-${index}`}
                                            value={option.id}
                                          >
                                            {option.description}
                                          </MenuItem>
                                        )
                                      )}
                                    </TextField>
                                  }
                                />
                              )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                  <Box
                    bgcolor={theme.palette.text.hint}
                    borderRadius={3}
                    p={2}
                    mb={2}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          name={`productRevisions[${
                            product.productRevisions.length - 1
                          }].productExpirySpanValueTypeId`}
                          inputRef={register({})}
                          style={{ display: 'none' }}
                        />

                        <TextField
                          name={`productRevisions[${
                            product.productRevisions.length - 1
                          }].productExpirySpanValue`}
                          inputRef={register({})}
                          variant="outlined"
                          id="expiry"
                          type="number"
                          fullWidth
                          label="Expiry"
                          disabled={!isEditing}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <ASExpiryUnitDropdown
                                  disabled={!isEditing}
                                  handleSelectedOption={(item) =>
                                    setValue(
                                      `productRevisions[${
                                        product.productRevisions.length - 1
                                      }].productExpirySpanValueTypeId`,
                                      findProductExpiryValuesNumber[item],
                                      {
                                        shouldDirty: true
                                      }
                                    )
                                  }
                                  initialValue={
                                    initalProductRevision
                                      .productExpirySpanValueType.description
                                  }
                                />
                              </InputAdornment>
                            )
                          }}
                          error={
                            revisionErrorValues &&
                            revisionErrorValues.productExpirySpanValue
                              ? true
                              : false
                          }
                          helperText={
                            revisionErrorValues &&
                            revisionErrorValues.productExpirySpanValue?.message
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {optionsObject && optionsObject.productStorageTypes && (
                          <Controller
                            control={control}
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].productStorageTypeId`}
                            as={
                              <TextField
                                select
                                variant="outlined"
                                fullWidth
                                label="Storage"
                                disabled={!isEditing}
                                error={
                                  revisionErrorValues &&
                                  revisionErrorValues.productStorageTypeId
                                    ? true
                                    : false
                                }
                                helperText={
                                  revisionErrorValues &&
                                  revisionErrorValues.productStorageTypeId
                                    ?.message
                                }
                              >
                                <MenuItem value={0}>Select</MenuItem>

                                {optionsObject.productStorageTypes.map(
                                  (option: ProductRouteModel, index) => (
                                    <MenuItem
                                      key={`option-value-${index}`}
                                      value={option.id}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {optionsObject && optionsObject.productPackingTypes && (
                          <Controller
                            control={control}
                            name={`productRevisions[${
                              product.productRevisions.length - 1
                            }].productPackingTypeId`}
                            as={
                              <TextField
                                select
                                variant="outlined"
                                fullWidth
                                label="Packaging"
                                disabled={!isEditing}
                                error={
                                  revisionErrorValues &&
                                  revisionErrorValues.productPackingTypeId
                                    ? true
                                    : false
                                }
                                helperText={
                                  revisionErrorValues &&
                                  revisionErrorValues.productPackingTypeId
                                    ?.message
                                }
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {optionsObject.productPackingTypes.map(
                                  (option: ProductRouteModel, index) => (
                                    <MenuItem
                                      key={`option-value-${index}`}
                                      value={option.id}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="productNotes[0].productNoteTypeId"
                          style={{ display: 'none' }}
                          inputRef={register({})}
                          value={1}
                        />
                        <TextField
                          name="productNotes[0].text"
                          inputRef={register({})}
                          style={{ display: 'none' }}
                        />
                        <TextField
                          onChange={(event) => {
                            setInternalNote(event.currentTarget.value)
                            setValue(
                              'productNotes[0].text',
                              event.currentTarget.value,
                              { shouldDirty: true }
                            )
                          }}
                          value={internalNote}
                          variant="outlined"
                          fullWidth
                          label="Internal Note"
                          multiline
                          rowsMax={3}
                          disabled={!isEditing}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="productNotes[1].productNoteTypeId"
                          style={{ display: 'none' }}
                          inputRef={register({})}
                          value={2}
                        />
                        <TextField
                          name="productNotes[1].text"
                          inputRef={register({})}
                          style={{ display: 'none' }}
                        />

                        <TextField
                          variant="outlined"
                          fullWidth
                          onChange={(event) => {
                            setExternalNote(event.currentTarget.value)
                            setValue(
                              'productNotes[1].text',
                              event.currentTarget.value,
                              { shouldDirty: true }
                            )
                          }}
                          value={externalNote}
                          label="External note"
                          multiline
                          rowsMax={3}
                          disabled={!isEditing}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>
                <Box
                  borderColor={theme.palette.grey[200]}
                  border={1}
                  borderRadius={3}
                  p={2}
                  mb={2}
                  hidden={isNewProduct}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        id="revision-number"
                        fullWidth
                        variant="outlined"
                        label="Revision number"
                        value={
                          product.productRevisions[
                            product.productRevisions.length - 1
                          ].productRevisionNumber
                        }
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name={`productRevisions[${
                          product.productRevisions.length - 1
                        }].productRevisionStatusId`}
                        inputRef={register({})}
                        style={{ display: 'none' }}
                      />
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Status"
                        disabled={true}
                        value={
                          findProductStatusTypeNumber[
                            product.productRevisions[
                              product.productRevisions.length - 1
                            ].productRevisionStatusId
                          ]
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name={`productRevisions[${
                          product.productRevisions.length - 1
                        }].productId`}
                        inputRef={register({})}
                        variant="outlined"
                        fullWidth
                        type="number"
                        label="Product ID"
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {isNewProduct === false && (
                  <Box
                    borderColor={theme.palette.grey[200]}
                    border={1}
                    borderRadius={3}
                    p={2}
                    mb={2}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Last updated"
                          value={moment(
                            product.productRevisions[
                              product.productRevisions.length - 1
                            ].productRevisionUpdateDateTime
                          ).format('DD-MM-YYYY HH:mm')}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name={`productRevisions[${
                            product.productRevisions.length - 1
                          }].productRevisionByUser.username`}
                          inputRef={register({})}
                          variant="outlined"
                          fullWidth
                          label="Updated by"
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Grid
                  style={{ flexGrow: 1, justifyContent: 'flex-end' }}
                  container
                  spacing={3}
                >
                  <div className={classes.buttonContainer}>
                    <Button
                      disableElevation={true}
                      variant="outlined"
                      onClick={() => history.push('/products')}
                    >
                      go back
                    </Button>
                    {!isNewProduct && (
                      <ASProductStatusButton
                        isRequestingStatus={isRequestingStatus}
                        selectedStatus={
                          findProductStatusTypeNumber[
                            product.productRevisions[
                              product.productRevisions.length - 1
                            ].productRevisionStatusId
                          ]
                        }
                        handleSelectedOption={(status: TProductStatus) =>
                          requestStatusChange && requestStatusChange(status)
                        }
                      />
                    )}
                    {isNewProduct === false && !isEditing && (
                      <Button
                        disableElevation={true}
                        variant="contained"
                        onClick={() => handleEditRequest()}
                      >
                        Edit
                      </Button>
                    )}
                    {isEditing && (
                      <Button
                        type="submit"
                        disableElevation={true}
                        variant="contained"
                        disabled={isSubmitDisabled}
                        color={
                          formState.touched &&
                          isEditing &&
                          !formState.isSubmitted
                            ? 'primary'
                            : 'default'
                        }
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      )}
      {/* <pre>{JSON.stringify(watchDisplayName, null, 4)}</pre>
      <pre>{JSON.stringify(watchAllFields, null, 4)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre> */}
    </Box>
  )
}

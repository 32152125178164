import AnalyticsService from './AnalyticsService'
import { ApplicationInsightsProvider } from './ApplicationInsightsProvider'
import { GoogleAnalyticsManagerProvider } from './GoogleAnalyticsManagerProvider'
import config from '../ConfigProvider'
/**
 * initAnalytics
 * Helper method to instantiate Analytics Service with all in the included providers
 * Be sure to call initProviders()!
 */
const initAnalytics = (): AnalyticsService => {
  const analyticsService = new AnalyticsService(
    false,
    config.environment !== 'local'
  )

  const aip = new ApplicationInsightsProvider()
  aip.tokenId = config.appInsightsInstrumentationKey
  analyticsService.registerProvider('ApplicationInsights', aip)

  const gap = new GoogleAnalyticsManagerProvider()
  gap.tokenId = config.gaId
  analyticsService.registerProvider('GoogleAnalyticsManagerProvider', gap)
  analyticsService.enableDebugLogging = false

  analyticsService.initProviders()
  return analyticsService
}

const analyticsServiceSingleton = initAnalytics()
export default analyticsServiceSingleton

import {
  withStyles,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  TableContainer
} from '@material-ui/core'

export const ASTableContainer = withStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.xl
  }
}))(TableContainer)

export const ASTableContainerOverflow = withStyles((theme) => ({
  root: {
    overflow: 'visible'
  }
}))(TableContainer)

export const ASTableHeaderCell = withStyles((theme) => ({
  root: {
    border: 'none',
    lineHeight: 'inherit',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  head: {
    backgroundColor: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    border: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

export const ASTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    overflowWrap: 'anywhere'
  }
}))(TableCell)

export const ASSlimTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: `${theme.spacing(1.6)}px ${theme.spacing(1)}px`,
    overflowWrap: 'anywhere'
  }
}))(TableCell)

export const ASTableSortLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      color: theme.palette.common.white
    },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: theme.palette.common.white
    },
    '&.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.common.white
    }
  }
}))(TableSortLabel)

export const ASSearchTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    height: theme.spacing(8)
  }
}))(TableRow)

export const ASTableRow = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    cursor: 'pointer',
    '&.active': {
      backgroundColor: theme.palette.primary.light
    },
    '&.disabled': {
      pointerEvents: 'none',
      opacity: '0.5'
    }
  }
}))(TableRow)

export const ASProductRow = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  }
}))(TableRow)

export const ASTableBody = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}))(TableBody)

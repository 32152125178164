import React, { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'
import { IAnalyticsProvider } from './'
import analyticsServiceSingleton from './initAnalytics'

/**
 * AnalyticsHelper
 * A component to intercept route change events and track them via an analytics service
 */

export const AnalyticsHelper: FunctionComponent = () => {
  const [analyticsService] = React.useState<IAnalyticsProvider>(
    analyticsServiceSingleton
  )

  const location = useLocation()

  React.useEffect(() => analyticsService.trackPageView(location.pathname), [
    location.pathname,
    analyticsService
  ])

  return null
}

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  withStyles
} from '@material-ui/core'
import React, { FunctionComponent, useContext } from 'react'
import { ASTableBody, ASProductRow } from '../ASTable'
import { OrderLineModel } from '../../models/swaggerTypesModified'
import { AppContext } from '../../context'
import { expiryValueFormatted } from '../../utility'

const ASHeaderCell = withStyles((theme) => ({
  root: {
    border: 'none',
    lineHeight: 'inherit',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  head: {
    backgroundColor: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    border: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

export const ASProductTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: `${theme.spacing(1.6)}px ${theme.spacing(2)}px`
  }
}))(TableCell)

interface IASProductsTableProps {
  products: OrderLineModel[]
}

export const ASProductsTable: FunctionComponent<IASProductsTableProps> = ({
  products
}) => {
  const { user } = useContext(AppContext)

  return (
    <TableContainer>
      <Table size="small" aria-label="products table">
        <TableHead>
          <ASProductRow>
            <ASHeaderCell>Product</ASHeaderCell>
            <ASHeaderCell>Dose</ASHeaderCell>
            <ASHeaderCell>Vehicle</ASHeaderCell>
            <ASHeaderCell>Volume</ASHeaderCell>
            <ASHeaderCell>Route</ASHeaderCell>
            <ASHeaderCell>Container</ASHeaderCell>
            <ASHeaderCell>Expiry Date</ASHeaderCell>
            <ASHeaderCell>Qty</ASHeaderCell>
          </ASProductRow>
        </TableHead>
        <ASTableBody>
          {products.map((product, index) => (
            <ASProductRow key={`product-row-${index}`}>
              <ASProductTableCell>{product.productName}</ASProductTableCell>
              <ASProductTableCell>{product.dose}</ASProductTableCell>
              <ASProductTableCell>
                {user?.profile.role === 'Admin'
                  ? product.productRevision.vehicle.otherName
                  : product.productRevision.vehicle.description}
              </ASProductTableCell>
              <ASProductTableCell>{product.volume}</ASProductTableCell>
              <ASProductTableCell>
                {product.productRevision.productRoute.description}
              </ASProductTableCell>

              <ASProductTableCell>
                {product.productRevision.container.description}
              </ASProductTableCell>
              <ASProductTableCell>
                {expiryValueFormatted(
                  product.productRevision.productExpirySpanValue,
                  product.productRevision.productExpirySpanValueType.id
                )}
              </ASProductTableCell>
              <ASProductTableCell>{product.quantity}</ASProductTableCell>
            </ASProductRow>
          ))}
        </ASTableBody>
      </Table>
    </TableContainer>
  )
}

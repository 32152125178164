import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  useTheme,
  IconButton,
  Box,
  TextField,
  MenuItem,
  makeStyles,
  Typography
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import {
  ASTableHeaderCell,
  ASTableBody,
  ASSlimTableCell,
  ASTableSortLabel,
  ASSearchTableRow,
  ASTableRow
} from '../ASTable'
import {
  IOrganisation,
  ITableHeader,
  IProduct,
  ITableCell,
  UserModel
} from '../../models'
import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'
import {
  TSortDirection,
  SortDirectionType,
  ProductStatusType
} from '../../types'
import { toggleSortDirection } from '../../features/sort.models'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useHistory } from 'react-router-dom'
import { ASSearchTextField } from '../ASSearch'
import { ColWidthsType, ColWidths } from '../../constants'
import { ASStatus } from '../ASStatus'
import { capitalizeFirstLetter, firstCharLowerCase } from '../../utility'
import moment from 'moment'

interface IASAdminTableProps {
  /** Requires an array of Organisation */
  collection: (IOrganisation | IProduct | UserModel)[]
  /** Table header values are provided by its parent including sort direction state  */
  tableHeaders: ITableHeader[]
  /** Provides the parent the name of the column that is being requested to sort */
  requestSort: (columnName: string, sortDirection: TSortDirection) => void
  /** Provides the parent updated table headers that include search values */
  requestSearch: (tableHeaders: ITableHeader[]) => void
  /** itemPath provides a url string value to enable items to link to their correct location */
  itemPath: string
  /** A fixed value for the tables width */
  tableWidth?: string
}

export const ASAdminTable: FunctionComponent<IASAdminTableProps> = ({
  collection,
  tableHeaders,
  requestSort,
  requestSearch,
  itemPath,
  tableWidth = 'auto'
}) => {
  const history = useHistory()
  const theme = useTheme()
  const [searchValues, setSearchValues] = useState<ITableHeader[]>(tableHeaders)
  const [list, setList] = useState(collection)

  const useStyles = makeStyles(() => ({
    statusSelect: {
      padding: '0',

      input: {
        padding: '10px'
      }
    }
  }))
  const classes = useStyles()

  const handleSearch = (value: string, searchName: string) => {
    const updateSearchValue = searchValues.map((field: ITableHeader) => {
      const normalisedFieldName = firstCharLowerCase(
        field.name.replace('header', '')
      )
      const normalisedSearchName = firstCharLowerCase(
        searchName.replace('header', '')
      )
      if (normalisedFieldName === normalisedSearchName) {
        if (value === 'all') {
          handleSearchClear(searchName)
        } else {
          field.searchValue = value
        }
      }
      return field
    })
    setSearchValues(updateSearchValue)
  }

  const handleSearchClear = (searchName: string) => {
    const updateSearchValue = searchValues.map((field: ITableHeader) => {
      if (field.name === searchName) {
        field.searchValue = ''
      }
      return field
    })
    setSearchValues(updateSearchValue)
  }

  useEffect(() => {
    requestSearch(searchValues)
  }, [searchValues, requestSearch])

  useEffect(() => {
    setList([...collection])
  }, [collection])

  return (
    <>
      <TableContainer>
        <Table
          style={{
            width: tableWidth,
            tableLayout: 'auto',
            margin: '0 auto'
          }}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <ASTableHeaderCell style={{ width: theme.spacing(0.8) }} />
              {tableHeaders.map((header: ITableHeader, index: number) => {
                return (
                  <ASTableHeaderCell
                    key={`head-cell-${index}`}
                    style={{
                      width:
                        ColWidths[header.colWidth ? header.colWidth : 'auto'],
                      minWidth:
                        ColWidths[header.colWidth ? header.colWidth : 'auto']
                    }}
                  >
                    {header.sortable === undefined && (
                      <ASTableSortLabel
                        active={header.sortDirection !== SortDirectionType.None}
                        direction={
                          header.sortDirection === SortDirectionType.Ascending
                            ? SortDirectionType.Ascending
                            : SortDirectionType.Descending
                        }
                        onClick={() =>
                          requestSort(
                            header.name,
                            header.sortDirection &&
                              toggleSortDirection(header.sortDirection)
                          )
                        }
                      >
                        {header.displayName}
                      </ASTableSortLabel>
                    )}
                    {header.sortable === false && (
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: theme.palette.common.white,
                          fontWeight: theme.typography.fontWeightBold,
                          lineHeight: '1'
                        }}
                      >
                        {header.displayName}
                      </Typography>
                    )}
                  </ASTableHeaderCell>
                )
              })}
              <ASTableHeaderCell style={{ width: theme.spacing(0.8) }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <ASSearchTableRow className="table-row-search">
              <TableCell style={{ padding: theme.spacing(0.8) }} />
              {tableHeaders.map((searchField: ITableHeader, index: number) => {
                return (
                  <TableCell padding="none" key={`search-cell-${index}`}>
                    {searchField.searchable === true && (
                      <Box
                        pr={theme.spacing(0.2)}
                        pl={theme.spacing(0.1)}
                        display="flex"
                        alignItems="center"
                      >
                        {searchField.type !== 'status' && (
                          <>
                            <ASSearchTextField
                              size="small"
                              type={
                                searchField.type === 'dateUnits'
                                  ? 'number'
                                  : 'text'
                              }
                              variant="outlined"
                              value={searchField.searchValue}
                              onChange={(event) => {
                                handleSearch(
                                  event.target.value,
                                  searchField.name
                                )
                              }}
                              id={searchField.name}
                            />
                            {searchField.searchValue && (
                              <Box ml={'5px'}>
                                <IconButton
                                  onClick={() =>
                                    handleSearchClear(searchField.name)
                                  }
                                  edge="start"
                                  size="small"
                                  color="default"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Box>
                            )}
                          </>
                        )}

                        {searchField.type === 'status' && (
                          <TextField
                            className={classes.statusSelect}
                            select={true}
                            variant="standard"
                            fullWidth={true}
                            defaultValue={
                              searchField.searchValue === ''
                                ? 'All'
                                : searchField.searchValue &&
                                  searchField.searchValue
                            }
                            onChange={(event) => {
                              handleSearch(
                                event.target.value.toLowerCase(),
                                searchField.name
                              )
                            }}
                          >
                            <MenuItem value="All">All</MenuItem>
                            {Object.values(ProductStatusType).map(
                              (item, idx) => {
                                return (
                                  <MenuItem
                                    key={`status-${idx}`}
                                    value={item.toLocaleLowerCase()}
                                  >
                                    {capitalizeFirstLetter(item)}
                                  </MenuItem>
                                )
                              }
                            )}
                          </TextField>
                        )}
                      </Box>
                    )}
                  </TableCell>
                )
              })}
              <TableCell style={{ padding: theme.spacing(0.8) }} />
            </ASSearchTableRow>
          </TableBody>

          <ASTableBody className="table-results-list">
            {list &&
              list.map(
                (
                  field: IOrganisation | IProduct | UserModel,
                  index: number
                ): React.ReactNode => {
                  const rowValues: ITableCell[] = tableHeaders.map((item) => {
                    const cellValue: ITableCell = {
                      value: null,
                      type: 'text',
                      size: 'small',
                      colSize: ColWidthsType.MD
                    }

                    for (const [key, value] of Object.entries(field)) {
                      if (item.name === key) {
                        cellValue.value = value
                        cellValue.size = item.size
                        cellValue.type = item.type
                        cellValue.colSize = item.colWidth
                      }
                    }
                    return cellValue
                  })

                  return (
                    <ASTableRow
                      key={`row-${index}`}
                      className="table-results-row"
                    >
                      <ASSlimTableCell
                        style={{
                          padding: theme.spacing(0.8)
                        }}
                      />
                      {rowValues &&
                        rowValues.map((item: ITableCell, idx) => {
                          if (
                            item.type === 'text' ||
                            item.type === 'dateUnits'
                          ) {
                            return (
                              <ASSlimTableCell
                                key={`text-cell-${idx + index}`}
                                onClick={() => {
                                  history.push(`${itemPath}/${field.id}`)
                                }}
                                size={item.size}
                                style={{
                                  width:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ],
                                  minWidth:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ]
                                }}
                              >
                                {typeof item.value === 'string' && item.value}
                              </ASSlimTableCell>
                            )
                          }
                          if (item.type === 'number') {
                            return (
                              <ASSlimTableCell
                                key={`text-cell-number-${idx + index}`}
                                onClick={() => {
                                  history.push(`${itemPath}/${field.id}`)
                                }}
                                size={item.size}
                                style={{
                                  width:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ],
                                  minWidth:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ]
                                }}
                              >
                                {typeof item.value === 'number' && item.value}
                              </ASSlimTableCell>
                            )
                          }
                          if (item.type === 'date') {
                            return (
                              <ASSlimTableCell
                                key={`text-cell-date-${idx + index}`}
                                onClick={() => {
                                  history.push(`${itemPath}/${field.id}`)
                                }}
                                size={item.size}
                                style={{
                                  width:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ],
                                  minWidth:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ]
                                }}
                              >
                                {typeof item.value === 'string' &&
                                  moment(item.value).format('DD-MM-YYYY HH:mm')}
                              </ASSlimTableCell>
                            )
                          }
                          if (item.type === 'status') {
                            return (
                              <ASSlimTableCell
                                key={`status-cell-${idx + index}`}
                                onClick={() =>
                                  history.push(`${itemPath}/${field.id}`)
                                }
                                size={item.size}
                                style={{
                                  width:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ],
                                  minWidth:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ]
                                }}
                              >
                                {typeof item.value === 'string' && (
                                  <ASStatus status={item.value} />
                                )}
                              </ASSlimTableCell>
                            )
                          }
                          if (item.type === 'checked') {
                            return (
                              <ASSlimTableCell
                                key={`checked-cell-${idx + index}`}
                                style={{
                                  color: theme.palette.primary.dark,
                                  width:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ],
                                  minWidth:
                                    ColWidths[
                                      item.colSize ? item.colSize : 'auto'
                                    ]
                                }}
                                onClick={() =>
                                  history.push(`${itemPath}/${field.id}`)
                                }
                                size={item.size}
                              >
                                <>
                                  {item.value && (
                                    <CheckCircleIcon color="inherit" />
                                  )}
                                </>
                              </ASSlimTableCell>
                            )
                          }
                          return <Fragment key={`empty-cell-${idx + index}`} />
                        })}
                      <ASSlimTableCell
                        style={{
                          padding: theme.spacing(0.8)
                        }}
                      />
                    </ASTableRow>
                  )
                }
              )}
          </ASTableBody>
        </Table>
      </TableContainer>
    </>
  )
}

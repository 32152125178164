import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  postOrderFilter,
  getActiveOrderCount
} from '../../../services/ApiService'
import {
  ITableHeader,
  IOrderList,
  IError,
  IOrderListPayload,
  QueryFilter,
  Filter
} from '../../../models'
import { Orders } from './Orders'
import { useDebounce } from '../../../components/utility/utility'
import { TSortDirection, SortDirectionType } from '../../../types/SortTypes'
import {
  Grid,
  TablePagination,
  Snackbar,
  Box,
  CircularProgress
} from '@material-ui/core'

import { ColWidthsType } from '../../../constants'
import { Alert } from '@material-ui/lab'
import { updateSearch, capitalizeFirstLetter } from '../../../utility'

export const CustomerOrderHeaders: ITableHeader[] = [
  {
    name: 'id',
    displayName: 'Our Ref',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'patientReference',
    displayName: 'Your Ref',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'orderCreateDate',
    displayName: 'Ordered on',
    sortDirection: SortDirectionType.Descending,
    type: 'digitalDateTime',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.LG
  },
  {
    name: 'patientName',
    displayName: 'Patient',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'patientTreatmentStartDate',
    displayName: 'Treatment Date and Time',
    sortDirection: SortDirectionType.None,
    type: 'dateTime',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.XL
  },
  {
    name: 'requiredDate',
    displayName: 'Delivery Date',
    sortDirection: SortDirectionType.None,
    type: 'date',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.MD
  },

  {
    name: 'headerOrderStatus',
    displayName: 'Status',
    sortDirection: SortDirectionType.None,
    type: 'status',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  }
]

const InitialState: QueryFilter = {
  sorting: {
    sortBy: 'OrderCreateDate',
    sortDirection: capitalizeFirstLetter(SortDirectionType.Descending)
  },
  pagination: {
    currentPage: 0,
    pageCount: 0,
    count: 10
  },
  filters: [
    {
      filterBy: '',
      filterValue: ''
    }
  ]
}

export const OrdersContainer: FunctionComponent = () => {
  const [orderState, setOrderState] = useState<IOrderListPayload>()
  const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(
    CustomerOrderHeaders
  )
  const [activeCount, setActiveCount] = useState<number>(0)
  const [pageState, setPageState] = useState<QueryFilter>(InitialState)
  const [searchQuery, setSearchQuery] = useState<Filter | undefined>()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>({
    hasError: false
  })

  const handleSearch = (query: Filter) => {
    setPageState(updateSearch(pageState, tableHeaders, query))
  }

  const debounceUpdateSearchQuery = useDebounce<Filter[]>(handleSearch, 200)
  const handleSearchRequest = (headers: ITableHeader[]) => {
    setTableHeaders(headers)
  }
  useEffect(() => searchQuery && debounceUpdateSearchQuery(searchQuery), [
    searchQuery
  ])

  const handleSortRequest = (columnName: string, sortDir: TSortDirection) => {
    setTableHeaders(
      tableHeaders.map((item) => {
        if (item.name === columnName) {
          item.sortDirection = sortDir
          const updatedPage = { ...pageState }
          if (updatedPage && updatedPage.sorting) {
            updatedPage.sorting.sortDirection = capitalizeFirstLetter(sortDir)
            updatedPage.sorting.sortBy = capitalizeFirstLetter(columnName)
          }
          setPageState(updatedPage)
        } else {
          item.sortDirection = SortDirectionType.None
        }
        return item
      })
    )
  }

  const updatePage = (value: QueryFilter) => {
    postOrderFilter(value)
      .then((response) => {
        const typedOrg = response.data as IOrderListPayload
        const updatedOrderItems: IOrderList[] =
          typedOrg &&
          typedOrg.orders.reduce(
            (accOrders: IOrderList[], order: IOrderList) => {
              const orderItem: IOrderList = {
                headerOrderStatus: order.orderStatus.id,
                headerFullAddress: order.contact.address.fullAddress,
                ...order
              }
              return [...(accOrders || []), orderItem]
            },
            []
          )
        typedOrg.orders = updatedOrderItems
        typedOrg && setOrderState(typedOrg)
        setIsLoading(false)
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to get filter order list'
        })
      })
    getActiveOrderCount()
      .then((response) => {
        response.data && setActiveCount(response.data)
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to get active count'
        })
      })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.currentPage = newPage
      setPageState(newPageState)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.count = parseInt(event.target.value, 10)
      setPageState(newPageState)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePage(pageState)
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [pageState])

  useEffect(() => {
    updatePage(pageState)
  }, [pageState])

  useEffect(() => {
    const searchValue = tableHeaders.find((item) => item.searchValue !== '')
    let filter = {
      filterBy: '',
      filterValue: ''
    }
    if (searchValue && searchValue.searchValue) {
      filter = {
        filterBy: searchValue.name,
        filterValue: searchValue.searchValue
      }
    }

    filter && setSearchQuery(filter)
  }, [tableHeaders, setSearchQuery])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          minHeight="400px"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Grid
          className="orders-list-container"
          container={true}
          direction="column"
          justify="space-between"
          alignItems="center"
          style={{ overflow: 'auto' }}
        >
          <Grid item={true} style={{ maxWidth: '100%' }}>
            {orderState && orderState.orders && (
              <Orders
                tableHeaders={tableHeaders}
                requestSort={(columnName, sortDirection) =>
                  handleSortRequest(columnName, sortDirection)
                }
                requestSearch={(headers) => handleSearchRequest(headers)}
                orders={orderState?.orders}
                activeCount={activeCount}
              />
            )}

            {orderState && pageState && pageState.pagination && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={orderState.total}
                rowsPerPage={pageState.pagination.count}
                page={orderState.currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

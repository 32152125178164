import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  useTheme,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  TextField,
  MenuItem,
  makeStyles,
  Button
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import LoopIcon from '@material-ui/icons/Loop'
import {
  ASTableHeaderCell,
  ASTableCell,
  ASTableSortLabel,
  ASSearchTableRow,
  ASTableRow
} from '../ASTable'
import { IOrderList, ITableHeader, ITableCell } from '../../models'
import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'
import {
  TSortDirection,
  SortDirectionType,
  OrderStatusType,
  findOrderStatusTypeByNumber
} from '../../types'
import { toggleSortDirection } from '../../features/sort.models'
import { useHistory, Link } from 'react-router-dom'
import { ASSearchTextField } from '../ASSearch'
import { ASStatus } from '../ASStatus'
import { ColWidths, ColWidthsType } from '../../constants'
import Moment from 'react-moment'
import { capitalizeFirstLetter, firstCharLowerCase } from '../../utility'
import moment from 'moment'

export const ASAccordion = withStyles((theme) => ({
  root: {
    margin: 0
  }
}))(Accordion)

export const ASAccordionSummary = withStyles((theme) => ({
  root: {
    margin: `0`,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark
  }
}))(AccordionSummary)

export const ASAccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(AccordionDetails)

const sortOrdersInToDateArray = (orders: IOrderList[]) => {
  const ordersByDateArray: IOrderByDate[] = []
  const dateFormat = 'ddd DD-MMM-YYYY'
  orders.forEach((order: IOrderList) => {
    const tempOrderArray = {
      ...order
    }
    const doesDateExist = ordersByDateArray.filter((dateItem: IOrderByDate) => {
      return (
        dateItem.date ===
        moment(tempOrderArray.orderCreateDate).format(dateFormat)
      )
    })
    if (doesDateExist.length === 0) {
      ordersByDateArray.push({
        date: moment(order.orderCreateDate).format(dateFormat)
      })
    }
  })

  orders.forEach((order: IOrderList) => {
    ordersByDateArray.forEach((item: IOrderByDate) => {
      if (item.date === moment(order.orderCreateDate).format(dateFormat)) {
        item.orders = [...(item.orders || []), order]
      }
    })
  })

  return ordersByDateArray
}

interface IOrderByDate {
  date: string
  orders?: IOrderList[]
}

interface IASCustomerOrderTableProps {
  /** Requires an array of Orders */
  orders: IOrderList[]
  /** Table header values are provided by its parent including sort direction state  */
  tableHeaders: ITableHeader[]
  /** Provides the parent the name of the column that is being requested to sort */
  requestSort: (columnName: string, sortDirection: TSortDirection) => void
  /** Provides the parent updated table headers that include search values */
  requestSearch: (tableHeaders: ITableHeader[]) => void
  /** itemPath provides a url string value to enable items to link to their correct location */
  printOrders?: boolean
}

export const ASCustomerOrderTable: FunctionComponent<IASCustomerOrderTableProps> = ({
  orders,
  tableHeaders,
  requestSort,
  requestSearch
}) => {
  const history = useHistory()
  const theme = useTheme()
  const [searchValues, setSearchValues] = useState<ITableHeader[]>(tableHeaders)
  const [ordersByDate, setOrdersByDate] = useState<IOrderByDate[]>()

  const useStyles = makeStyles(() => ({
    statusSelect: {
      padding: '0',

      input: {
        padding: '10px'
      }
    }
  }))
  const classes = useStyles()

  const handleSearchClear = (searchName: string) => {
    const updateSearchValue = searchValues.map((field: ITableHeader) => {
      if (field.name === searchName) {
        field.searchValue = ''
      }
      return field
    })
    setSearchValues(updateSearchValue)
  }

  const handleSearch = (value: string, searchName: string) => {
    const updateSearchValue = searchValues.map((field: ITableHeader) => {
      const normalisedFieldName = firstCharLowerCase(
        field.name.replace('header', '')
      )
      const normalisedSearchName = firstCharLowerCase(
        searchName.replace('header', '')
      )
      if (normalisedFieldName === normalisedSearchName) {
        if (value === 'all') {
          handleSearchClear(searchName)
        } else {
          field.searchValue = value
        }
      }
      return field
    })
    setSearchValues(updateSearchValue)
  }

  useEffect(() => {
    requestSearch(searchValues)
  }, [searchValues, requestSearch])

  useEffect(() => {
    setOrdersByDate(sortOrdersInToDateArray(orders))
  }, [orders])

  return (
    <>
      <TableContainer className="orders-list">
        <Table
          style={{
            width: 'auto',
            tableLayout: 'auto',
            margin: '0 auto'
          }}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <ASTableHeaderCell style={{ padding: theme.spacing(0.8) }} />
              {tableHeaders &&
                tableHeaders.map((header: ITableHeader, index: number) => {
                  if (header.colWidth) {
                    return (
                      <ASTableHeaderCell
                        key={`head-cell-${index}`}
                        style={{ width: ColWidths[header.colWidth] }}
                      >
                        <ASTableSortLabel
                          active={
                            header.sortDirection !== SortDirectionType.None
                          }
                          direction={
                            header.sortDirection === SortDirectionType.Ascending
                              ? SortDirectionType.Ascending
                              : SortDirectionType.Descending
                          }
                          onClick={() =>
                            requestSort(
                              header.name,
                              header.sortDirection &&
                                toggleSortDirection(header.sortDirection)
                            )
                          }
                        >
                          {header.displayName}
                        </ASTableSortLabel>
                      </ASTableHeaderCell>
                    )
                  }
                  return <Fragment key={`search-cell-${index}`} />
                })}
              <ASTableHeaderCell
                style={{ width: ColWidths.md, minWidth: ColWidths.md }}
              />
              <ASTableHeaderCell style={{ padding: theme.spacing(0.8) }} />
            </TableRow>
          </TableHead>

          <TableBody>
            <ASSearchTableRow>
              <TableCell style={{ padding: theme.spacing(0.8) }} />
              {tableHeaders &&
                tableHeaders.map((searchField: ITableHeader, index: number) => {
                  if (searchField.colWidth) {
                    return (
                      <TableCell
                        padding="none"
                        key={`search-cell-${index}`}
                        style={{ width: ColWidths[searchField.colWidth] }}
                      >
                        {searchField.searchable === true && (
                          <Box
                            pr={theme.spacing(0.2)}
                            pl={theme.spacing(0.1)}
                            display="flex"
                            alignItems="center"
                          >
                            {searchField.type !== 'status' && (
                              <>
                                <ASSearchTextField
                                  size="small"
                                  variant="outlined"
                                  value={searchField.searchValue}
                                  onChange={(event) => {
                                    handleSearch(
                                      event.target.value,
                                      searchField.name
                                    )
                                  }}
                                  id={searchField.name}
                                />
                                {searchField.searchValue && (
                                  <Box ml={'5px'}>
                                    <IconButton
                                      onClick={() =>
                                        handleSearchClear(searchField.name)
                                      }
                                      edge="start"
                                      size="small"
                                      color="default"
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                )}
                              </>
                            )}

                            {searchField.type === 'status' && (
                              <TextField
                                className={classes.statusSelect}
                                select={true}
                                variant="standard"
                                fullWidth={true}
                                defaultValue={
                                  searchField.searchValue === ''
                                    ? 'All'
                                    : searchField.searchValue &&
                                      searchField.searchValue
                                }
                                onChange={(event) => {
                                  handleSearch(
                                    event.target.value.toLowerCase(),
                                    searchField.name
                                  )
                                }}
                              >
                                <MenuItem value="All">All</MenuItem>
                                {Object.values(OrderStatusType).map(
                                  (item, index) => {
                                    return (
                                      <MenuItem
                                        key={`status-${index}`}
                                        value={item.toLocaleLowerCase()}
                                      >
                                        {capitalizeFirstLetter(item)}
                                      </MenuItem>
                                    )
                                  }
                                )}
                              </TextField>
                            )}
                          </Box>
                        )}
                      </TableCell>
                    )
                  }
                  return <Fragment key={`search-cell-${index}`} />
                })}
              <TableCell style={{ width: ColWidths.md }} />
              <TableCell style={{ padding: theme.spacing(0.8) }} />
            </ASSearchTableRow>
            {ordersByDate &&
              ordersByDate.map((order: IOrderByDate, index) => {
                return (
                  <ASTableRow
                    className="table-results-row"
                    key={`accordion-row-${index}`}
                  >
                    <TableCell colSpan={11} style={{ padding: 0 }}>
                      <ASAccordion
                        elevation={0}
                        square={true}
                        defaultExpanded={index === 0 ? true : false}
                      >
                        <ASAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${1}-content`}
                          id={`panel${1}-header`}
                        >
                          <Typography>
                            <strong>Ordered On:</strong> {order.date}
                          </Typography>
                        </ASAccordionSummary>
                        <ASAccordionDetails>
                          <Table>
                            <TableBody>
                              {order.orders &&
                                order.orders.map(
                                  (
                                    order: IOrderList,
                                    index: number
                                  ): React.ReactNode => {
                                    if (order.id && index < 10) {
                                      const rowValues: ITableCell[] = tableHeaders.map(
                                        (item) => {
                                          const cellValue: ITableCell = {
                                            value: null,
                                            type: 'text',
                                            size: 'small',
                                            colSize: ColWidthsType.MD
                                          }

                                          for (const [
                                            key,
                                            value
                                          ] of Object.entries(order)) {
                                            if (item.name === key) {
                                              cellValue.value = value
                                              cellValue.size = item.size
                                              cellValue.type = item.type
                                              cellValue.colSize = item.colWidth
                                            }
                                          }
                                          return cellValue
                                        }
                                      )

                                      return (
                                        <ASTableRow key={`row-${index}`}>
                                          <ASTableCell
                                            style={{
                                              padding: theme.spacing(0.8)
                                            }}
                                          />

                                          {rowValues.map(
                                            (item: ITableCell, idx) => {
                                              if (item.type === 'text') {
                                                return (
                                                  <ASTableCell
                                                    key={`text-cell-${
                                                      idx + index
                                                    }`}
                                                    onClick={() => {
                                                      history.push(
                                                        `/orders/${order.id}`
                                                      )
                                                    }}
                                                    size={item.size}
                                                    style={{
                                                      width:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ],
                                                      minWidth:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ]
                                                    }}
                                                  >
                                                    {item.value}
                                                  </ASTableCell>
                                                )
                                              }
                                              if (
                                                item.type === 'digitalDateTime'
                                              ) {
                                                return (
                                                  <ASTableCell
                                                    key={`text-cell-digital-date-cell-${
                                                      idx + index
                                                    }`}
                                                    onClick={() => {
                                                      history.push(
                                                        `/orders/${order.id}`
                                                      )
                                                    }}
                                                    size={item.size}
                                                    style={{
                                                      width:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ],
                                                      minWidth:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ]
                                                    }}
                                                  >
                                                    {typeof item.value ===
                                                      'string' &&
                                                      moment(item.value).format(
                                                        'DD-MM-YYYY HH:mm'
                                                      )}
                                                  </ASTableCell>
                                                )
                                              }
                                              if (item.type === 'date') {
                                                return (
                                                  <ASTableCell
                                                    key={`text-cell-date-${
                                                      idx + index
                                                    }`}
                                                    onClick={() => {
                                                      history.push(
                                                        `/orders/${order.id}`
                                                      )
                                                    }}
                                                    size={item.size}
                                                    style={{
                                                      width:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ],
                                                      minWidth:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ]
                                                    }}
                                                  >
                                                    {typeof item.value ===
                                                      'string' && (
                                                      <Moment
                                                        local={true}
                                                        format="ddd DD-MMM-YYYY"
                                                        date={item.value}
                                                      />
                                                    )}
                                                  </ASTableCell>
                                                )
                                              }
                                              if (item.type === 'dateTime') {
                                                return (
                                                  <ASTableCell
                                                    key={`text-cell-date-time-${
                                                      idx + index
                                                    }`}
                                                    onClick={() => {
                                                      history.push(
                                                        `/orders/${order.id}`
                                                      )
                                                    }}
                                                    size={item.size}
                                                    style={{
                                                      width:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ],
                                                      minWidth:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ]
                                                    }}
                                                  >
                                                    {typeof item.value ===
                                                      'string' && (
                                                      <Moment
                                                        local={true}
                                                        format="ddd DD-MMM-YYYY HH:mm"
                                                        date={item.value}
                                                      />
                                                    )}
                                                  </ASTableCell>
                                                )
                                              }
                                              if (item.type === 'status') {
                                                return (
                                                  <ASTableCell
                                                    key={`status-cell-status-${
                                                      idx + index
                                                    }`}
                                                    onClick={() =>
                                                      history.push(
                                                        `/orders/${order.id}`
                                                      )
                                                    }
                                                    size={item.size}
                                                    style={{
                                                      width:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ],
                                                      minWidth:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ]
                                                    }}
                                                  >
                                                    {item.value && (
                                                      <ASStatus
                                                        status={
                                                          findOrderStatusTypeByNumber[
                                                            `${item.value}`
                                                          ]
                                                        }
                                                      />
                                                    )}
                                                  </ASTableCell>
                                                )
                                              }
                                              if (item.type === 'checked') {
                                                return (
                                                  <ASTableCell
                                                    key={`checked-cell-${
                                                      idx + index
                                                    }`}
                                                    style={{
                                                      color:
                                                        theme.palette.primary
                                                          .dark,
                                                      width:
                                                        ColWidths[
                                                          item.colSize
                                                            ? item.colSize
                                                            : 'auto'
                                                        ]
                                                    }}
                                                    align="center"
                                                    onClick={() =>
                                                      history.push(
                                                        `/orders/${order.id}`
                                                      )
                                                    }
                                                    size={item.size}
                                                  >
                                                    {item.value && (
                                                      <CheckCircleIcon color="inherit" />
                                                    )}
                                                  </ASTableCell>
                                                )
                                              }
                                              return <></>
                                            }
                                          )}
                                          <ASTableCell
                                            align="center"
                                            style={{
                                              width: ColWidths.md,
                                              minWidth: ColWidths.md
                                            }}
                                          >
                                            {order.orderStatus.id === 1 && (
                                              <Button
                                                color="default"
                                                fullWidth={true}
                                                disableElevation={true}
                                                variant="contained"
                                                component={Link}
                                                to={{
                                                  pathname: `/orders/${order.id}`,
                                                  state: {
                                                    isEditing: true
                                                  }
                                                }}
                                              >
                                                Continue
                                              </Button>
                                            )}
                                            {order.orderStatus.id !== 1 && (
                                              <Button
                                                color="default"
                                                fullWidth={true}
                                                disableElevation={true}
                                                variant="contained"
                                                component={Link}
                                                to={{
                                                  pathname: `/orders/${order.id}`,
                                                  state: {
                                                    isEditing: true,
                                                    isRepeat: true
                                                  }
                                                }}
                                              >
                                                <LoopIcon
                                                  style={{ marginRight: '5px' }}
                                                  fontSize="small"
                                                />
                                                Repeat
                                              </Button>
                                            )}
                                          </ASTableCell>
                                          <ASTableCell
                                            style={{
                                              padding: theme.spacing(0.8)
                                            }}
                                          ></ASTableCell>
                                        </ASTableRow>
                                      )
                                    }
                                    return (
                                      <Fragment key={`search-cell-${index}`} />
                                    )
                                  }
                                )}
                            </TableBody>
                          </Table>
                        </ASAccordionDetails>
                      </ASAccordion>
                    </TableCell>
                  </ASTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

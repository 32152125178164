import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState
} from 'react'
import { OrderDetail } from './OrderDetail'
import {
  IOrder,
  IError,
  IOrganisation,
  OrderStatusModel,
  ProductRouteModel,
  IResponseOptions,
  IProductOptions
} from '../../../../models'
import {
  getOrderById,
  putOrderStatus,
  postOrder,
  putOrder,
  getOrganisationsById,
  getStatusOptions,
  getProductOptions
} from '../../../../services/ApiService'
import { OrderDetailForm } from './OrderDetailForm'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { Box, CircularProgress, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { setActiveOrder } from '../../../../features'
import { AppContext } from '../../../../context'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../../services/Analytics'
import { OrderStatusType } from '../../../../types'

interface IOrderRouteParams {
  /** The id of this feature passed through via the route params */
  id: string
}

interface ILocation {
  isEditing?: boolean
  isRepeat?: boolean
}

interface IOrderDetailContainerProps
  extends RouteComponentProps<IOrderRouteParams> {}

export const OrderDetailContainer: FunctionComponent<IOrderDetailContainerProps> = ({
  match,
  location
}) => {
  const { dispatch, user } = useContext(AppContext)
  const [order, setOrder] = useState<IOrder | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isNewOrder, setIsNewOrder] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isRepeat, setIsRepeat] = useState<boolean>(false)
  const [organisation, setOrganisation] = useState<IOrganisation>()
  const [isCancelable, setIsCancelable] = useState<boolean>(false)
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState<
    ProductRouteModel[]
  >()

  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()

  useEffect(() => {
    const { id } = match.params
    if (id && id !== 'new') {
      getOrderById(id)
        .then((response) => {
          const orderResponse = response.data

          if (location && location.state) {
            const { isEditing, isRepeat } = location.state as ILocation
            isEditing && setIsEditing(true)
            isRepeat && setIsRepeat(true)
          }

          setOrder(orderResponse)
          setActiveOrder(dispatch, orderResponse)

          getOrganisationsById(response.data.organisationId)
            .then((respo) => {
              setOrganisation(respo.data)
              getStatusOptions(parseInt(id))
                .then((resp) => {
                  const statusValues: string[] = resp.data.map(
                    (item: OrderStatusModel) => item.description
                  )
                  setIsCancelable(
                    statusValues.includes(OrderStatusType.Cancelled)
                  )
                  setIsLoading(false)
                })
                .catch((errorMessage) => {
                  console.warn(errorMessage)
                  setError({
                    hasError: true,
                    errorMessage: 'failed to get order status options'
                  })
                })
            })
            .catch((errorMessage) => {
              console.warn(errorMessage)
              setError({
                hasError: true,
                errorMessage: 'failed to get organisation details'
              })
            })
        })
        .catch((errorMessage) => {
          console.warn(errorMessage)
          setError({
            hasError: true,
            errorMessage: 'failed to get order'
          })
        })
    }
    getProductOptions()
      .then((response) => {
        const options = response.data as IProductOptions | undefined

        const optionsObject: IResponseOptions | undefined =
          options && options ? { ...options } : undefined

        optionsObject?.productUnitOfMeasurement &&
          setUnitOfMeasureOptions(optionsObject.productUnitOfMeasurement)
        setIsLoading(false)
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to load product option values'
        })
      })
    if (id && id === 'new') {
      setIsNewOrder(true)
      setIsEditing(true)
    }
    setIsLoading(false)
  }, [match.params, location, dispatch])

  const handleCancelOrder = () => {
    order &&
      putOrderStatus(order.id, 6)
        .then((response) => {
          if (response.data === true) {
            setActiveOrder(dispatch, undefined)
            history.push('/orders')
          } else {
            setError({
              hasError: true,
              errorMessage: 'failed to set order status to canceled'
            })
          }
        })
        .catch((errorMessage) => {
          console.warn(errorMessage)
          setError({
            hasError: true,
            errorMessage: 'failed to set order status to canceled'
          })
        })
  }

  const handleSubmission = (order: any, inProgress: boolean) => {
    !isNewOrder &&
      !isRepeat &&
      order &&
      putOrder(order)
        .then(() => {
          inProgress === true &&
            putOrderStatus(parseInt(order.orderId), 2).catch((errorMessage) => {
              console.warn(errorMessage)
              setError({
                hasError: true,
                errorMessage: 'failed to set order status to is-processing'
              })
            })
        })
        .then(() => {
          setActiveOrder(dispatch, undefined)
          history.push('/orders')
        })
        .catch((errorMessage) => {
          console.warn(errorMessage)
          setError({
            hasError: true,
            errorMessage: 'failed to update existing order'
          })
          analyticsServiceSingleton.trackError(errorMessage)
        })
    isNewOrder &&
      postOrder(order)
        .then((response) => {
          inProgress === true &&
            response.data.id &&
            putOrderStatus(response.data.id, 2).catch((errorMessage) => {
              console.warn(errorMessage)
              setError({
                hasError: true,
                errorMessage: 'failed to set order status to is-processing'
              })
            })
        })
        .then(() => {
          setActiveOrder(dispatch, undefined)
          history.push('/orders')
        })
        .catch((errorMessage) => {
          console.warn(errorMessage)
          setError({
            hasError: true,
            errorMessage: 'failed to post new order'
          })
          analyticsServiceSingleton.trackError(errorMessage)
        })
    isRepeat &&
      order &&
      postOrder(order)
        .then((response) => {
          inProgress === true &&
            response.data.id &&
            putOrderStatus(response.data.id, 2).catch((errorMessage) => {
              console.warn(errorMessage)
              setError({
                hasError: true,
                errorMessage: 'failed to set order status to is-processing'
              })
            })
        })
        .then(() => {
          setActiveOrder(dispatch, undefined)
          user &&
            user.profile &&
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.RequestRepeatOrder,
              {
                userId: user.profile.email
              }
            )
          history.push('/orders')
        })
        .catch((error) => {
          console.warn(error)
          setError({
            hasError: true,
            errorMessage: 'failed to post new cloned order'
          })
          analyticsServiceSingleton.trackError(error)
        })
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  return (
    <>
      {isLoading === true && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {isLoading === false &&
        isNewOrder === true &&
        isEditing === true &&
        isRepeat === false &&
        unitOfMeasureOptions && (
          <OrderDetailForm
            isNewOrder={isNewOrder}
            submitOrder={(orderItem, inProgress) =>
              handleSubmission(orderItem, inProgress)
            }
            unitOfMeasureOptions={unitOfMeasureOptions}
          />
        )}
      {isLoading === false && isRepeat && order && unitOfMeasureOptions && (
        <OrderDetailForm
          isNewOrder={true}
          isRepeat={true}
          order={order}
          submitOrder={(orderItem, inProgress) =>
            handleSubmission(orderItem, inProgress)
          }
          unitOfMeasureOptions={unitOfMeasureOptions}
        />
      )}
      {isLoading === false &&
        isEditing &&
        order &&
        !isRepeat &&
        unitOfMeasureOptions && (
          <OrderDetailForm
            isNewOrder={false}
            order={order}
            submitOrder={(orderItem, inProgress) =>
              handleSubmission(orderItem, inProgress)
            }
            unitOfMeasureOptions={unitOfMeasureOptions}
          />
        )}
      {isLoading === false && isEditing === false && order && (
        <OrderDetail
          isCancelable={isCancelable}
          organisation={organisation}
          cancelOrder={handleCancelOrder}
          order={order}
        />
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  useTheme,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  CircularProgress,
  Checkbox
} from '@material-ui/core'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { object, string, number } from 'yup'
import { IOrganisation } from '../../../models'
import { CreateUserModel } from '../../../models/swaggerTypesModified'
import { ASUserStatusButton } from '../../../components/ASUserStatusButton'
import { UserStatus, TUserStatus } from '../../../types'
import { UserModel } from '../../../models/swaggerTypesModified'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

const blankUser: UserModel = {
  id: 0,
  uid: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  jobDescription: '',
  subs: '',
  organisationId: 0,
  organisationName: '',
  lastLoginDate: '',
  role: '',
  roleEnum: 0,
  isActive: true
}

const errorMessage = {
  dropdown: 'Please select an option',
  required: 'Required field',
  email: 'Please provide a valid email address'
}

const userSchema = object().shape({
  firstName: string().trim().required(errorMessage.required),
  lastName: string().trim().required(errorMessage.required),
  organisationId: number().min(1, errorMessage.required),
  email: string().trim().email(errorMessage.email),
  jobDescription: string().trim().required(errorMessage.required)
})

interface IUserDetailProps {
  /* The user passed down into the detail */
  user?: UserModel
  /* The user passed down into the detail */
  organisations?: IOrganisation[]
  /* Whether the user is being edited or not */
  isEditing?: boolean
  /* Whether the user is new or not */
  isNewUser?: boolean
  /* Informs comonent if request is in progress */
  isRequestingStatus?: boolean
  /* Informs comonent if password reset request is in progress */
  isRequestingPasswordReset?: boolean
  /* Toggle editing ability of form for existing user */
  toggleEditing?: () => void
  /* send user submission object to parent */
  submitUser: (org: CreateUserModel) => void
  /* inform parent of password reset request */
  handlePasswordReset?: () => void
  /* request status change from parent */
  requestStatusChange?: (item: UserStatus) => void
}

export const UserDetail: FunctionComponent<IUserDetailProps> = ({
  user = blankUser,
  organisations,
  isEditing,
  isNewUser,
  isRequestingStatus,
  isRequestingPasswordReset,
  toggleEditing,
  submitUser,
  handlePasswordReset,
  requestStatusChange
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const {
    register,
    handleSubmit,
    errors,
    formState,
    control,
    setValue,
    watch
  } = useForm<any>({
    defaultValues: user,
    resolver: yupResolver(userSchema)
  })

  const watchOrganisation = watch('organisationId')

  useEffect(() => {
    setValue('role', watchOrganisation === 1 ? 'Admin' : 'Customer')
  }, [watchOrganisation, setValue])

  const isSubmitDisabled =
    Object.entries(formState.dirtyFields).length === 0 && !formState.isValid

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={theme.breakpoints.values.md}
      width="100%"
      marginX="auto"
      mb={theme.spacing(1)}
    >
      <form onSubmit={handleSubmit(submitUser)}>
        <Box pt={2}>
          <Box>
            <Button
              startIcon={<LeftIcon />}
              onClick={() => history.push('/users')}
            >
              Go Back
            </Button>
          </Box>
          <Box p={2} pl={0}>
            {!isNewUser && user.id && (
              <Typography variant="h4" color="primary">
                Reference: {user.id}
              </Typography>
            )}
            {isNewUser && (
              <Typography variant="h4" color="primary">
                New User
              </Typography>
            )}
          </Box>
          <Box
            bgcolor={theme.palette.primary.light}
            borderRadius={3}
            p={2}
            mb={2}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="role"
                  inputRef={register({})}
                  style={{ display: 'none' }}
                />

                <Checkbox
                  name="sendInvite"
                  checked={true}
                  inputRef={register({})}
                  style={{ display: 'none' }}
                />
                <TextField
                  name="firstName"
                  inputRef={register({})}
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  disabled={!isEditing}
                  error={errors.firstName?.message ? true : false}
                  helperText={errors.firstName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  inputRef={register({})}
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  disabled={!isEditing}
                  error={errors.lastName?.message ? true : false}
                  helperText={errors.lastName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  inputRef={register({})}
                  fullWidth
                  label="Email (Username)"
                  variant="outlined"
                  disabled={!isEditing}
                  error={errors.email?.message ? true : false}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="jobDescription"
                  inputRef={register({})}
                  fullWidth
                  label="Job Position"
                  variant="outlined"
                  disabled={!isEditing}
                  error={errors.jobDescription?.message ? true : false}
                  helperText={errors.jobDescription?.message}
                />
              </Grid>
            </Grid>
          </Box>
          <Box bgcolor={theme.palette.text.hint} borderRadius={3} p={2} mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="organisationId"
                  as={
                    <TextField
                      label="Customer"
                      select
                      value={user.organisationId | 0}
                      fullWidth={true}
                      variant={'outlined'}
                      disabled={!isEditing}
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      {organisations &&
                        organisations.map((item: IOrganisation, index) => (
                          <MenuItem
                            key={`organisation-${index}`}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={
                    user.isActive ? UserStatus.Active : UserStatus.Inactive
                  }
                  label="Status"
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            hidden={isNewUser}
            borderColor={theme.palette.grey[200]}
            border={1}
            borderRadius={3}
            p={2}
            mb={2}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={moment(user.lastLoginDate).format('DD-MM-YYYY HH:mm')}
                  variant="outlined"
                  fullWidth
                  label="Last Login"
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid
            style={{ flexGrow: 1, justifyContent: 'flex-end' }}
            container
            spacing={3}
          >
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => history.goBack()}
                disableElevation={true}
                variant="outlined"
              >
                GO BACK
              </Button>
              {!isNewUser && (
                <>
                  <Button
                    color="default"
                    onClick={() => handlePasswordReset && handlePasswordReset()}
                    disableElevation={true}
                    variant="contained"
                  >
                    Request password reset
                    {isRequestingPasswordReset && (
                      <CircularProgress
                        style={{ marginLeft: theme.spacing(1) }}
                        size="20px"
                      />
                    )}
                  </Button>
                  <ASUserStatusButton
                    isRequestingStatus={isRequestingStatus}
                    selectedStatus={
                      user.isActive ? UserStatus.Active : UserStatus.Inactive
                    }
                    handleSelectedOption={(status: TUserStatus) =>
                      requestStatusChange && requestStatusChange(status)
                    }
                  />
                </>
              )}

              {isEditing && (
                <Button
                  type="submit"
                  disableElevation={true}
                  variant="contained"
                  disabled={isSubmitDisabled}
                  color={
                    formState.touched && isEditing && !formState.isSubmitted
                      ? 'primary'
                      : 'default'
                  }
                >
                  Save & exit
                </Button>
              )}

              {!isEditing && (
                <Button
                  color="primary"
                  onClick={() => toggleEditing && toggleEditing()}
                  disableElevation={true}
                  variant="contained"
                >
                  edit
                </Button>
              )}
            </div>
          </Grid>
        </Box>
      </form>
      {/* <pre>{JSON.stringify(formState, null, 2)}</pre>
      <pre>{JSON.stringify(watchAllFields, null, 4)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre> */}
    </Box>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { UserDetail } from './UserDetail'
import { IOrganisation, IError, UserModel, ISuccess } from '../../../models'
import {
  getOrganisations,
  userPasswordReset,
  getUserById,
  postLockUser,
  putUser,
  cancelGetUserById,
  cancelGetOrganisations,
  cancelPutUser,
  cancelUserPasswordReset,
  cancelPostLockUser
} from '../../../services/ApiService'
import { Snackbar, Box, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { CreateUserModel } from '../../../models/swaggerTypesModified'
import { UserStatus, findUserStatusNumber } from '../../../types'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'

interface IOrganisationRouteParams {
  /** The id of this feature passed through via the route params */
  id: string
}

interface IUserDetailContainerProps
  extends RouteComponentProps<IOrganisationRouteParams> {}

export const UserDetailContainer: FunctionComponent<IUserDetailContainerProps> = ({
  match
}) => {
  const [user, setUser] = useState<UserModel | undefined>(undefined)
  const [organisations, setOrganisations] = useState<
    IOrganisation[] | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isRequestingPasswordReset, setIsRequestingPasswordReset] = useState<
    boolean
  >(false)

  const [isRequestingStatus, setIsRequestingStatus] = useState<boolean>(false)

  const [success, setSuccess] = useState<ISuccess>({
    hasSuccess: false
  })
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()
  const { id } = match.params

  useEffect(() => {
    const { id } = match.params
    if (id && id !== 'new') {
      getUserById(id)
        .then((response) => {
          setUser(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to get order by id'
          })
        })
    }
  }, [match.params])

  useEffect(() => {
    getOrganisations()
      .then((response) => {
        setOrganisations(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setError({
          hasError: true,
          errorMessage: 'failed to get organisations list'
        })
      })
  }, [])

  const handleEditingToggle = () => {
    setIsEditing(!isEditing)
  }

  const handleSubmission = (value: CreateUserModel) => {
    user &&
      user.subs &&
      putUser(user.subs, value)
        .then((response) => {
          if (response.data) {
            history.goBack()
          } else {
            setError({
              hasError: true,
              errorMessage:
                'A user already exists with this email address, please provide a different value.'
            })
          }
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to update existing user'
          })
        })
  }

  const handlePasswordReset = () => {
    setIsRequestingPasswordReset(true)
    user &&
      user.subs &&
      userPasswordReset(user.subs)
        .then((response) => {
          setIsRequestingPasswordReset(true)
          if (response.data === true) {
            setSuccess({
              hasSuccess: true,
              successMessage: `A request will be submitted to ${user?.username}`
            })
            analyticsServiceSingleton.trackEvent(AnalyticsEvent.InviteSent, {
              emailaddress: user.email,
              userId: user.id
            })
          } else {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.InviteSentError,
              {
                emailaddress: user.email,
                userId: user.id
              }
            )
            setError({
              hasError: true,
              errorMessage: 'The request failed, please try again later'
            })
          }
          setIsRequestingPasswordReset(false)
        })
        .catch((error) => {
          setIsRequestingPasswordReset(false)
          setError({
            hasError: true,
            errorMessage: 'failed to request password reset'
          })
        })
  }

  const handleStatusChangeRequest = (value: UserStatus) => {
    setIsRequestingStatus(true)
    const userStatusNumber = findUserStatusNumber[value]
    user &&
      user.subs &&
      postLockUser(user.subs, userStatusNumber)
        .then((response) => {
          response.data === true &&
            setSuccess({
              hasSuccess: true,
              successMessage: `The status has been updated successfully`
            })
          setIsRequestingStatus(false)
        })
        .then(() => {
          getUserById(id)
            .then((response) => {
              setUser(response.data)
              setIsLoading(false)
            })
            .catch((error) => {
              console.log(error)
              setError({
                hasError: true,
                errorMessage: 'failed to get order by id'
              })
            })
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to modify user status'
          })
        })
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
    setSuccess({ hasSuccess: false, successMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelGetUserById()
      cancelGetOrganisations()
      cancelPutUser()
      cancelUserPasswordReset()
      cancelPostLockUser()
    }
  }, [])

  return (
    <>
      {id && id !== 'new' && (
        <>
          {isLoading && (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && user && (
            <UserDetail
              user={user}
              isNewUser={false}
              isEditing={isEditing}
              organisations={organisations}
              isRequestingStatus={isRequestingStatus}
              isRequestingPasswordReset={isRequestingPasswordReset}
              toggleEditing={handleEditingToggle}
              submitUser={handleSubmission}
              handlePasswordReset={handlePasswordReset}
              requestStatusChange={(value) => handleStatusChangeRequest(value)}
            />
          )}

          {success.hasSuccess && (
            <Snackbar
              open={success.hasSuccess}
              autoHideDuration={3000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="success">
                {success.successMessage}
              </Alert>
            </Snackbar>
          )}

          {error.hasError && (
            <Snackbar
              open={error.hasError}
              autoHideDuration={4000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="error">
                {error.errorMessage}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  )
}

import { Dictionary } from '.'

export enum User {
  Admin = 'Admin',
  Customer = 'Customer'
}

export type TUser = User.Admin | User.Customer

export const findUserType: Dictionary<TUser> = {
  [User.Admin]: User.Admin,
  [User.Customer]: User.Customer
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type TUserStatus = UserStatus.Active | UserStatus.Inactive

export const findUserStatusType: Dictionary<TUserStatus> = {
  [UserStatus.Active]: UserStatus.Active,
  [UserStatus.Inactive]: UserStatus.Inactive
}

export const findUserStatusNumber: Dictionary<number> = {
  [UserStatus.Inactive]: 1,
  [UserStatus.Active]: 0
}

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  useTheme,
  IconButton,
  Box,
  TextField,
  MenuItem,
  makeStyles,
  Snackbar,
  Typography,
  Tooltip,
  InputAdornment
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import {
  ASTableHeaderCell,
  ASTableBody,
  ASSlimTableCell,
  ASTableSortLabel,
  ASSearchTableRow,
  ASTableRow
} from '../ASTable'
import {
  ITableHeader,
  IOrderList,
  ITableCell,
  IOrder,
  IError
} from '../../models'
import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'
import {
  TSortDirection,
  SortDirectionType,
  findOrderStatusTypeByNumber,
  OrderStatusType
} from '../../types'
import { toggleSortDirection } from '../../features/sort.models'
import { useHistory } from 'react-router-dom'
import { ASSearchTextField } from '../ASSearch'
import { ASCheckbox } from '../ASForm'
import { ASPrintTemplate } from '../ASPrintTemplate'
import { ColWidthsType, ColWidths } from '../../constants'
import { printOrders } from '../../services/ApiService'
import { ASStatus } from '../ASStatus'
import { capitalizeFirstLetter, firstCharLowerCase } from '../../utility'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

interface IASOrdersTableProps {
  selectedOrders: number[]
  /** Requires an array of Organisation */
  orders: IOrderList[]
  /** Table header values are provided by its parent including sort direction state  */
  tableHeaders: ITableHeader[]
  /** Provides the parent the name of the column that is being requested to sort */
  requestSort: (columnName: string, sortDirection: TSortDirection) => void
  /** Provides the parent updated table headers that include search values */
  requestSearch: (tableHeaders: ITableHeader[]) => void
  handleSelectedOrder: (event: React.ChangeEvent, name: number) => void
  clearSelectedOrders: () => void
}

export const ASOrdersTable: FunctionComponent<IASOrdersTableProps> = ({
  selectedOrders,
  orders,
  tableHeaders,
  requestSort,
  requestSearch,
  handleSelectedOrder,
  clearSelectedOrders
}) => {
  const history = useHistory()
  const theme = useTheme()
  const [ordersToPrint, setOrdersToPrint] = useState<IOrder[]>()
  const [searchValues, setSearchValues] = useState<ITableHeader[]>(tableHeaders)
  const [printLoading, setPrintLoading] = useState<boolean>(false)

  const [error, setError] = useState<IError>({
    hasError: false
  })

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  const useStyles = makeStyles(() => ({
    statusSelect: {
      padding: '0',

      input: {
        padding: '10px'
      }
    }
  }))
  const classes = useStyles()

  const handleSearchClear = (searchName: string) => {
    const updateSearchValue = searchValues.map((field: ITableHeader) => {
      if (field.name === searchName) {
        field.searchValue = ''
      }
      return field
    })
    setSearchValues(updateSearchValue)
  }

  const handleSearch = (value: string, searchName: string) => {
    const updateSearchValue = searchValues.map((field: ITableHeader) => {
      const normalisedFieldName = firstCharLowerCase(
        field.name.replace('header', '')
      )
      const normalisedSearchName = firstCharLowerCase(
        searchName.replace('header', '')
      )
      if (normalisedFieldName === normalisedSearchName) {
        if (value === 'all') {
          handleSearchClear(searchName)
        } else {
          field.searchValue = value
        }
      }
      return field
    })
    setSearchValues(updateSearchValue)
  }

  useEffect(() => {
    requestSearch(searchValues)
  }, [searchValues, requestSearch])

  const isSelected = (name: number) => selectedOrders.indexOf(name) !== -1

  const handlePrinted = () => {
    clearSelectedOrders()
  }

  useEffect(() => {
    selectedOrders.length > 0 && setPrintLoading(true)
    selectedOrders &&
      selectedOrders.length > 0 &&
      printOrders(selectedOrders)
        .then((response) => {
          const requestedOrders: IOrder[] = response.data
          setOrdersToPrint(requestedOrders)
          setPrintLoading(false)
        })
        .catch(() => {
          setError({
            hasError: true,
            errorMessage: 'failed to load print order'
          })
        })
  }, [selectedOrders])

  return (
    <div className="orders-list">
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
      <TableContainer>
        <Table
          style={{
            width: '1625px',
            tableLayout: 'auto',
            margin: '0 auto'
          }}
        >
          <TableHead>
            <TableRow>
              <ASTableHeaderCell />
              {tableHeaders.map((header: ITableHeader, index: number) => {
                return (
                  <ASTableHeaderCell
                    key={`head-cell-${index}`}
                    style={{
                      width:
                        ColWidths[header.colWidth ? header.colWidth : 'auto'],
                      minWidth:
                        ColWidths[header.colWidth ? header.colWidth : 'auto']
                    }}
                  >
                    {header.sortable === undefined && (
                      <ASTableSortLabel
                        active={header.sortDirection !== SortDirectionType.None}
                        direction={
                          header.sortDirection === SortDirectionType.Ascending
                            ? SortDirectionType.Ascending
                            : SortDirectionType.Descending
                        }
                        onClick={() =>
                          requestSort(
                            header.name,
                            header.sortDirection &&
                              toggleSortDirection(header.sortDirection)
                          )
                        }
                      >
                        {header.displayName}
                      </ASTableSortLabel>
                    )}
                    {header.sortable === false && (
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: theme.palette.common.white,
                          fontWeight: theme.typography.fontWeightBold,
                          lineHeight: '1'
                        }}
                      >
                        {header.displayName}
                      </Typography>
                    )}
                  </ASTableHeaderCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            <ASSearchTableRow>
              <TableCell
                style={{
                  width: ColWidths.xs,
                  minWidth: ColWidths.xs,
                  padding: 0
                }}
                align="center"
              >
                <ASPrintTemplate
                  isLoading={printLoading}
                  handlePrinted={handlePrinted}
                  orders={ordersToPrint}
                  active={selectedOrders && selectedOrders?.length > 0}
                />
              </TableCell>
              {tableHeaders.map((searchField: ITableHeader, index: number) => {
                return (
                  <TableCell padding="none" key={`search-cell-${index}`}>
                    {searchField.searchable === true && (
                      <Box
                        pr={theme.spacing(0.2)}
                        pl={theme.spacing(0.1)}
                        display="flex"
                        alignItems="center"
                      >
                        {searchField.type !== 'status' &&
                          searchField.type !== 'date' &&
                          searchField.type !== 'digitalDateTime' && (
                            <>
                              <ASSearchTextField
                                size="small"
                                variant="outlined"
                                value={searchField.searchValue}
                                onChange={(event) => {
                                  handleSearch(
                                    event.target.value,
                                    searchField.name
                                  )
                                }}
                                id={searchField.name}
                              />
                              {searchField.searchValue && (
                                <Box ml={'5px'}>
                                  <IconButton
                                    onClick={() =>
                                      handleSearchClear(searchField.name)
                                    }
                                    edge="start"
                                    size="small"
                                    color="default"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </>
                          )}

                        {searchField.type === 'status' && (
                          <>
                            <TextField
                              className={classes.statusSelect}
                              select={true}
                              variant="standard"
                              fullWidth={true}
                              defaultValue={
                                searchField.searchValue === ''
                                  ? 'All'
                                  : searchField.searchValue &&
                                    searchField.searchValue
                              }
                              onChange={(event) => {
                                handleSearch(
                                  event.target.value.toLowerCase(),
                                  searchField.name
                                )
                              }}
                            >
                              <MenuItem value="All">All</MenuItem>
                              {Object.values(OrderStatusType).map(
                                (item, idx) => {
                                  return (
                                    <MenuItem
                                      key={`status-${idx}`}
                                      value={item.toLocaleLowerCase()}
                                    >
                                      {capitalizeFirstLetter(item)}
                                    </MenuItem>
                                  )
                                }
                              )}
                            </TextField>
                          </>
                        )}

                        {(searchField.type === 'date' ||
                          searchField.type === 'digitalDateTime') && (
                          <>
                            {typeof searchField.searchValue === 'string' && (
                              <>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    variant="inline"
                                    margin="none"
                                    fullWidth={true}
                                    format="d MMM yyyy"
                                    onChange={(event) => {
                                      if (event !== null) {
                                        handleSearch(
                                          `${moment(new Date(event)).format(
                                            'YYYY-MM-DD'
                                          )}`,
                                          searchField.name
                                        )
                                      }
                                    }}
                                    value={
                                      searchField.searchValue
                                        ? `${moment(
                                            new Date(searchField.searchValue)
                                          ).format('YYYY-MM-DD')}T00:00`
                                        : null
                                    }
                                    emptyLabel=" "
                                    InputProps={{
                                      endAdornment: searchField.searchValue ? (
                                        <Box ml={'5px'}>
                                          <IconButton
                                            onClick={() =>
                                              handleSearchClear(
                                                searchField.name
                                              )
                                            }
                                            edge="start"
                                            size="small"
                                            color="default"
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </Box>
                                      ) : (
                                        <InputAdornment position="end">
                                          <CalendarTodayIcon fontSize="small" />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    )}
                  </TableCell>
                )
              })}
            </ASSearchTableRow>
          </TableBody>
          <ASTableBody>
            {orders &&
              (orders.map(
                (field: IOrderList, index: number): React.ReactNode => {
                  if (field.id) {
                    const isItemSelected = isSelected(field.id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    const rowValues: ITableCell[] = tableHeaders.map((item) => {
                      const cellValue: ITableCell = {
                        value: null,
                        type: 'text',
                        size: 'small',
                        colSize: ColWidthsType.MD
                      }

                      for (const [key, value] of Object.entries(field)) {
                        if (item.name === key) {
                          cellValue.value = value
                          cellValue.size = item.size
                          cellValue.type = item.type
                          cellValue.colSize = item.colWidth
                        }
                      }
                      return cellValue
                    })

                    return (
                      <ASTableRow
                        key={`row-${index}`}
                        className={`table-results-row ${
                          isItemSelected ? 'active' : ''
                        }`}
                      >
                        <ASSlimTableCell
                          color={theme.palette.primary.dark}
                          align="center"
                          padding="checkbox"
                        >
                          <ASCheckbox
                            color="primary"
                            onChange={(event) =>
                              field.id && handleSelectedOrder(event, field.id)
                            }
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ASSlimTableCell>

                        {rowValues &&
                          rowValues.map((item: ITableCell, idx) => {
                            if (item.type === 'text') {
                              return (
                                <ASSlimTableCell
                                  key={`text-cell-${idx + index}`}
                                  onClick={() => {
                                    history.push(`/orders/${field.id}`)
                                  }}
                                  size={item.size}
                                  style={{
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                >
                                  {typeof item.value === 'string' && item.value}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'number') {
                              return (
                                <ASSlimTableCell
                                  key={`text-cell-number${idx + index}`}
                                  onClick={() => {
                                    history.push(`/orders/${field.id}`)
                                  }}
                                  size={item.size}
                                  style={{
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                >
                                  {typeof item.value === 'number' && item.value}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'dateTime') {
                              return (
                                <ASSlimTableCell
                                  key={`text-cell-date-time${idx + index}`}
                                  onClick={() => {
                                    history.push(`/orders/${field.id}`)
                                  }}
                                  size={item.size}
                                  style={{
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                >
                                  {typeof item.value === 'string' &&
                                    moment(item.value).format(
                                      'ddd DD-MMM-YYYY HH:mm'
                                    )}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'digitalDateTime') {
                              return (
                                <ASSlimTableCell
                                  key={`text-cell-digital-time-${idx + index}`}
                                  onClick={() => {
                                    history.push(`/orders/${field.id}`)
                                  }}
                                  size={item.size}
                                  style={{
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                >
                                  {typeof item.value === 'string' &&
                                    moment(item.value).format(
                                      'DD-MM-YYYY HH:mm'
                                    )}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'date') {
                              return (
                                <ASSlimTableCell
                                  key={`text-cell-date-${idx + index}`}
                                  onClick={() => {
                                    history.push(`/orders/${field.id}`)
                                  }}
                                  size={item.size}
                                  style={{
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                >
                                  {typeof item.value === 'string' &&
                                    moment(item.value).format(
                                      'ddd DD-MMM-YYYY'
                                    )}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'status') {
                              return (
                                <ASSlimTableCell
                                  key={`status-cell-status-${idx + index}`}
                                  onClick={() =>
                                    history.push(`/orders/${field.id}`)
                                  }
                                  size={item.size}
                                  style={{
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                >
                                  {item.value &&
                                    typeof item.value === 'number' && (
                                      <ASStatus
                                        status={
                                          findOrderStatusTypeByNumber[
                                            item.value
                                          ]
                                        }
                                      />
                                    )}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'checked') {
                              return (
                                <ASSlimTableCell
                                  key={`checked-cell-${idx + index}`}
                                  style={{
                                    color: theme.palette.primary.dark,
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                  align="center"
                                  onClick={() =>
                                    history.push(`/orders/${field.id}`)
                                  }
                                  size={item.size}
                                >
                                  {item.value && (
                                    <CheckCircleIcon color="inherit" />
                                  )}
                                </ASSlimTableCell>
                              )
                            }
                            if (item.type === 'checkedNote') {
                              return (
                                <ASSlimTableCell
                                  key={`checked-cell-note${idx + index}`}
                                  style={{
                                    color: theme.palette.primary.dark,
                                    width:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ],
                                    minWidth:
                                      ColWidths[
                                        item.colSize ? item.colSize : 'auto'
                                      ]
                                  }}
                                  align="center"
                                  onClick={() =>
                                    history.push(`/orders/${field.id}`)
                                  }
                                  size={item.size}
                                >
                                  {item.value && (
                                    <Tooltip
                                      title={
                                        <p
                                          style={{
                                            color: theme.palette.common.white,
                                            fontSize:
                                              theme.typography.body1.fontSize,
                                            margin: theme.spacing(1)
                                          }}
                                        >
                                          {item.value}
                                        </p>
                                      }
                                      arrow={true}
                                    >
                                      <CheckCircleIcon color="inherit" />
                                    </Tooltip>
                                  )}
                                </ASSlimTableCell>
                              )
                            }
                            return (
                              <Fragment key={`empty-cell-${idx + index}`} />
                            )
                          })}
                      </ASTableRow>
                    )
                  }
                  return <Fragment key={`cell-${index}`} />
                }
              ) as React.ReactNode)}
          </ASTableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

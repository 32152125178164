// https://medium.com/javascript-in-plain-english/extend-material-ui-theme-in-typescript-a462e207131f
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions
} from '@material-ui/core/styles'

export const createAsepticTheme = (options: ThemeOptions) => {
  return responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: {
          dark: '#1C6DB0',
          main: '#45a4d5',
          light: '#eef6f9',
          contrastText: '#fff'
        },
        secondary: {
          main: '#FB551F',
          light: '#747474',
          dark: '#363839'
        },
        text: {
          primary: '#747474',
          secondary: '#747474',
          hint: '#F5F5F5'
        },
        background: {
          default: '#ffffff'
        }
      },
      props: {
        // Name of the component ⚛️
        MuiAppBar: {
          position: 'sticky'
        },
        MuiButtonBase: {
          // The properties to apply
          disableRipple: true // No more ripple, on the whole application 💣!
        },
        MuiTypography: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span'
          }
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 800,
          md: 960,
          lg: 1400,
          xl: 1800
        }
      },

      ...options
    })
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { Grid, TablePagination, Box, Snackbar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Products } from './ProductsList'
import {
  IProduct,
  ITableHeader,
  IProductPayload,
  QueryFilter,
  Filter,
  IError
} from '../../../models'
import {
  postProductFilter,
  cancelPostProductFilter
} from '../../../services/ApiService'
import { TSortDirection, SortDirectionType } from '../../../types/SortTypes'
import { useDebounce } from '../../../components/utility/utility'
import {
  findProductStatusTypeNumber,
  findProductExpiryValuesByNumber
} from '../../../types'
import { InitialState, ColWidthsType } from '../../../constants'
import { capitalizeFirstLetter, updateSearch } from '../../../utility'
import { Alert } from '@material-ui/lab'

export const ProductHeaders: ITableHeader[] = [
  {
    name: 'headerProductName',
    displayName: 'Drug Name',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.LG
  },
  {
    name: 'headerProductCode',
    displayName: 'Code',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductVehicle',
    displayName: 'Vehicle (Diluent)',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductRoute',
    displayName: 'Route',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductContainer',
    displayName: 'Container',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductConcentrationDisplay',
    displayName: 'Concentration',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.LG
  },
  {
    name: 'headerProductExpirySpanValue',
    displayName: 'Expiry',
    sortDirection: SortDirectionType.None,
    type: 'dateUnits',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductStorageType',
    displayName: 'Storage',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductPackagingType',
    displayName: 'Packaging',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductNoteInternal',
    displayName: 'Internal',
    sortDirection: SortDirectionType.None,
    type: 'checked',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductNoteExternal',
    displayName: 'External',
    sortDirection: SortDirectionType.None,
    type: 'checked',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductRevisionNumber',
    displayName: 'Revision',
    sortDirection: SortDirectionType.None,
    type: 'number',
    size: 'small',
    searchValue: '',
    searchable: false,
    sortable: false,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductStatus',
    displayName: 'Status',
    sortDirection: SortDirectionType.None,
    type: 'status',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerProductLastUpdate',
    displayName: 'Last Update',
    sortDirection: SortDirectionType.None,
    type: 'date',
    size: 'small',
    searchValue: '',
    searchable: false,
    sortable: false,
    colWidth: ColWidthsType.LG
  },
  {
    name: 'headerUsername',
    displayName: 'Last Update By',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: false,
    sortable: false,
    colWidth: ColWidthsType.SM
  }
]

export const ProductsListContainer: FunctionComponent = () => {
  const [prodState, setProdState] = useState<IProductPayload>()
  const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(
    ProductHeaders
  )
  const [pageState, setPageState] = useState<QueryFilter>(InitialState)
  const [searchQuery, setSearchQuery] = useState<Filter | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const handleSearch = (searchQuery: Filter) => {
    setPageState(updateSearch(pageState, tableHeaders, searchQuery))
  }

  const debounceUpdateSearchQuery = useDebounce<Filter[]>(handleSearch, 200)
  const handleSearchRequest = (tableHeaders: ITableHeader[]) => {
    setTableHeaders(tableHeaders)
  }
  useEffect(() => searchQuery && debounceUpdateSearchQuery(searchQuery), [
    searchQuery
  ])

  const handleSortRequest = (columnName: string, sortDir: TSortDirection) => {
    setTableHeaders(
      tableHeaders.map((item) => {
        if (item.name === columnName) {
          item.sortDirection = sortDir
          const updatedPage = { ...pageState }
          if (updatedPage && updatedPage.sorting) {
            updatedPage.sorting.sortDirection = capitalizeFirstLetter(sortDir)
            updatedPage.sorting.sortBy = capitalizeFirstLetter(
              columnName.replace('header', '')
            )
          }
          setPageState(updatedPage)
        } else {
          item.sortDirection = SortDirectionType.None
        }
        return item
      })
    )
  }

  const updatePage = (value: QueryFilter) => {
    postProductFilter(value)
      .then((response) => {
        const productsPayload = response.data as IProductPayload

        const updatedProductItems: IProduct[] = productsPayload.products.reduce(
          (accProducts: IProduct[], product: IProduct) => {
            let productItem: IProduct = {
              headerProductName: product.productName && product.productName,
              headerProductCode: product.productCode && product.productCode,
              headerProductNoteExternal:
                product?.recentExternalNote === null ||
                product?.recentExternalNote?.text.trim().split('').length !== 0
                  ? true
                  : false,
              headerProductNoteInternal:
                product?.recentInternalNote === null ||
                product?.recentInternalNote?.text.trim().split('').length !== 0
                  ? true
                  : false,

              ...product
            }
            if (product.productRevisions.length > 0) {
              const productRevision =
                product.productRevisions[product.productRevisions.length - 1]

              const {
                vehicle,
                productRoute,
                container,
                concentrationDisplay,
                productStorageType,
                productPackingType,
                productRevisionStatusId,
                productRevisionNumber,
                productRevisionUpdateDateTime,
                productRevisionByUser,
                productExpirySpanValue
              } = productRevision
              productItem = {
                headerProductVehicle:
                  vehicle?.description !== null ? vehicle.otherName : '',
                headerProductRoute:
                  productRoute?.description !== null
                    ? productRoute?.description
                    : '',
                headerProductContainer:
                  container?.description !== null ? container?.description : '',
                headerProductConcentrationDisplay:
                  concentrationDisplay !== null ? concentrationDisplay : '',
                headerProductExpirySpanValue:
                  productExpirySpanValue !== null
                    ? `${productExpirySpanValue.toString()} ${
                        findProductExpiryValuesByNumber[
                          productRevision.productExpirySpanValueTypeId
                        ]
                      }`
                    : '',
                headerProductStorageType:
                  productStorageType?.description !== null
                    ? productStorageType?.description
                    : '',

                headerProductPackagingType:
                  productPackingType?.description !== null
                    ? productPackingType?.description
                    : '',
                headerProductRevisionNumber: productRevisionNumber,
                headerProductStatus:
                  findProductStatusTypeNumber[productRevisionStatusId],
                headerProductLastUpdate: productRevisionUpdateDateTime,
                headerUsername:
                  productRevisionByUser && productRevisionByUser.username,
                ...productItem
              }
            }
            return (accProducts = [...(accProducts || []), productItem])
          },
          []
        )
        productsPayload.products = [...updatedProductItems]
        setProdState({ ...productsPayload })
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setError({
          hasError: true,
          errorMessage: 'failed to get updated product list'
        })
      })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.currentPage = newPage
      setPageState(newPageState)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.count = parseInt(event.target.value, 10)
      setPageState(newPageState)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePage(pageState)
    }, 60000)
    return () => clearInterval(interval)
  }, [pageState])

  useEffect(() => {
    updatePage(pageState)
  }, [pageState])

  useEffect(() => {
    const searchValue = tableHeaders.find((item) => item.searchValue !== '')
    let filter = {
      filterBy: '',
      filterValue: ''
    }

    if (searchValue && searchValue.searchValue) {
      if (searchValue && searchValue.searchValue) {
        filter = {
          filterBy: searchValue.name.replace('header', ''),
          filterValue: searchValue.searchValue
        }
      }
    }

    filter && setSearchQuery(filter)
  }, [tableHeaders, setSearchQuery])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelPostProductFilter()
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Grid
          className="products-list-container"
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          style={{ overflow: 'auto' }}
        >
          <Grid item style={{ maxWidth: '100%' }}>
            {prodState && pageState && pageState.pagination && (
              <>
                <Products
                  tableHeaders={tableHeaders}
                  requestSort={(columnName, sortDirection) =>
                    handleSortRequest(columnName, sortDirection)
                  }
                  requestSearch={(tableHeaders) =>
                    handleSearchRequest(tableHeaders)
                  }
                  products={prodState.products}
                />
                {pageState.pagination.count && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={prodState.total}
                    rowsPerPage={pageState.pagination.count}
                    page={prodState.currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

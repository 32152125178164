import React, { FunctionComponent, useEffect, useState } from 'react'
import { UserDetail } from './UserDetail'
import { IOrganisation, IError, CreateUserModel } from '../../../models'
import {
  getOrganisations,
  postUser,
  cancelGetOrganisations,
  cancelPostUser
} from '../../../services/ApiService'
import { Snackbar, Box, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'

interface IOrganisationRouteParams {
  /** The id of this feature passed through via the route params */
  id: string
}

interface INewUserDetailContainerProps
  extends RouteComponentProps<IOrganisationRouteParams> {}

export const NewUserDetailContainer: FunctionComponent<INewUserDetailContainerProps> = ({
  match
}) => {
  const [user, setUser] = useState<any | undefined>(undefined)
  const [organisations, setOrganisations] = useState<
    IOrganisation[] | undefined
  >(undefined)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()
  const { id } = match.params

  useEffect(() => {
    getOrganisations()
      .then((response) => {
        setOrganisations(response.data)
        setIsLoading(false)
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to get organisations list for input dropdown'
        })
      })
  }, [])

  const handleSubmission = (user: CreateUserModel) => {
    postUser(user)
      .then((response) => {
        if (response.data) {
          history.goBack()
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.InviteSent, {
            emailaddress: user.email
          })
        } else {
          setError({
            hasError: true,
            errorMessage:
              'A user already exists with this email address, please provide a different value.'
          })
        }
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to post new user'
        })
      })
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelGetOrganisations()
      cancelPostUser()
    }
  }, [])

  return (
    <>
      {id === undefined && (
        <>
          {isLoading && (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <UserDetail
              user={user}
              isNewUser={true}
              isEditing={true}
              organisations={organisations}
              submitUser={handleSubmission}
            />
          )}
          {error.hasError && (
            <Snackbar
              open={error.hasError}
              autoHideDuration={4000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="error">
                {error.errorMessage}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  )
}

import React, { FunctionComponent, useState } from 'react'
import { OrganisationDetail } from './OrganisationDetail'
import { IOrganisation, IError } from '../../../models'
import { postOrganisation } from '../../../services/ApiService'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'

interface IOrganisationRouteParams {
  /** The id of this feature passed through via the route params */
  id: string
}

interface IOrganisationDetailContainerProps
  extends RouteComponentProps<IOrganisationRouteParams> {}

export const NewOrganisationDetailContainer: FunctionComponent<IOrganisationDetailContainerProps> = ({
  match
}) => {
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()
  const { id } = match.params

  const handleSubmission = (organisation: IOrganisation) => {
    postOrganisation(organisation)
      .then(() => {
        history.goBack()
      })
      .catch((error) => {
        console.log(error)
        setError({
          hasError: true,
          errorMessage: 'failed to post new customer'
        })
      })
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  // useEffect(() => {
  //   return () => {
  //     cancelPostOrganisation()
  //   }
  // }, [])

  return (
    <>
      {id === undefined && (
        <>
          <div>
            <OrganisationDetail
              isNewOrganisation={true}
              isEditing={true}
              submitOrganisation={handleSubmission}
            />
          </div>

          {error.hasError && (
            <Snackbar
              open={error.hasError}
              autoHideDuration={4000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="error">
                {error.errorMessage}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  )
}

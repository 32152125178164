export enum SortDirectionType {
  Ascending = 'asc',
  Descending = 'desc',
  None = 'none'
}

export type TSortDirection =
  | SortDirectionType.Ascending
  | SortDirectionType.Descending
  | SortDirectionType.None

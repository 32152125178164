import React, { FunctionComponent, useContext } from 'react'
import {
  Button,
  createStyles,
  Grid,
  Theme,
  Typography,
  Box
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCancelIcon from '@material-ui/icons/Cancel'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import { IOrder, IOrganisation } from '../../../../models'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import { ASStatus } from '../../../../components/ASStatus'
import { findOrderStatusTypeByNumber } from '../../../../types/StatusTypes'
import { ASProductsTable } from '../../../../components/ASProductsTable'
import Moment from 'react-moment'
import { setActiveOrder } from '../../../../features'
import { AppContext } from '../../../../context'

interface IOrderDetailProps {
  order: IOrder
  organisation?: IOrganisation
  isCancelable?: boolean
  cancelOrder: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

export const OrderDetail: FunctionComponent<IOrderDetailProps> = ({
  order,
  organisation,
  isCancelable,
  cancelOrder
}) => {
  const { dispatch } = useContext(AppContext)
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={theme.breakpoints.values.md}
      marginX="auto"
      mb={theme.spacing(1)}
    >
      <Grid container={true}>
        <Grid item={true}>
          <Button
            startIcon={<LeftIcon />}
            onClick={() => (
              setActiveOrder(dispatch, undefined), history.push('/orders')
            )}
          >
            Go Back
          </Button>
          <Box pt={2} mb={3}>
            <Box mb={3}>
              <Grid container={true} spacing={3} justify="space-between">
                <Grid item={true}>
                  <Typography variant="h3" color="primary">
                    Order {order.id}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <ASStatus
                    status={findOrderStatusTypeByNumber[order.orderStatus.id]}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              bgcolor={theme.palette.text.hint}
              borderRadius={3}
              p={2}
              mb={2}
            >
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6}>
                  <Typography variant="h5">
                    <strong>Your Reference</strong>
                  </Typography>
                  <p>{order.patientReference && order.patientReference}</p>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <Typography variant="h5">
                    <strong>Patient Name</strong>
                  </Typography>
                  <p>{order.patientName && order.patientName}</p>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <Typography variant="h5">
                    <strong>Hospital Number / NHS Number</strong>
                  </Typography>
                  <p>{order.hospitalNumber && order.hospitalNumber}</p>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <Typography variant="h5">
                    <strong>Call Before Compounding</strong>
                  </Typography>
                  {order.callBeforeCompounding && (
                    <p>
                      <CheckCircleIcon color="inherit" />
                    </p>
                  )}
                  {!order.callBeforeCompounding && (
                    <p>
                      <CheckCancelIcon color="inherit" />
                    </p>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box mb={2} mt={3}>
              <Typography variant="h5">Delivery</Typography>
            </Box>
            <Box
              bgcolor={theme.palette.text.hint}
              borderRadius={3}
              p={2}
              mb={2}
            >
              <Grid style={{ flexGrow: 1 }} container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6}>
                  <Grid style={{ flexGrow: 1 }} container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={6}>
                      <Typography variant="h5">
                        <strong>Treatment Start</strong>
                      </Typography>
                      <p>
                        {order.patientTreatmentStartDate && (
                          <Moment
                            local={true}
                            format="DD-MM-YYYY HH:mm "
                            date={order.patientTreatmentStartDate}
                          />
                        )}
                      </p>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                      <Typography variant="h5">
                        <strong>Date Required</strong>
                      </Typography>
                      <p>
                        {order.requiredDate && (
                          <Moment
                            local={true}
                            format="DD-MM-YYYY"
                            date={order.requiredDate}
                          />
                        )}
                      </p>
                    </Grid>
                  </Grid>
                  {order?.recentExternalNote?.text && (
                    <Grid item={true} xs={12}>
                      <Typography variant="h5">
                        <strong>Notes</strong>
                      </Typography>

                      <p>{order.recentExternalNote.text}</p>
                    </Grid>
                  )}
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <Typography variant="h5">
                    <strong>Delivery address</strong>
                  </Typography>
                  {organisation && (
                    <>
                      <p>
                        <strong>{organisation.name}</strong>
                      </p>
                      <p>{organisation.contact[0].address.fullAddress}</p>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box mb={2} mt={3}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6} style={{ display: 'flex' }}>
                  <Box display="flex" alignContent="flex-end">
                    <Typography variant="h5">Products</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <ASProductsTable products={order.orderLines} />
          </Box>
        </Grid>
      </Grid>
      <Grid
        style={{ flexGrow: 1, justifyContent: 'flex-end' }}
        container={true}
        spacing={3}
      >
        <div className={classes.buttonContainer}>
          <Button
            component={Link}
            to="/orders"
            color="default"
            variant="outlined"
          >
            GO BACK
          </Button>
          {isCancelable && (
            <Button
              onClick={() => cancelOrder()}
              disableElevation={true}
              variant="outlined"
            >
              Cancel Order
            </Button>
          )}
          {order.orderStatus.id === 1 && (
            <Button
              variant="contained"
              disableElevation={true}
              color="secondary"
              component={Link}
              to={{
                pathname: `/orders/${order.id}`,
                state: {
                  isEditing: true
                }
              }}
            >
              Continue
            </Button>
          )}

          {order.orderStatus.id !== 1 && (
            <Button
              disableElevation={true}
              component={Link}
              variant="contained"
              color="primary"
              to={{
                pathname: `/orders/${order.id}`,
                state: {
                  isEditing: true,
                  isRepeat: true
                }
              }}
            >
              Repeat
            </Button>
          )}
        </div>
      </Grid>
    </Box>
  )
}

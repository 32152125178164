import React, { FunctionComponent } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { Box } from '@material-ui/core'

interface IASStatusButtonGroupProps {
  disabled: boolean
  initialValue: string
  handleSelectedOption: (item: string) => void
}

export const ASExpiryUnitDropdown: FunctionComponent<IASStatusButtonGroupProps> = ({
  disabled,
  initialValue,
  handleSelectedOption
}) => {
  const options = ['Days', 'Hours']
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedItem, setSelectedItem] = React.useState<string>(
    initialValue || options[0]
  )

  const handleMenuItemClick = (value: string) => {
    if (disabled !== true) {
      setSelectedItem(value)
      setOpen(false)
      handleSelectedOption(value)
    }
  }

  const handleToggle = () => {
    if (disabled !== true) {
      setOpen((prevOpen) => !prevOpen)
    }
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Box zIndex={1}>
      <p ref={anchorRef} onClick={handleToggle}>
        {selectedItem}
      </p>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((optionValue: string, index: number) => (
                    <MenuItem
                      key={`menu-item-${index}`}
                      selected={optionValue === selectedItem}
                      onClick={() => handleMenuItemClick(optionValue)}
                      value={optionValue.valueOf()}
                    >
                      {optionValue}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

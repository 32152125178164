import React, { FunctionComponent } from 'react'

import {
  MuiThemeProvider,
  CssBaseline,
  ThemeOptions,
  responsiveFontSizes,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'
import { ASMainMenu } from '../ASMainMenu'
import { ASFooter } from '../ASFooter'
import { createAsepticTheme } from '../../aseptic.theme'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
  }
  body {
    overflow-x: hidden;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  @media print{
    @page {
      size: A4 landscape;
    }
    body  {
      height: auto;
      width: 100%;
    }
  }
`

const themeOpts: ThemeOptions = {}
let asepticTheme = createAsepticTheme(themeOpts)
asepticTheme = responsiveFontSizes(asepticTheme)

export const ASMinSpecWarning: FunctionComponent = ({}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: `${theme.spacing(5)}px auto`,
      border: `1px solid ${theme.palette.error.dark}`,
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.error.main
    }
  }))
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={asepticTheme}>
      <CssBaseline>
        <GlobalStyle />
        <ASMainMenu isAdmin={false}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={5}>
              <Box className={classes.root}>
                Sorry, this application does not support this browser. <br />
                Please use Chrome or MS Edge. <br />
                Alternatively contact us on 0800 043 0096
              </Box>
            </Grid>
          </Grid>
        </ASMainMenu>
        <ASFooter />
      </CssBaseline>
    </MuiThemeProvider>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { OrganisationDetail } from './OrganisationDetail'
import { IOrganisation, IError } from '../../../models'
import {
  getOrganisationsById,
  putOrganisation,
  cancelGetOrganisationsById,
  cancelPutOrganisation
} from '../../../services/ApiService'
import { Snackbar, Box, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'

interface IOrganisationRouteParams {
  /** The id of this feature passed through via the route params */
  id: string
}

interface IOrganisationDetailContainerProps
  extends RouteComponentProps<IOrganisationRouteParams> {}

export const OrganisationDetailContainer: FunctionComponent<IOrganisationDetailContainerProps> = ({
  match
}) => {
  const [organisation, setOrganisation] = useState<IOrganisation | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()
  const { id } = match.params

  useEffect(() => {
    const { id } = match.params
    if (id && id !== 'new') {
      getOrganisationsById(parseInt(id))
        .then((response) => {
          setOrganisation(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to get order by id'
          })
        })
    }
  }, [match.params])

  const handleEditingToggle = () => {
    setIsEditing(!isEditing)
  }

  const handleSubmission = (organisation: IOrganisation) => {
    putOrganisation(organisation)
      .then(() => {
        history.goBack()
      })
      .catch((error) => {
        console.log(error)
        setError({
          hasError: true,
          errorMessage: 'failed to update existing organisation'
        })
      })
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelGetOrganisationsById()
      cancelPutOrganisation()
    }
  }, [])

  return (
    <>
      {id && id !== 'new' && (
        <>
          {isLoading && (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && !error.hasError && (
            <div>
              <OrganisationDetail
                organisation={organisation}
                isNewOrganisation={false}
                isEditing={isEditing}
                toggleEditing={handleEditingToggle}
                submitOrganisation={handleSubmission}
              />
            </div>
          )}
          {error.hasError && (
            <Snackbar
              open={error.hasError}
              autoHideDuration={4000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="error">
                {error.errorMessage}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  postUserFilter,
  cancelPostUserFilter
} from '../../../services/ApiService'
import { UsersList } from './UsersList'
import {
  ITableHeader,
  IError,
  IUserListPayload,
  IUserList
} from '../../../models'
import { QueryFilter, Filter } from '../../../models/swaggerTypesModified'
import { useDebounce } from '../../../components/utility/utility'
import { TSortDirection, SortDirectionType } from '../../../types/SortTypes'

import {
  Grid,
  TablePagination,
  Box,
  CircularProgress,
  Snackbar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { InitialState, ColWidthsType } from '../../../constants'
import { updateSearch, capitalizeFirstLetter } from '../../../utility'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'

export const UserHeaders: ITableHeader[] = [
  {
    name: 'organisationId',
    displayName: 'Org Code',
    sortDirection: SortDirectionType.None,
    type: 'number',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.XS
  },
  {
    name: 'id',
    displayName: 'User Code',
    sortDirection: SortDirectionType.None,
    type: 'number',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.XS
  },
  {
    name: 'headerOrganisationName',
    displayName: 'Customer Name',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: false,
    sortable: false,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'isActive',
    displayName: 'Active',
    sortDirection: SortDirectionType.None,
    type: 'checked',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.XS
  },
  {
    name: 'email',
    displayName: 'Email (Username)',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'medium',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'role',
    displayName: 'Role',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.XS
  },
  {
    name: 'jobDescription',
    displayName: 'Job Description',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'lastLoginDate',
    displayName: 'Last Login',
    sortDirection: SortDirectionType.None,
    type: 'date',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.SM
  }
]

export const UsersListContainer: FunctionComponent = () => {
  const [usersState, setUsersState] = useState<IUserListPayload>()
  const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(UserHeaders)
  const [pageState, setPageState] = useState<QueryFilter>(InitialState)
  const [searchQuery, setSearchQuery] = useState<Filter | undefined>()
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleSearch = (searchQuery: Filter) => {
    setPageState(updateSearch(pageState, tableHeaders, searchQuery))
  }

  const debounceUpdateSearchQuery = useDebounce<Filter[]>(handleSearch, 200)

  const handleSearchRequest = (tableHeaders: ITableHeader[]) => {
    setTableHeaders(tableHeaders)
  }
  useEffect(() => searchQuery && debounceUpdateSearchQuery(searchQuery), [
    searchQuery
  ])

  const handleSortRequest = (columnName: string, sortDir: TSortDirection) => {
    setTableHeaders(
      tableHeaders.map((item) => {
        if (item.name === columnName) {
          item.sortDirection = sortDir
          const updatedPage = { ...pageState }
          if (updatedPage && updatedPage.sorting) {
            updatedPage.sorting.sortDirection = capitalizeFirstLetter(sortDir)
            updatedPage.sorting.sortBy = capitalizeFirstLetter(columnName)
          }
          setPageState(updatedPage)
        } else {
          item.sortDirection = SortDirectionType.None
        }
        return item
      })
    )
  }

  const updatePage = (value: QueryFilter) => {
    postUserFilter(value)
      .then((response) => {
        const typedUser = response.data as IUserListPayload
        const updatedUserItems: IUserList[] =
          typedUser &&
          typedUser.users.reduce((accUsers: IUserList[], user: IUserList) => {
            let userItem: IUserList = {
              headerOrganisationName: `${user.organisationName}`,
              ...user
            }
            return (accUsers = [...(accUsers || []), userItem])
          }, [])
        typedUser.users = updatedUserItems

        typedUser && setUsersState(typedUser)
        setIsLoading(false)
      })
      .catch((error) => {
        setError({
          hasError: true,
          errorMessage: 'failed to post user filter'
        })
        analyticsServiceSingleton.trackError(error)
      })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.currentPage = newPage
      setPageState(newPageState)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.count = parseInt(event.target.value, 10)
      setPageState(newPageState)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePage(pageState)
    }, 60000)
    return () => clearInterval(interval)
  }, [pageState])

  useEffect(() => {
    updatePage(pageState)
  }, [pageState])

  useEffect(() => {
    const searchValue = tableHeaders.find((item) => item.searchValue !== '')
    let filter = {
      filterBy: '',
      filterValue: ''
    }
    if (searchValue && searchValue.searchValue) {
      filter = {
        filterBy: searchValue.name,
        filterValue: searchValue.searchValue
      }
    }

    filter && setSearchQuery(filter)
  }, [tableHeaders, setSearchQuery])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelPostUserFilter()
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Grid
          className="users-list-container"
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          style={{ overflow: 'auto' }}
        >
          <Grid item style={{ maxWidth: '100%' }}>
            {usersState && pageState && pageState.pagination && (
              <>
                <UsersList
                  tableHeaders={tableHeaders}
                  requestSort={(columnName, sortDirection) =>
                    handleSortRequest(columnName, sortDirection)
                  }
                  requestSearch={(tableHeaders) =>
                    handleSearchRequest(tableHeaders)
                  }
                  users={usersState?.users}
                />

                {pageState.pagination.count && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={usersState.total}
                    rowsPerPage={pageState.pagination.count}
                    page={usersState.currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

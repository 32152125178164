import { withStyles, TextField } from '@material-ui/core'

export const ASSearchTextField = withStyles((theme) => ({
  root: {
    transition: 'all 0.3s',
    width: '100%',

    '&.Mui-focused fieldset': {
      backgroundColor: theme.palette.common.white
    }
  }
}))(TextField)

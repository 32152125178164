import { FunctionComponent, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../context'
import {
  createGetUserAuthEvent,
  createLogInAuthEvent
} from '../../events/AuthEvent'

interface ILogInProps {
  redirectUrl?: string
}

export const LogIn: FunctionComponent<ILogInProps> = ({ redirectUrl }) => {
  const { dispatch, isAuthenticated, user } = useContext(AppContext)
  const location = useLocation()

  // Try and get a user first (isAuthenticated)
  useEffect(() => dispatch(createGetUserAuthEvent()), [dispatch])

  // Watch whether isAuthenticated or not and log in if so
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(
        createLogInAuthEvent(
          redirectUrl && location.pathname !== '/login' ? redirectUrl : '/'
        )
      )
    }
  }, [dispatch, isAuthenticated, user, redirectUrl, location.pathname])

  return null
}

import { createAsepticTheme } from '../aseptic.theme'
import { ThemeOptions } from '@material-ui/core'
const themeOpts: ThemeOptions = {}
let theme = createAsepticTheme(themeOpts)

export const ColWidths = Object.freeze({
  auto: 'auto',
  xs: theme.spacing(10),
  sm: theme.spacing(15),
  md: theme.spacing(20),
  lg: theme.spacing(25),
  xl: theme.spacing(30)
})

export enum ColWidthsType {
  AUTO = 'auto',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export type TColWidth =
  | ColWidthsType.AUTO
  | ColWidthsType.XS
  | ColWidthsType.SM
  | ColWidthsType.MD
  | ColWidthsType.LG
  | ColWidthsType.XL

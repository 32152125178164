import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from 'react'
import {
  postProductFilter,
  cancelPostProductFilter
} from '../../services/ApiService'
import {
  ITableHeader,
  IProduct,
  IProductPayload,
  QueryFilter,
  Filter,
  IError,
  OrderLineModel,
  IOrder
} from '../../models'
import { useDebounce } from '../utility/utility'
import { TSortDirection, SortDirectionType } from '../../types/SortTypes'
import { ASProductsSelectTable } from './ASProductsSelectTable'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {
  TablePagination,
  Box,
  InputAdornment,
  Input,
  withStyles,
  Grid,
  Button,
  IconButton,
  CircularProgress,
  Snackbar,
  Typography
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {
  updateSearch,
  capitalizeFirstLetter,
  expiryValueFormatted
} from '../../utility'
import { setActiveOrder } from '../../features'
import { AppContext } from '../../context'
import { Alert } from '@material-ui/lab'
import { ProductStatusType } from '../../types'

export const ProductHeaders: ITableHeader[] = [
  {
    name: 'productName',
    displayName: 'Product',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'medium',
    searchValue: '',
    searchable: true
  },
  {
    name: 'headerProductRoute',
    displayName: 'Route',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true
  },
  {
    name: 'headerVehical',
    displayName: 'Diluent',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'medium',
    searchValue: '',
    searchable: true
  },
  {
    name: 'headerExpiryDate',
    displayName: 'Expiry Date',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'medium',
    searchValue: '',
    searchable: true
  },
  {
    name: 'headerProductContainer',
    displayName: 'Container',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true
  }
]

const ASProductSearchInput = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: theme.typography.h4.fontSize
  }
}))(Input)

const InitialState: QueryFilter = {
  sorting: {
    sortBy: 'Id',
    sortDirection: capitalizeFirstLetter(SortDirectionType.Ascending)
  },
  pagination: {
    currentPage: 0,
    pageCount: 0,
    count: 10
  },
  filters: [
    {
      filterBy: 'ProductStatuses',
      filterValue: 'ACTIVE'
    },
    {
      filterBy: 'ProductStatuses',
      filterValue: 'INACTIVE'
    }
  ]
}

interface IASProductsSelectTableContainerProps {
  cancelRequest: () => void
}

export const ASProductsSelectTableContainer: FunctionComponent<IASProductsSelectTableContainerProps> = ({
  cancelRequest
}) => {
  const { activeOrder, dispatch } = useContext(AppContext)
  const [prodState, setProdState] = useState<IProductPayload>()
  const [order, setOrder] = useState<IOrder | undefined>(activeOrder)
  const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(
    ProductHeaders
  )
  const [pageState, setPageState] = useState<QueryFilter>(InitialState)
  const [searchQuery, setSearchQuery] = useState<Filter | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const [selectedProducts, setSelectedProducts] = useState<
    IProduct[] | undefined
  >()
  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearch = (query: Filter) => {
    setPageState(
      updateSearch(pageState, tableHeaders, query, InitialState.filters)
    )
  }
  const debounceUpdateSearchQuery = useDebounce<Filter[]>(handleSearch, 200)
  const handleSearchRequest = (value: string) => {
    const updatedHeaders = tableHeaders.map((tableHeader) => {
      if (tableHeader.name === 'productName') {
        tableHeader.searchValue = value
      }
      return tableHeader
    })
    setSearchValue(value)
    setTableHeaders(updatedHeaders)
  }

  useEffect(() => searchQuery && debounceUpdateSearchQuery(searchQuery), [
    searchQuery
  ])

  const handleSortRequest = (columnName: string, sortDir: TSortDirection) => {
    setTableHeaders(
      tableHeaders.map((item) => {
        if (item.name === columnName) {
          item.sortDirection = sortDir
          const updatedPage = { ...pageState }
          if (updatedPage && updatedPage.sorting) {
            updatedPage.sorting.sortDirection = capitalizeFirstLetter(sortDir)
            updatedPage.sorting.sortBy = capitalizeFirstLetter(columnName)
          }
          setPageState(updatedPage)
        } else {
          item.sortDirection = SortDirectionType.None
        }
        return item
      })
    )
  }

  const updatePage = (value: QueryFilter) => {
    postProductFilter(value)
      .then((response) => {
        const productsPayload = response.data as IProductPayload
        const updatedProductItems: IProduct[] = productsPayload.products
          .filter(
            (item) =>
              Array.isArray(item.productRevisions) &&
              item.productRevisions.length > 0
          )
          .reduce((accProducts: IProduct[], product: IProduct) => {
            let productItem: IProduct = {
              headerProductName: product.productName && product.productName,
              ...product
            }

            if (product.productRevisions.length > 0) {
              const productRevision =
                product.productRevisions[product.productRevisions.length - 1]

              const isProductDisabled =
                productRevision.status.description.toUpperCase() ===
                  ProductStatusType.Retired.toUpperCase() ||
                productRevision.status.description.toUpperCase() ===
                  ProductStatusType.Inactive.toUpperCase()

              productItem = {
                headerVehicle:
                  productRevision && productRevision.vehicle.description,
                headerProductRoute: productRevision.productRoute.description,
                headerProductContainer: productRevision.container.description,
                headerVehical: productRevision.vehicle.otherName,
                headerExpiryDate: expiryValueFormatted(
                  productRevision.productExpirySpanValue,
                  productRevision.productExpirySpanValueType.id
                ),
                isDisabled: isProductDisabled,
                ...productItem
              }
            }
            return [...(accProducts || []), productItem]
          }, [])
        productsPayload.products = [...updatedProductItems]
        setProdState({ ...productsPayload })
        setIsLoading(false)
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to get updated product list'
        })
      })
  }

  useEffect(() => {
    const searchVal = tableHeaders.find((item) => item.searchValue !== '')
    let filter = {
      filterBy: '',
      filterValue: ''
    }
    if (searchVal && searchVal.searchValue) {
      filter = {
        filterBy: searchVal.name,
        filterValue: searchVal.searchValue
      }
    }

    filter && setSearchQuery(filter)
  }, [tableHeaders, setSearchQuery])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.currentPage = newPage
      setPageState(newPageState)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.count = parseInt(event.target.value, 10)
      setPageState(newPageState)
    }
  }

  useEffect(() => {
    const updatedOrder = { ...activeOrder } as IOrder
    if (selectedProducts && updatedOrder) {
      const orderLineItems = selectedProducts.map((product: IProduct) => {
        if (product.productRevisions) {
          const recentProductRevision =
            product.productRevisions[product.productRevisions.length - 1]
          const orderLine: OrderLineModel = {
            id: product.id,
            productName: product.productName,
            productRevisionId: product.productRevisions[0].id,
            orderId: updatedOrder.id,
            quantity: 0,
            dose: '',
            volume: '',
            productRevision: recentProductRevision
          }
          return orderLine
        }
      }) as OrderLineModel[]

      updatedOrder.orderLines = [
        ...(updatedOrder.orderLines || []),
        ...orderLineItems
      ]

      setOrder({ ...updatedOrder })
    }
  }, [selectedProducts, activeOrder])

  const handleProductSubmission = () => {
    order && setActiveOrder(dispatch, { ...order })
    cancelRequest()
  }

  useEffect(() => {
    updatePage(pageState)
  }, [pageState])

  useEffect(() => {
    return () => {
      cancelPostProductFilter()
    }
  }, [])

  const totalProductsCounter =
    ((selectedProducts && selectedProducts.length) || 0) +
    ((activeOrder && activeOrder?.orderLines.length) || 0)

  return (
    <>
      {isLoading && (
        <Box
          height="300px"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {prodState && pageState && pageState.pagination && (
        <>
          <Box mb={3}>
            <ASProductSearchInput
              placeholder="Search"
              color={'primary'}
              fullWidth={true}
              value={searchValue}
              onChange={(event) => handleSearchRequest(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {!searchValue && <SearchIcon />}
                  {searchValue && (
                    <IconButton size="small" onClick={() => setSearchValue('')}>
                      <HighlightOffIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </Box>
          <ASProductsSelectTable
            selectedProducts={totalProductsCounter}
            tableHeaders={tableHeaders}
            requestSort={(columnName, sortDirection) =>
              handleSortRequest(columnName, sortDirection)
            }
            products={prodState.products}
            handleSelectedProducts={(products) => setSelectedProducts(products)}
          />
          {pageState.pagination.count && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={prodState.total}
              rowsPerPage={pageState.pagination.count}
              page={prodState.currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
          <Grid
            style={{ flexGrow: 1, justifyContent: 'space-between' }}
            container={true}
            spacing={3}
            justify="flex-start"
            alignItems="center"
          >
            <Grid item={true}>
              <Box mt={2}>
                {totalProductsCounter > 0 && (
                  <Typography color="primary">{`${totalProductsCounter} product${
                    totalProductsCounter === 1 ? '' : 's'
                  } selected`}</Typography>
                )}
              </Box>
            </Grid>

            <Grid item={true}>
              <Box display="flex" mt={2}>
                <Button
                  onClick={() => cancelRequest()}
                  disableElevation={true}
                  variant="outlined"
                >
                  Cancel
                </Button>

                <Box ml={2}>
                  <Button
                    disabled={
                      !selectedProducts || selectedProducts.length === 0
                    }
                    type="submit"
                    onClick={() => handleProductSubmission()}
                    disableElevation={true}
                    variant="contained"
                    color={
                      selectedProducts && selectedProducts.length !== 0
                        ? 'primary'
                        : 'default'
                    }
                  >
                    Add to order
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

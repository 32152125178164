import React, {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom'
import authServiceInstance from '../../services/AuthService'
import { Box, CircularProgress } from '@material-ui/core'

interface IPrivateRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>
}

type RenderComponent = (props: RouteComponentProps<any>) => ReactNode

enum AuthState {
  LOADING,
  AUTHORISED,
  UNAUTHORISED
}

export const ASPrivateRoute: FunctionComponent<IPrivateRouteProps> = (
  props: IPrivateRouteProps
) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING)
  const { component: Component, ...rest }: IPrivateRouteProps = props
  const renderComponent: RenderComponent = (props) => <Component {...props} />

  useEffect(() => {
    authServiceInstance.getUser().then((user) => {
      setAuthState(
        user && user.access_token
          ? AuthState.AUTHORISED
          : AuthState.UNAUTHORISED
      )
    })
  }, [props.path])

  return (
    <>
      {authState === AuthState.LOADING && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {authState === AuthState.AUTHORISED && (
        <Route {...rest} render={renderComponent} />
      )}
      {authState === AuthState.UNAUTHORISED && <Redirect to="/login" />}
    </>
  )
}

import React, { FunctionComponent, useState } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  useTheme,
  Snackbar
} from '@material-ui/core'
import {
  ASTableHeaderCell,
  ASTableBody,
  ASTableRow,
  ASSlimTableCell,
  ASTableSortLabel
} from '../ASTable'
import { ITableHeader, ITableCell, IProduct } from '../../models'
import { TSortDirection, SortDirectionType } from '../../types'
import { toggleSortDirection } from '../../features/sort.models'
import { ASCheckbox } from '../ASForm'
import { Alert } from '@material-ui/lab'

interface IASProductsSelectTableProps {
  /** Requires an array of Products */
  products: IProduct[]
  /** Table header values are provided by its parent including sort direction state  */
  tableHeaders: ITableHeader[]
  /** Provides the parent the name of the column that is being requested to sort */
  requestSort: (columnName: string, sortDirection: TSortDirection) => void
  /** Provides the parent the products selected */
  selectedProducts: number
  /** Enabled the parent to update stored selected products */
  handleSelectedProducts: (products: IProduct[] | undefined) => void
}

export const ASProductsSelectTable: FunctionComponent<IASProductsSelectTableProps> = ({
  products,
  tableHeaders,
  requestSort,
  selectedProducts,
  handleSelectedProducts
}) => {
  const theme = useTheme()
  const [selected, setSelected] = React.useState<IProduct[] | undefined>()
  const [printNotificationOpen, setPrintNotificationOpen] = useState<boolean>(
    false
  )

  const handleClick = (event: React.ChangeEvent, product: IProduct) => {
    event.preventDefault()
    let newSelected: IProduct[] = []

    const selectedIndex: IProduct | undefined = selected?.find(
      (item: IProduct) => item.id === product.id
    )
    if (!selected) {
      newSelected = [product]
    } else {
      if (
        selectedIndex === undefined &&
        ((selectedProducts && selectedProducts) || 0) <= 4
      ) {
        newSelected = [...selected, product]
      } else if (selectedIndex) {
        newSelected = [
          ...selected.filter((item) => item.id !== selectedIndex.id)
        ]
      } else {
        setPrintNotificationOpen(true)
        newSelected = [...selected]
      }
    }
    setSelected(newSelected)
    handleSelectedProducts(newSelected)
  }

  const handleNotificationClose = () => {
    setPrintNotificationOpen(false)
  }

  return (
    <>
      <Snackbar
        open={printNotificationOpen}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity="warning">
          Up to 5 products can only be applied to an order
        </Alert>
      </Snackbar>
      <TableContainer>
        <Table
          style={{
            tableLayout: 'auto',
            margin: '0 auto'
          }}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <ASTableHeaderCell style={{ width: theme.spacing(6) }} />
              {tableHeaders.map((header: ITableHeader, index: number) => {
                return (
                  <ASTableHeaderCell key={`head-cell-${index}`}>
                    <ASTableSortLabel
                      active={header.sortDirection !== SortDirectionType.None}
                      direction={
                        header.sortDirection === SortDirectionType.Ascending
                          ? SortDirectionType.Ascending
                          : SortDirectionType.Descending
                      }
                      onClick={() =>
                        requestSort(
                          header.name,
                          header.sortDirection &&
                            toggleSortDirection(header.sortDirection)
                        )
                      }
                    >
                      {header.displayName}
                    </ASTableSortLabel>
                  </ASTableHeaderCell>
                )
              })}
            </TableRow>
          </TableHead>

          <ASTableBody>
            {products &&
              products.map(
                (field: IProduct, index: number): React.ReactNode => {
                  const isItemSelected =
                    selected?.find(
                      (product: IProduct) => product.id === field.id
                    ) !== undefined
                  const labelId = `enhanced-table-checkbox-${index}`

                  const rowValues: ITableCell[] = tableHeaders.map((item) => {
                    let cellValue: ITableCell = {
                      value: null,
                      type: 'text',
                      size: 'small'
                    }

                    for (const [key, value] of Object.entries(field)) {
                      if (item.name === key) {
                        cellValue.value = value
                        cellValue.size = item.size
                        cellValue.type = item.type
                      }
                    }
                    return cellValue
                  })

                  return (
                    <ASTableRow
                      key={`row-${index}`}
                      className={`${isItemSelected ? 'active' : ''} ${
                        field.isDisabled ? `disabled` : ``
                      }`}
                    >
                      <ASSlimTableCell
                        style={{ width: theme.spacing(6) }}
                        color={theme.palette.primary.dark}
                        align="center"
                        padding="checkbox"
                      >
                        <ASCheckbox
                          color="primary"
                          onChange={(event) =>
                            !field.isDisabled && handleClick(event, field)
                          }
                          checked={isItemSelected ? isItemSelected : false}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ASSlimTableCell>

                      {rowValues.map((item: ITableCell, idx) => {
                        if (item.type === 'text') {
                          return (
                            <ASSlimTableCell
                              key={`text-cell-${idx}`}
                              size={item.size}
                            >
                              {item.value}
                            </ASSlimTableCell>
                          )
                        }

                        return <></>
                      })}
                    </ASTableRow>
                  )
                }
              )}
          </ASTableBody>
        </Table>
      </TableContainer>
    </>
  )
}

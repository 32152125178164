import { QueryFilter, ITableHeader, Filter } from '../models'

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const firstCharLowerCase = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}

export const updateSearch = (
  pageState: QueryFilter,
  tableHeaders: ITableHeader[],
  searchQuery: Filter,
  fixedFilters?: Filter[]
) => {
  const newpageState = { ...pageState }
  newpageState.pagination.currentPage = 0
  const newtableHeaders = [...tableHeaders]
  newtableHeaders.map((column) => {
    if (
      column.name === searchQuery.filterBy &&
      column.searchValue === searchQuery.filterValue
    ) {
      column.searchValue = searchQuery.filterValue
    }
    return column
  })
  const newSearchArray = newtableHeaders
    .map((item) => {
      return {
        filterBy: capitalizeFirstLetter(item.name.replace('header', '')),
        filterValue: item.searchValue
      } as Filter
    })
    .filter((item) => item.filterValue !== '')

  newpageState.filters = [...newSearchArray, ...(fixedFilters || [])]

  return newpageState
}

export const expiryValueFormatted = (value: number, unit: number) => {
  let formattedValue = `${value} ${unit === 1 ? 'Day' : 'Hour'}`
  if (value === 0 || value > 1) {
    formattedValue = `${formattedValue}s`
  }
  return formattedValue
}

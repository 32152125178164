import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { OrderStatusModel } from '../../models/swaggerTypes'
import { TOrderStatus, findStatusType, findStatusOrderType } from '../../types'
import { CircularProgress } from '@material-ui/core'

interface IASStatusButtonGroupProps {
  selectedStatus: TOrderStatus
  isRequestingStatus: boolean | undefined
  statusOptions: OrderStatusModel[]
  handleSelectedOption: (item: TOrderStatus) => void
}

export const ASStatusButtonGroup: FunctionComponent<IASStatusButtonGroupProps> = ({
  statusOptions,
  isRequestingStatus,
  handleSelectedOption
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState<TOrderStatus>()

  const handleMenuItemClick = (value: string) => {
    const selectedStatus = findStatusOrderType[value]
    setSelectedIndex(selectedStatus)
    setOpen(false)
    handleSelectedOption(selectedStatus)
  }

  const handleToggle = () => {
    !isRequestingStatus && setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        disableElevation={true}
        color="default"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleToggle}>Change status</Button>
        <Button
          color="default"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select status option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {!isRequestingStatus && <ArrowDropDownIcon />}
          {isRequestingStatus && <CircularProgress size="20px" />}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {statusOptions
                    .map((item) => item.description)
                    .map((optionValue: string, index: number) => (
                      <MenuItem
                        key={`menu-item-${index}`}
                        selected={optionValue === selectedIndex}
                        onClick={() =>
                          handleMenuItemClick(findStatusType[optionValue])
                        }
                        value={optionValue.valueOf()}
                      >
                        {optionValue.valueOf()}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

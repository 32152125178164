import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  postOrderFilter,
  cancelPostOrderFilter
} from '../../../services/ApiService'
import {
  ITableHeader,
  IOrderList,
  QueryFilter,
  Filter,
  IError,
  IOrderListPayload
} from '../../../models'
import { useDebounce } from '../../../components/utility/utility'
import { TSortDirection, SortDirectionType } from '../../../types/SortTypes'
import {
  Grid,
  TablePagination,
  Box,
  Snackbar,
  Typography
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ColWidthsType, InitialState } from '../../../constants'
import { updateSearch, capitalizeFirstLetter } from '../../../utility'
import { Alert } from '@material-ui/lab'
import { ASOrdersTable } from '../../../components/ASOrdersTable'

export const OrderHeaders: ITableHeader[] = [
  {
    name: 'submitDate',
    displayName: 'Date Submitted',
    sortDirection: SortDirectionType.None,
    type: 'digitalDateTime',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'id',
    displayName: 'Our Ref',
    sortDirection: SortDirectionType.Ascending,
    type: 'number',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'headerAddress',
    displayName: 'Delivery Address',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.XL
  },
  {
    name: 'patientReference',
    displayName: 'Customer Order',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'patientName',
    displayName: 'Patient Name',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'requiredDate',
    displayName: 'Date Required ',
    sortDirection: SortDirectionType.None,
    type: 'date',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'patientTreatmentStartDate',
    displayName: 'Treatment Date and Time',
    sortDirection: SortDirectionType.None,
    type: 'dateTime',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'headerOrderStatus',
    displayName: 'Order Status',
    sortDirection: SortDirectionType.None,
    type: 'status',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'callBeforeCompounding',
    displayName: 'Call Before Compounding',
    sortDirection: SortDirectionType.None,
    type: 'checked',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.XS
  },
  {
    name: 'headerRecentInternalNote',
    displayName: 'Internal Note ',
    sortDirection: SortDirectionType.None,
    type: 'checkedNote',
    size: 'small',
    searchValue: '',
    sortable: false,
    searchable: false,
    colWidth: ColWidthsType.SM
  }
]

export const AdminOrdersContainer: FunctionComponent = () => {
  const [orderState, setOrderState] = useState<IOrderListPayload>()
  const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(OrderHeaders)
  const [pageState, setPageState] = useState<QueryFilter>(InitialState)
  const [selectedOrders, setSelectedOrders] = useState<number[]>([])
  const [searchQuery, setSearchQuery] = useState<Filter | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<IError>({
    hasError: false
  })

  const handleSearch = (searchQuery: Filter) => {
    setPageState(updateSearch(pageState, tableHeaders, searchQuery))
  }

  const debounceUpdateSearchQuery = useDebounce<Filter[]>(handleSearch, 200)
  const handleSearchRequest = (tableHeaders: ITableHeader[]) => {
    setTableHeaders(tableHeaders)
  }

  useEffect(() => searchQuery && debounceUpdateSearchQuery(searchQuery), [
    searchQuery
  ])

  const handleSortRequest = (columnName: string, sortDir: TSortDirection) => {
    setTableHeaders(
      tableHeaders.map((item) => {
        if (item.name === columnName) {
          item.sortDirection = sortDir
          const updatedPage = { ...pageState }
          if (updatedPage && updatedPage.sorting) {
            updatedPage.sorting.sortDirection = capitalizeFirstLetter(sortDir)
            updatedPage.sorting.sortBy = capitalizeFirstLetter(columnName)
          }
          setPageState(updatedPage)
        } else {
          item.sortDirection = SortDirectionType.None
        }
        return item
      })
    )
  }

  const handleSelectedOrder = (event: React.ChangeEvent, name: number) => {
    event.preventDefault()
    const selectedIndex = selectedOrders.indexOf(name)
    let newSelected: number[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOrders, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOrders.slice(1))
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelected = newSelected.concat(selectedOrders.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      )
    }
    setSelectedOrders(newSelected)
  }

  const updatePage = (value: QueryFilter) => {
    postOrderFilter(value)
      .then((response) => {
        const typedOrg = response.data as IOrderListPayload
        const updatedOrderItems: IOrderList[] =
          typedOrg &&
          typedOrg.orders.reduce(
            (accOrders: IOrderList[], order: IOrderList) => {
              let orderItem: IOrderList = {
                headerOrderStatus: order.orderStatus.id,
                headerAddress: order.contact.address.fullAddress,
                headerRecentInternalNote:
                  order.recentInternalNote?.text === null ||
                  order.recentInternalNote?.text === undefined
                    ? null
                    : order.recentInternalNote?.text,
                ...order
              }
              return (accOrders = [...(accOrders || []), orderItem])
            },
            []
          )
        typedOrg.orders = updatedOrderItems

        setOrderState(typedOrg)
        setIsLoading(false)
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to get filter order list'
        })
      })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.currentPage = newPage
      setPageState(newPageState)
    }
    setSelectedOrders([])
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.count = parseInt(event.target.value, 10)
      setPageState(newPageState)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePage(pageState)
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [pageState])

  useEffect(() => {
    updatePage(pageState)
  }, [pageState])

  useEffect(() => {
    const searchValue = tableHeaders.find((item) => item.searchValue !== '')
    let filter = {
      filterBy: '',
      filterValue: ''
    }
    if (searchValue && searchValue.searchValue) {
      filter = {
        filterBy: searchValue.name,
        filterValue: searchValue.searchValue
      }
    }

    filter && setSearchQuery(filter)
  }, [tableHeaders, setSearchQuery])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelPostOrderFilter()
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      <Grid
        className="admin-orders-list-container"
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        style={{ overflow: 'auto' }}
      >
        <Grid item style={{ maxWidth: '100%' }}>
          {orderState && (
            <>
              <Box mt={3} mb={3}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item lg={2}>
                    <Typography variant="h2" color="primary">
                      Orders
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <ASOrdersTable
                selectedOrders={selectedOrders}
                tableHeaders={tableHeaders}
                requestSort={(columnName, sortDirection) =>
                  handleSortRequest(columnName, sortDirection)
                }
                requestSearch={(tableHeaders) =>
                  handleSearchRequest(tableHeaders)
                }
                orders={orderState.orders}
                handleSelectedOrder={(event, id) =>
                  handleSelectedOrder(event, id)
                }
                clearSelectedOrders={() => setSelectedOrders([])}
              />
            </>
          )}

          {orderState && pageState && pageState.pagination && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={orderState.total}
              rowsPerPage={pageState.pagination.count}
              page={orderState.currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

import { Dictionary } from '.'

export enum StatusType {
  Pending = 'Pending',
  Active = 'Active',
  OnHold = 'CBC-On Hold',
  InProgress = 'In Progress',
  Submitted = 'Submitted',
  Cancelled = 'Cancelled',
  Processed = 'Processed',
  Inactive = 'Inactive',
  Retired = 'Retired'
}

export enum OrderStatusType {
  Pending = 'Pending',
  Submitted = 'Submitted',
  OnHold = 'CBC-On Hold',
  InProgress = 'In Progress',
  Processed = 'Processed',
  Cancelled = 'Cancelled'
}

export enum AllStatusType {
  StatusType
}

export type TAllStatuses =
  | StatusType.Pending
  | StatusType.Submitted
  | StatusType.InProgress
  | StatusType.OnHold
  | StatusType.Pending
  | StatusType.Processed
  | StatusType.Cancelled
  | StatusType.Active
  | StatusType.Inactive
  | StatusType.Retired

export const findStatusType: Dictionary<TAllStatuses> = {
  [StatusType.Pending]: StatusType.Pending,
  [StatusType.Submitted]: StatusType.Submitted,
  [StatusType.InProgress]: StatusType.InProgress,
  [StatusType.Processed]: StatusType.Processed,
  [StatusType.OnHold]: StatusType.OnHold,
  [StatusType.Cancelled]: StatusType.Cancelled,
  [StatusType.Active]: StatusType.Active,
  [StatusType.Inactive]: StatusType.Inactive,
  [StatusType.Retired]: StatusType.Retired
}

export type TOrderStatus =
  | StatusType.Pending
  | StatusType.Active
  | StatusType.InProgress
  | StatusType.Processed
  | StatusType.Submitted
  | StatusType.OnHold
  | StatusType.Cancelled

export const findStatusOrderType: Dictionary<TOrderStatus> = {
  [StatusType.Pending]: StatusType.Pending,
  [StatusType.Submitted]: StatusType.Submitted,
  [StatusType.InProgress]: StatusType.InProgress,
  [StatusType.Processed]: StatusType.Processed,
  [StatusType.OnHold]: StatusType.OnHold,
  [StatusType.Cancelled]: StatusType.Cancelled,
  [StatusType.Active]: StatusType.Active
}

export const findOrderStatusTypeByNumber: Dictionary<TOrderStatus> = {
  1: StatusType.Pending,
  2: StatusType.Submitted,
  3: StatusType.OnHold,
  4: StatusType.InProgress,
  5: StatusType.Processed,
  6: StatusType.Cancelled
}

export const findOrderStatusTypeNumber: Dictionary<number> = {
  [StatusType.Pending]: 1,
  [StatusType.Submitted]: 2,
  [StatusType.OnHold]: 3,
  [StatusType.InProgress]: 4,
  [StatusType.Processed]: 5,
  [StatusType.Cancelled]: 6
}

export enum ProductStatusType {
  Active = 'Active',
  Inactive = 'Inactive',
  Retired = 'Retired'
}

export type TProductStatus =
  | ProductStatusType.Active
  | ProductStatusType.Inactive
  | ProductStatusType.Retired

export const findProductStatusType: Dictionary<TProductStatus> = {
  [ProductStatusType.Active]: ProductStatusType.Active,
  [ProductStatusType.Inactive]: ProductStatusType.Inactive,
  [ProductStatusType.Retired]: ProductStatusType.Retired
}

export const findProductStatusTypeNumber: Dictionary<TProductStatus> = {
  1: ProductStatusType.Active,
  2: ProductStatusType.Inactive,
  3: ProductStatusType.Retired
}

export const findProductStatusNumber: Dictionary<number> = {
  [ProductStatusType.Active]: 1,
  [ProductStatusType.Inactive]: 2,
  [ProductStatusType.Retired]: 3
}

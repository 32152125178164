import React, { FunctionComponent, useRef } from 'react'
import {
  Box,
  makeStyles,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  TableHead,
  useTheme,
  CircularProgress
} from '@material-ui/core'
import { IOrder, OrderLineModel } from '../../models'

import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from 'react-to-print'
import WhiteLogo from '../../images/qpl-logo-no-background-white.png'
import Moment from 'react-moment'

export const ASPrintCellContainer = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: theme.spacing(1)
  }
}))(TableCell)

export const ASPrintCell = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.common.black}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    fontSize: '10px',
    height: '30px',
    lineHeight: '1.2'
  }
}))(TableCell)

export const ASPrintCellText = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.common.black,
    fontSize: '10px',
    height: '30px',
    lineHeight: '1.2'
  }
}))(TableCell)

export const ASPrintHeaderCell = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.common.black}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    fontSize: '10px',
    height: '30px',
    lineHeight: '1.2',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold
  }
}))(TableCell)

export const ASPrintTableCell = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.common.black}`,
    padding: `5px ${theme.spacing(1)}px`,
    fontSize: '10px',
    height: '20px',
    lineHeight: '1.2',
    textAlign: 'center'
  }
}))(TableCell)

interface IComponentToPrintProps {
  order: IOrder
}

export const ComponentToPrint: FunctionComponent<IComponentToPrintProps> = ({
  order
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'none',
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100%',
      height: 'auto',
      background: '#ffffff',
      zIndex: 100,
      '@media print': {
        position: 'relative',
        top: 0,
        left: 0,
        display: 'block',
        pageBreakAfter: 'always'
      }
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      background: theme.palette.primary.dark,
      color: theme.palette.common.white
    },
    headerInner: {
      display: 'flex',
      alignItems: 'center'
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium
    },
    logo: {
      height: 'auto',
      width: theme.spacing(20),
      padding: theme.spacing(2),
      marginRight: theme.spacing(6)
    },
    content: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      textAlign: 'left',
      fontSize: '12px'
    }
  }))
  const classes = useStyles()
  const theme = useTheme()
  const contact = order.organisation.contact[0]

  return (
    <div className={`order-for-print ${classes.root}`}>
      <Box className={classes.header}>
        <Grid container className={classes.headerInner}>
          <Grid item>
            <img
              className={classes.logo}
              src={WhiteLogo}
              alt="Quantum Aseptic Services logo"
            />
          </Grid>
          <Grid item>
            <h2 className={classes.title}>
              Named patient chemotherapy order form (online)
            </h2>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.content}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ verticalAlign: 'top', marginBottom: '10px' }}>
                <ASPrintCellContainer>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow style={{ verticalAlign: 'top' }}>
                          <ASPrintCell
                            size="small"
                            style={{ width: '25%', padding: 0 }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <ASPrintCellText align="center" size="small">
                                    <strong>ONLINE ORDER</strong>
                                  </ASPrintCellText>
                                </TableRow>
                                <TableRow>
                                  <ASPrintCellText
                                    size="small"
                                    align="center"
                                    style={{ border: 'none' }}
                                  >
                                    {order.id}
                                  </ASPrintCellText>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </ASPrintCell>
                          <ASPrintCell
                            align="center"
                            size="small"
                            style={{ width: '25%', padding: 0 }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <ASPrintCellText align="center" size="small">
                                    <strong>DATE CREATED</strong>
                                  </ASPrintCellText>
                                </TableRow>
                                <TableRow>
                                  <ASPrintCellText
                                    size="small"
                                    align="center"
                                    style={{ border: 'none' }}
                                  >
                                    <Moment
                                      local
                                      format="dddd Do MMMM YYYY HH:mm"
                                      date={order.orderCreateDate}
                                    />
                                  </ASPrintCellText>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </ASPrintCell>
                          <ASPrintCell
                            rowSpan={6}
                            style={{ width: '30%', padding: '0' }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <ASPrintCellText align="center" size="small">
                                    <strong>DELIVERY ADDRESS</strong>
                                  </ASPrintCellText>
                                </TableRow>
                                <TableRow>
                                  <ASPrintCellText
                                    rowSpan={4}
                                    size="small"
                                    align="left"
                                    style={{ border: 'none' }}
                                  >
                                    <p>
                                      {contact.address.addressLine1 && (
                                        <>
                                          {contact.address.addressLine1}
                                          <br />
                                        </>
                                      )}

                                      {contact.address.addressLine2 && (
                                        <>
                                          {contact.address.addressLine2}
                                          <br />
                                        </>
                                      )}

                                      {contact.address.addressLine3 && (
                                        <>
                                          {contact.address.addressLine3}
                                          <br />
                                        </>
                                      )}

                                      {contact.address.town && (
                                        <>
                                          {contact.address.town}
                                          <br />
                                        </>
                                      )}

                                      {contact.address.county && (
                                        <>
                                          {contact.address.county}
                                          <br />
                                        </>
                                      )}

                                      {contact.address.postCode && (
                                        <>
                                          {contact.address.postCode}
                                          <br />
                                        </>
                                      )}
                                    </p>

                                    <br />

                                    {contact.address.phoneNumber && (
                                      <p>
                                        Phone - {contact.address.phoneNumber}
                                      </p>
                                    )}
                                  </ASPrintCellText>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </ASPrintCell>
                          <ASPrintCell
                            rowSpan={6}
                            style={{ width: '20%', padding: '0' }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <ASPrintCellText align="center" size="small">
                                    <strong>QPL USE ONLY</strong>
                                  </ASPrintCellText>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    size="small"
                                    align="center"
                                    style={{ border: 'none' }}
                                  ></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </ASPrintCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            size="small"
                            align="center"
                            style={{ border: 'none' }}
                          ></TableCell>
                        </TableRow>
                        <TableRow>
                          <ASPrintCell size="small" style={{ width: '25%' }}>
                            <strong>PATIENT NAME</strong>
                          </ASPrintCell>
                          <ASPrintCell
                            align="center"
                            size="small"
                            style={{ width: '25%' }}
                          >
                            {order.patientName}
                          </ASPrintCell>
                        </TableRow>
                        <TableRow>
                          <ASPrintCell size="small" style={{ width: '25%' }}>
                            <strong>HOSPITAL NUMBER</strong>
                          </ASPrintCell>
                          <ASPrintCell
                            align="center"
                            size="small"
                            style={{ width: '25%' }}
                          >
                            {order.hospitalNumber}
                          </ASPrintCell>
                        </TableRow>
                        <TableRow>
                          <ASPrintCell size="small" style={{ width: '25%' }}>
                            <strong>ORDER NUMBER</strong>
                          </ASPrintCell>
                          <ASPrintCell
                            align="center"
                            size="small"
                            style={{ width: '25%' }}
                          >
                            {order.patientReference}
                          </ASPrintCell>
                        </TableRow>
                        <TableRow>
                          <ASPrintCell size="small" style={{ width: '25%' }}>
                            <strong>ON HOLD - CALL FIRST</strong>
                          </ASPrintCell>
                          <ASPrintCell
                            align="center"
                            size="small"
                            style={{
                              width: '25%',
                              color: `${
                                order.callBeforeCompounding
                                  ? theme.palette.common.white
                                  : 'inherit'
                              }`,
                              backgroundColor: `${
                                order.callBeforeCompounding
                                  ? theme.palette.common.black
                                  : theme.palette.common.white
                              }`
                            }}
                          >
                            {order.callBeforeCompounding ? (
                              <strong>Yes - Call before compounding</strong>
                            ) : (
                              'No'
                            )}
                          </ASPrintCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ASPrintCellContainer>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <ASPrintCellContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <ASPrintCell style={{ width: '25%' }}>
                          <strong>DATE DELIVERY REQUIRED</strong>
                        </ASPrintCell>
                        <ASPrintCell align="center" style={{ width: '25%' }}>
                          <Moment
                            local
                            format="dddd Do MMMM YYYY"
                            date={order.requiredDate}
                          />
                        </ASPrintCell>
                        <ASPrintCell rowSpan={2}>
                          <strong>Notes </strong>
                          <br />
                          {order?.recentExternalNote?.text}
                        </ASPrintCell>
                      </TableRow>
                      <TableRow>
                        <ASPrintCell style={{ width: '25%' }}>
                          <strong>PATIENT TREATMENT DATE:</strong>
                        </ASPrintCell>
                        <ASPrintCell align="center" style={{ width: '25%' }}>
                          <Moment
                            local
                            format="dddd Do MMMM YYYY HH:mm"
                            date={order.patientTreatmentStartDate}
                          />
                        </ASPrintCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </ASPrintCellContainer>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <ASPrintCellContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <ASPrintHeaderCell
                          style={{ width: '3%' }}
                        ></ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          DRUG
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          DOSE
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          VEHICLE
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          VOLUME
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          ROUTE
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          FINAL CONTAINER
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          QTY
                        </ASPrintHeaderCell>
                        <ASPrintHeaderCell style={{ width: '10%' }}>
                          QPL USE
                          <br />
                          ONLY
                          <br />
                          NAV N
                        </ASPrintHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.orderLines.map(
                        (product: OrderLineModel, index) => (
                          <TableRow key={`product-table-cell-${index}`}>
                            <ASPrintTableCell style={{ width: '3%' }}>
                              <strong>{index + 1}</strong>
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.productName}
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.dose}
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.productRevision.vehicle.otherName}
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.volume}
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.productRevision.productRoute.description}
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.productRevision.container.description}
                            </ASPrintTableCell>
                            <ASPrintTableCell style={{ width: '10%' }}>
                              {product.quantity}
                            </ASPrintTableCell>
                            <ASPrintTableCell
                              style={{
                                width: '10%',
                                background: theme.palette.grey[300]
                              }}
                            ></ASPrintTableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </ASPrintCellContainer>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <ASPrintCellContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <ASPrintCell style={{ width: '20%' }}>
                          <strong>NAME OF PERSON ORDERING</strong>
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '30%' }}>
                          {`${order.orderedByUser.firstName} ${order.orderedByUser.lastName}`}
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '20%' }}>
                          <strong>POSITION</strong>
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '10%' }}>
                          {order.orderedByUser.jobDescription}
                        </ASPrintCell>
                      </TableRow>
                      <TableRow>
                        <ASPrintCell style={{ width: '10%' }}>
                          <strong>ELECTRONIC SIGNATURE</strong>
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '30%' }}>
                          {order.orderedByUser.id}
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '10%' }}>
                          <strong>DATE</strong>
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '40%' }}>
                          <Moment
                            local
                            format="dddd Do MMMM YYYY HH:mm"
                            date={order.orderCreateDate}
                          />
                        </ASPrintCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </ASPrintCellContainer>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <ASPrintCellContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <ASPrintCell style={{ width: '20%' }}>
                          <strong>QUANTUM - INPUT BY</strong>
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '30%' }}></ASPrintCell>
                        <ASPrintCell style={{ width: '20%' }}>
                          <strong>CHECKED BY</strong>
                        </ASPrintCell>
                        <ASPrintCell style={{ width: '35%' }}></ASPrintCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </ASPrintCellContainer>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  )
}

interface IASPrintTemplateProps {
  /** active prop allows the component to change colour based on parent state */
  active: boolean | undefined
  /** orders that are provided for print */
  orders?: IOrder[]
  /* allows the parent component to inform when loading is complete */
  isLoading: boolean
  /** onClick is a passthrough function to allow onClick to be handled by the parent */
  handlePrinted: () => void
}
export const ASPrintTemplate: FunctionComponent<IASPrintTemplateProps> = ({
  active,
  orders,
  isLoading,
  handlePrinted
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.grey[500],
      cursor: 'pointer',

      '&.active': {
        color: theme.palette.primary.main
      }
    }
  }))

  const classes = useStyles()
  const componentRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {active && !isLoading && (
        <ReactToPrint
          onAfterPrint={() => handlePrinted()}
          trigger={() => (
            <Box className={`${classes.root} ${active ? 'active' : ''}`}>
              <PrintIcon />
              <span className="print-counter">
                Print {orders && orders.length}
              </span>
            </Box>
          )}
          content={() => componentRef.current}
        />
      )}
      {!active && !isLoading && (
        <Box className={`${classes.root}`}>
          <PrintIcon />
          <span className="print-counter">Print</span>
        </Box>
      )}
      {isLoading && (
        <Box className={`${classes.root}`}>
          <CircularProgress size="30px" />
        </Box>
      )}
      {orders && (
        <div ref={componentRef}>
          {orders.map((order: IOrder, index) => {
            return (
              <ComponentToPrint key={`printed-order-${index}`} order={order} />
            )
          })}
        </div>
      )}
    </>
  )
}

import { Dictionary } from '../types/Dictionary'
import { SortDirectionType } from '../types/SortTypes'

const nextSortDirection: Dictionary<SortDirectionType> = {
  [SortDirectionType.None]: SortDirectionType.Ascending,
  [SortDirectionType.Ascending]: SortDirectionType.Descending,
  [SortDirectionType.Descending]: SortDirectionType.None
}

export const toggleSortDirection = (currentSortDirection: SortDirectionType) =>
  nextSortDirection[currentSortDirection]

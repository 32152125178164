import React, { FunctionComponent } from 'react'
import { findStatusType } from '../../types'
import { Chip, withStyles } from '@material-ui/core'

interface IASStatusProps {
  status: string
}

const ASStatusChip = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    '&.pending': {
      color: theme.palette.grey[800],
      backgroundColor: theme.palette.warning.light
    },
    '&.active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main
    },
    '&.cbc-on-hold': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark
    },
    '&.in-progress': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark
    },
    '&.processed': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main
    },
    '&.submitted': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark
    },
    '&.retired': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700]
    }
  }
}))(Chip)

export const ASStatus: FunctionComponent<IASStatusProps> = ({ status }) => {
  const statusName = findStatusType[status]
  const statusClassName = statusName.trim().toLowerCase().replace(/ /g, '-')
  return (
    <>
      <ASStatusChip
        className={statusClassName}
        size="medium"
        label={statusName}
      />
    </>
  )
}

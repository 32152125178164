import React, { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'

export const NotFound: FunctionComponent = () => {
  return (
    <Box mt={2} mx={0}>
      <div>Not found...</div>
    </Box>
  )
}

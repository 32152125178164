import React, {
  FunctionComponent,
  createContext,
  useState,
  useEffect
} from 'react'
import { IOrder } from '../models'
import { User } from 'oidc-client'
import { IAuthEvent, AuthEventType } from '../events/AuthEvent'
import authService from '../services/AuthService'
import analyticsServiceSingleton from '../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../services/Analytics'
import config from '../services/ConfigProvider'

export enum EventTypes {
  UPDATE_ADMIN = 'UPDATE_ADMIN',
  UPDATE_ORDER = 'UPDATE_ORDER'
}

export type IOrderEvent = {
  type: EventTypes.UPDATE_ORDER
  activeOrder: IOrder | undefined
}

export type IAdminEvent = {
  type: EventTypes.UPDATE_ADMIN
  adminState: boolean | undefined
}

export type AppEvent = IOrderEvent | IAdminEvent | IAuthEvent

export interface IAppContext {
  dispatch: (event: AppEvent) => void
  isAuthenticated: boolean | undefined
  user: User | undefined
  adminState: boolean | undefined
  activeOrder?: IOrder | undefined
}

export const defaultCtx = {
  dispatch: () => {},
  isAuthenticated: undefined,
  user: undefined,
  adminState: undefined,
  activeOrder: undefined
}

export const AppContext = createContext<IAppContext>(defaultCtx)
export const AppContextConsumer = AppContext.Consumer

interface IAppContextProvider {
  /* This allows you to provide a mock context for stories and tests  */
  providedContext?: IAppContext
}

export const AppContextProvider: FunctionComponent<IAppContextProvider> = ({
  children,
  providedContext
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  )
  const [user, setUser] = useState<User>()
  const [adminState, setAdminState] = useState<boolean | undefined>(true)
  const [activeOrder, setActiveOrder] = useState<IOrder>()
  const [appContext, setAppContext] = useState<IAppContext>({
    dispatch: (event: AppEvent) => {
      switch (event.type) {
        case AuthEventType.GET_USER:
          authService
            .getUser()
            .then((user) => {
              setIsAuthenticated(!!user)
              user && setUser(user)
            })
            .catch((error) => {
              console.warn('An error occurred while authenticating')
            })
          break
        case AuthEventType.LOG_IN:
          authService
            .login(
              event.redirectUrl &&
                event.redirectUrl.replace(config.clientRoot, '/')
            )
            .then((response) => {
              setIsAuthenticated(true)
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.LoginSuccess,
                {
                  userId: user?.profile.sub
                }
              )
            })
            .catch((error) => {
              console.warn('An error occurred while logging in')
              setIsAuthenticated(false)
              analyticsServiceSingleton.trackEvent(AnalyticsEvent.LoginFail, {
                userId: user?.profile.email
              })
            })
          break
        case EventTypes.UPDATE_ADMIN:
          setAdminState(event.adminState)
          break
        case EventTypes.UPDATE_ORDER:
          setActiveOrder(event.activeOrder)
          break
      }
    },
    adminState,
    activeOrder,
    isAuthenticated,
    user
  })

  useEffect(() => {
    providedContext &&
      setAppContext({
        ...providedContext
      })
    !providedContext &&
      setAppContext({
        ...appContext,
        adminState,
        activeOrder,
        isAuthenticated,
        user
      })
  }, [isAuthenticated, user, adminState, activeOrder, providedContext])

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  )
}

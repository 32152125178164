import { withStyles, Checkbox, Box } from '@material-ui/core'

export const ASCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    padding: 0
  }
}))(Checkbox)

export const Error = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    color: 'red'
  }
}))(Box)

import React, { FunctionComponent, useEffect, useContext } from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import {
  makeStyles,
  useTheme,
  withStyles,
  darken
} from '@material-ui/core/styles'
import { NavLink, Link } from 'react-router-dom'
import Logo from '../../images/qpl-logo-no-background.png'
import WhiteLogo from '../../images/qpl-logo-no-background-white.png'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircle from '@material-ui/icons/AccountCircle'
import authService from '../../services/AuthService'

import {
  Box,
  Hidden,
  Drawer,
  Divider,
  List,
  ListItem,
  Menu,
  MenuItem
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { ASHorizonalMenu, ASHorizonalMenuLink } from '../ASHorizonalMenu/index'
import { AppContext } from '../../context'
import { createGetUserAuthEvent } from '../../events/AuthEvent'

const drawerWidth = 240

export const ASIconButton = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))(IconButton)

export const ASLogoutButton = withStyles((theme) => ({
  root: {
    display: 'inline',
    color: theme.palette.common.white,
    fontSize: '14px',
    textTransform: 'none',
    textAlign: 'left',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
  }
}))(Button)

interface IDrawerLinkProps {
  to: string
  text: string
  onClick?: () => void
}

const ASDrawerLink: FunctionComponent<IDrawerLinkProps> = ({
  to,
  text,
  onClick
}) => {
  const theme = useTheme()
  const useStyles = makeStyles({
    navlink: {
      color: theme.palette.common.white,
      textDecoration: 'none',
      paddingLeft: 0,
      '&:hover': {
        color: theme.palette.primary.main
      },
      '&.is-active': {
        color: theme.palette.primary.main
      }
    }
  })

  const classes = useStyles()

  return (
    <ListItem button>
      <NavLink
        className={classes.navlink}
        activeClassName="is-active"
        to={to}
        onClick={() => onClick && onClick()}
      >
        <ListItem button>
          <span>{text}</span>
        </ListItem>
      </NavLink>
    </ListItem>
  )
}

interface IASMainMenuProps {
  isAdmin: boolean
}

export const ASMainMenu: FunctionComponent<IASMainMenuProps> = ({
  isAdmin,
  children
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [accountMenu, setAccountMenu] = React.useState(null)
  const accountMenuBool = Boolean(accountMenu)
  const { dispatch, user } = useContext(AppContext)

  useEffect(() => {
    dispatch(createGetUserAuthEvent())
  }, [dispatch])

  const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1
    },
    logoContainer: {
      marginRight: theme.spacing(5),
      imageRendering: '-webkit-optimize-contrast'
    },
    logo: {
      height: 'auto',
      width: theme.spacing(20),
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(1.5)}px 0`,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(20)
      }
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2, 1, 2, 0),

      color: theme.palette.common.white,
      ...theme.mixins.toolbar
    },
    appBar: {
      backgroundColor: isAdmin
        ? theme.palette.primary.dark
        : theme.palette.grey[100],
      color: isAdmin ? theme.palette.common.white : theme.palette.text.primary
    },
    deskTopProfile: {
      display: 'none',
      alignItems: 'center',
      height: '100%',
      paddingLeft: theme.spacing(3),
      borderLeft: `2px solid ${
        isAdmin
          ? darken(theme.palette.primary.dark, 0.1)
          : theme.palette.grey[100]
      }`,
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    mobileProfile: {
      display: 'block',
      paddingLeft: theme.spacing(3),

      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    title: {
      flexGrow: 1,
      letterSpacing: '1.2px',
      lineHeight: '1.3'
    },
    jobTitle: {
      textTransform: 'uppercase',
      fontSize: '11px'
    },
    inputRoot: {
      color: 'inherit'
    },
    toolbar: { paddingRight: 0 },
    drawerPaper: {
      width: drawerWidth,
      background: theme.palette.secondary.dark,
      color: theme.palette.common.white
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      height: '100%',
      maxWidth: '2200px',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px `,
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(4)}px ${theme.spacing(3)}px `
      }
    }
  }))
  const classes = useStyles()

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const handleAccountMenu = (event: any) => {
    setAccountMenu(event.currentTarget)
  }
  const handleAccountClose = () => {
    setAccountMenu(null)
  }

  const drawer = (
    <>
      <div className={classes.drawerHeader}>
        <div className={classes.mobileProfile}>
          <Typography
            align="right"
            className={classes.title}
            variant="body2"
            noWrap
          >
            {user && user.profile.name && <strong>{user.profile.name}</strong>}

            <br />
            {user && user.profile.job_description && (
              <span className={classes.jobTitle}>
                {user.profile.job_description}
              </span>
            )}
          </Typography>
        </div>
        <IconButton color="inherit" onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <Divider />
      <List>
        <ASDrawerLink
          to="/orders"
          text="Orders"
          onClick={() => handleDrawerClose()}
        />
        {!isAdmin && (
          <ASDrawerLink
            to="/orders/new"
            text="Add Order"
            onClick={() => handleDrawerClose()}
          />
        )}

        {isAdmin && (
          <>
            <ASDrawerLink
              to="/products"
              text="Products"
              onClick={() => handleDrawerClose()}
            />
            <ASDrawerLink
              to="/customers"
              text="Customers"
              onClick={() => handleDrawerClose()}
            />

            <ASDrawerLink
              to="/users"
              text="Users"
              onClick={() => handleDrawerClose()}
            />
          </>
        )}

        <Divider />
        <ASLogoutButton fullWidth={true} onClick={() => authService.logout()}>
          Logout
        </ASLogoutButton>
      </List>
    </>
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        position="static"
        className={classes.appBar}
        color="inherit"
      >
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <Box mr={3} display="flex" alignItems="center" height="100%">
                <Link className={classes.logoContainer} to="/orders">
                  <img
                    className={classes.logo}
                    src={isAdmin ? WhiteLogo : Logo}
                    alt="Quantum Aseptic Services logo"
                  />
                </Link>
                {isAdmin && (
                  <Typography
                    className={`admin-title ${classes.title}`}
                    variant="h6"
                    noWrap
                  >
                    ADMIN
                  </Typography>
                )}
              </Box>
            </Grid>

            {user && (
              <Grid item>
                <Box display="flex" alignItems="center" height="100%">
                  <ASHorizonalMenu>
                    {isAdmin && (
                      <>
                        <ASHorizonalMenuLink to="/orders" text="Orders" />

                        <ASHorizonalMenuLink to="/products" text="Products" />

                        <ASHorizonalMenuLink to="/customers" text="Customers" />
                        <ASHorizonalMenuLink to="/users" text="Users" />
                      </>
                    )}
                  </ASHorizonalMenu>
                  <div className={classes.deskTopProfile}>
                    <Typography
                      align="right"
                      className={classes.title}
                      variant="body2"
                      noWrap
                    >
                      {user && user.profile.name && (
                        <strong>{user.profile.name}</strong>
                      )}
                      <br />
                      {user && user.profile.job_description && (
                        <span className={classes.jobTitle}>
                          {user.profile.job_description}
                        </span>
                      )}
                    </Typography>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleAccountMenu}
                      color="inherit"
                    >
                      <AccountCircle fontSize="large" />
                    </IconButton>
                  </div>
                  <div>
                    <Menu
                      id="menu-appbar"
                      anchorEl={accountMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={accountMenuBool}
                      onClose={handleAccountClose}
                    >
                      <MenuItem onClick={() => authService.logout()}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>

                  <ASIconButton
                    edge="end"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </ASIconButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="right"
            open={false}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            anchor="right"
            open={drawerOpen}
            ModalProps={{ onBackdropClick: handleDrawerClose }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  )
}

import React, { FunctionComponent } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import WhiteLogo from '../../images/qpl-logo-no-background-white.png'

export const ASFooter: FunctionComponent = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flex: '0 0 auto',
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(
        2
      )}px ${theme.spacing(3)}px`,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white,

      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(
          2
        )}px ${theme.spacing(5)}px`
      }
    },
    logo: {
      maxWidth: theme.spacing(22),
      imageRendering: '-webkit-optimize-contrast',

      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.spacing(25)
      }
    },
    title: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      margin: `0 0 ${theme.spacing(1)}px 0`
    },
    info: {
      fontSize: theme.typography.h6.fontSize,
      margin: 0
    },
    link: {
      marginLeft: theme.spacing(1),
      color: theme.palette.common.white,
      transition: 'color 0.25s',
      textDecoration: 'none',

      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    dataList: {
      margin: `0 0 ${theme.spacing(4)}px 0`
    },
    smallPrint: {
      marginTop: theme.spacing(2)
    }
  }))
  const classes = useStyles()

  return (
    <>
      <Grid
        container
        className={`footer ${classes.root}`}
        justify="space-between"
      >
        <Grid item xs={12} sm={6} md={3}>
          <dl className={classes.dataList}>
            <dt className={classes.title}>Head Office</dt>
            <dd className={classes.info}>Quantum House</dd>
            <dd className={classes.info}>Hobson Industrial Estate</dd>
            <dd className={classes.info}>Burnopfield</dd>
            <dd className={classes.info}>Co. Durham, NE16 6EA</dd>
            <dd className={classes.info}>United Kingdom</dd>
          </dl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <dl className={classes.dataList}>
            <dt className={classes.title}>General Enquiries</dt>
            <dd className={classes.info}>
              Tel:
              <a className={classes.link} href="tel:44+08000430096">
                0800 043 0096
              </a>
            </dd>
            <dd className={classes.info}>
              Email:
              <a
                className={classes.link}
                href="mailto:aseptics@quantumpharma.co.uk"
              >
                aseptics@quantumpharma.co.uk
              </a>
            </dd>
          </dl>
        </Grid>
        <Grid style={{ textAlign: 'right' }} item xs={12} sm={12} md={3}>
          <div>
            <img
              className={classes.logo}
              src={WhiteLogo}
              alt="Quantum Aseptic Services logo"
            />
          </div>
        </Grid>
        <Grid
          className={classes.smallPrint}
          style={{ textAlign: 'right' }}
          item
          xs={12}
        >
          <small>© {new Date().getFullYear()} Quantum Pharma Plc</small>
        </Grid>
      </Grid>
    </>
  )
}

import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  CancelTokenSource,
  CancelToken
} from 'axios'

import {
  IProduct,
  IOrganisation,
  IOrganisationPayload,
  QueryFilter,
  IProductPayload,
  IProductOptions,
  IOrderListPayload,
  IOrder,
  IOrderList,
  OrderStatusModel,
  CreateUserModel,
  IUserListPayload
} from '../models'
import { UserModel } from '../models/swaggerTypesModified'

import config from './ConfigProvider'

import authService from './AuthService'
import { User } from 'oidc-client'

export const REQUEST_CANCELLED = 'REQUEST_CANCELLED'

export enum AuthError {
  NetWorkError = 'Network error occurred',
  TokenMissingOrExpired = 'User is missing access_token or token expired',
  RenewingTokenError = 'An error occurred while renewing the access token',
  UnknownAuthError = 'An unknown auth error occurred',
  RequestCancelled = 'Request was cancelled before completing'
}

/**
 * Utility method to add token to headers
 * @param user
 */
const setAuthTokenToHeader = (user: User) => {
  axios.defaults.headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${user.access_token}`
  }
}

/**
 * Utility method to set request
 * @param path
 * @param method
 * @param payload
 * @param cancelToken
 */
const setRequestConfig = (
  path: string,
  method: 'GET' | 'POST' | 'PUT' = 'GET',
  payload?: any,
  cancelToken?: CancelToken
): AxiosRequestConfig => ({
  method: method as Method,
  url: `${config.apiRoot}${path}`,
  data: payload,
  cancelToken
})
/**
 * Generic call wrapper method which inserts a valid auth token
 * Use to wrap any API calls
 * @param path
 * @param method
 * @param payload
 * @param cancelToken
 */
const call = async <Response = any>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' = 'GET',
  payload?: any,
  cancelToken?: CancelToken
): Promise<AxiosResponse<Response>> => {
  let oidcUser: User | null
  let authRequest: any
  let requestConfig: AxiosRequestConfig
  try {
    // Check if we have a valid user/token
    oidcUser = await authService.getUserAsync()
    oidcUser && setAuthTokenToHeader(oidcUser)
    requestConfig = setRequestConfig(path, method, payload, cancelToken)
    authRequest = await axios.request<Response>(requestConfig)
  } catch (error) {
    // If a network request was cancelled
    if (error.message === REQUEST_CANCELLED) {
      console.info(AuthError.RequestCancelled)
      return Promise.reject(new Error(AuthError.RequestCancelled))
    }
    // If a network error (disconnected)
    if (!error.response) {
      console.warn(AuthError.NetWorkError)
      return Promise.reject(new Error(AuthError.NetWorkError))
    }
    // If token has expired use refreshToken to get a new token/user
    if (error.response.status === 401) {
      console.warn(AuthError.TokenMissingOrExpired)
      try {
        oidcUser = await authService.renewTokenAsync()
        oidcUser && setAuthTokenToHeader(oidcUser)
        requestConfig = setRequestConfig(path, method, payload)
        authRequest = await axios.request<Response>(requestConfig)
      } catch (error) {
        console.warn(AuthError.RenewingTokenError)
        // Redirect to login if a user tries to spoof
        await authService.login()
        return Promise.reject(new Error(AuthError.RenewingTokenError))
      }
    } else {
      console.warn(AuthError.UnknownAuthError)
      return Promise.reject(error)
    }
  }
  return authRequest
}

// GetValues --------------------------------------------------

let cancelGetValuesSource: CancelTokenSource

const cancelGetValues = () => {
  cancelGetValuesSource && cancelGetValuesSource.cancel(REQUEST_CANCELLED)
}

const getValues = (): Promise<AxiosResponse<any>> => {
  cancelGetValuesSource = axios.CancelToken.source()

  return call<IOrder[]>('api/Values', 'GET', cancelGetValuesSource.token)
}

// GetOrders --------------------------------------------------

let cancelGetOrdersSource: CancelTokenSource

const cancelGetOrders = () => {
  cancelGetOrdersSource && cancelGetOrdersSource.cancel(REQUEST_CANCELLED)
}

const getOrders = (): Promise<AxiosResponse<IOrderList[]>> => {
  cancelGetValuesSource = axios.CancelToken.source()
  return call<IOrderList[]>('api/Orders', 'GET', cancelGetOrdersSource.token)
}

// GetOrderById --------------------------------------------------

let cancelGetOrderByIdSource: CancelTokenSource

const cancelGetOrderById = () => {
  cancelGetOrderByIdSource && cancelGetOrderByIdSource.cancel(REQUEST_CANCELLED)
}

const getOrderById = (id: string): Promise<AxiosResponse<IOrder>> => {
  cancelGetOrderByIdSource = axios.CancelToken.source()

  return call<IOrder>(`api/orders/${id}`, 'GET', cancelGetOrderByIdSource.token)
}

// GetStatusOptions --------------------------------------------------

let cancelGetStatusOptionsSource: CancelTokenSource

const cancelGetStatusOptions = () => {
  cancelGetStatusOptionsSource &&
    cancelGetStatusOptionsSource.cancel(REQUEST_CANCELLED)
}

const getStatusOptions = (
  orderId: number
): Promise<AxiosResponse<OrderStatusModel[]>> => {
  cancelGetStatusOptionsSource = axios.CancelToken.source()

  return call<OrderStatusModel[]>(
    `/api/Orders/${orderId}/status/options`,
    'GET'
  )
}

// PutOrderStatus --------------------------------------------------

let cancelPutOrderStatusSource: CancelTokenSource

const cancelPutOrderStatus = () => {
  cancelPutOrderStatusSource &&
    cancelPutOrderStatusSource.cancel(REQUEST_CANCELLED)
}

const putOrderStatus = (
  orderId: number,
  statusId: number
): Promise<AxiosResponse<boolean>> => {
  cancelPutOrderStatusSource = axios.CancelToken.source()

  return call<boolean>(
    `/api/Orders/${orderId}/status?statusId=${statusId}`,
    'PUT',
    null,
    cancelPutOrderStatusSource.token
  )
}

// PutOrderNote --------------------------------------------------

let cancelPutOrderNoteSource: CancelTokenSource

const cancelPutOrderNote = () => {
  cancelPutOrderNoteSource && cancelPutOrderNoteSource.cancel(REQUEST_CANCELLED)
}

const putOrderNote = (
  orderId: number,
  note: string | null
): Promise<AxiosResponse> => {
  cancelPutOrderNoteSource = axios.CancelToken.source()

  return call(
    `/api/Orders/${orderId}/note?note=${
      note !== null ? encodeURIComponent(note) : note
    }`,
    'PUT',
    null,
    cancelPutOrderNoteSource.token
  )
}

// GetActiveOrderCount --------------------------------------------------

let cancelGetActiveOrderCountSource: CancelTokenSource

const cancelGetActiveOrderCount = () => {
  cancelGetActiveOrderCountSource &&
    cancelGetActiveOrderCountSource.cancel(REQUEST_CANCELLED)
}

const getActiveOrderCount = (): Promise<AxiosResponse<number>> => {
  cancelGetActiveOrderCountSource = axios.CancelToken.source()

  return call<number>(
    `api/Orders/active/count`,
    'GET',
    null,
    cancelGetActiveOrderCountSource.token
  )
}

// PostOrderFilter --------------------------------------------------

let cancelPostOrderFilterSource: CancelTokenSource

const cancelPostOrderFilter = () => {
  cancelPostOrderFilterSource &&
    cancelPostOrderFilterSource.cancel(REQUEST_CANCELLED)
}

const postOrderFilter = (
  post: QueryFilter
): Promise<AxiosResponse<IOrderListPayload>> => {
  cancelPostOrderFilterSource = axios.CancelToken.source()

  return call<IOrderListPayload>(
    'api/Orders/filtered',
    'POST',
    post,
    cancelPostOrderFilterSource.token
  )
}

// PrintOrders --------------------------------------------------

let cancelPrintOrdersSource: CancelTokenSource

const cancelPrintOrders = () => {
  cancelPrintOrdersSource && cancelPrintOrdersSource.cancel(REQUEST_CANCELLED)
}

const printOrders = (post: number[]): Promise<AxiosResponse<IOrder[]>> => {
  cancelPrintOrdersSource = axios.CancelToken.source()

  return call<IOrder[]>(
    'api/Orders/print',
    'POST',
    post,
    cancelPrintOrdersSource.token
  )
}

// PostOrder --------------------------------------------------

let cancelPostOrderSource: CancelTokenSource

const cancelPostOrder = () => {
  cancelPostOrderSource && cancelPostOrderSource.cancel(REQUEST_CANCELLED)
}

const postOrder = (order: IOrder): Promise<AxiosResponse<IOrder>> => {
  cancelPostOrderSource = axios.CancelToken.source()

  return call<IOrder>(`api/Orders`, 'POST', order, cancelPostOrderSource.token)
}

// PutOrder --------------------------------------------------

let cancelPutOrderSource: CancelTokenSource

const cancelPutOrder = () => {
  cancelPutOrderSource && cancelPutOrderSource.cancel(REQUEST_CANCELLED)
}

const putOrder = (order: IOrder): Promise<AxiosResponse<IOrder>> => {
  cancelPutOrderSource = axios.CancelToken.source()

  return call<IOrder>(`api/Orders`, 'PUT', order, cancelPutOrderSource.token)
}

// GetOrganisations --------------------------------------------------

let cancelGetOrganisationsSource: CancelTokenSource

const cancelGetOrganisations = () => {
  cancelGetOrganisationsSource &&
    cancelGetOrganisationsSource.cancel(REQUEST_CANCELLED)
}

const getOrganisations = (): Promise<AxiosResponse<IOrganisation[]>> => {
  cancelGetOrganisationsSource = axios.CancelToken.source()

  return call<IOrganisation[]>(
    'api/organisations',
    'GET',
    null,
    cancelGetOrganisationsSource.token
  )
}

// GetOrganisationsById --------------------------------------------------

let cancelGetOrganisationsByIdSource: CancelTokenSource

const cancelGetOrganisationsById = () => {
  cancelGetOrganisationsByIdSource &&
    cancelGetOrganisationsByIdSource.cancel(REQUEST_CANCELLED)
}

const getOrganisationsById = (
  id: number
): Promise<AxiosResponse<IOrganisation>> => {
  cancelGetOrganisationsByIdSource = axios.CancelToken.source()

  return call<IOrganisation>(
    `api/organisations/${id}`,
    'GET',
    null,
    cancelGetOrganisationsByIdSource.token
  )
}

// PostOrganisationFilter --------------------------------------------------

let cancelPostOrganisationFilterSource: CancelTokenSource

const cancelPostOrganisationFilter = () => {
  cancelPostOrganisationFilterSource &&
    cancelPostOrganisationFilterSource.cancel(REQUEST_CANCELLED)
}

const postOrganisationFilter = (
  post: QueryFilter
): Promise<AxiosResponse<IOrganisationPayload>> => {
  cancelPostOrganisationFilterSource = axios.CancelToken.source()

  return call<IOrganisationPayload>(
    `api/Organisations/filtered`,
    'POST',
    post,
    cancelPostOrganisationFilterSource.token
  )
}

// PostOrganisation --------------------------------------------------

let cancelPostOrganisationSource: CancelTokenSource

const cancelPostOrganisation = () => {
  cancelPostOrganisationSource &&
    cancelPostOrganisationSource.cancel(REQUEST_CANCELLED)
}

const postOrganisation = (
  organisation: IOrganisation
): Promise<AxiosResponse<IOrganisation>> => {
  cancelPostOrganisationSource = axios.CancelToken.source()

  return call<IOrganisation>(
    `api/organisations`,
    'POST',
    organisation,
    cancelPostOrganisationSource.token
  )
}

// PutOrganisation --------------------------------------------------

let cancelPutOrganisationSource: CancelTokenSource

const cancelPutOrganisation = () => {
  cancelPutOrganisationSource &&
    cancelPutOrganisationSource.cancel(REQUEST_CANCELLED)
}

const putOrganisation = (
  organisation: IOrganisation
): Promise<AxiosResponse<IOrganisation>> => {
  cancelPutOrganisationSource = axios.CancelToken.source()

  return call<IOrganisation>(
    `api/organisations`,
    'PUT',
    organisation,
    cancelPutOrganisationSource.token
  )
}

// GetUsers --------------------------------------------------

let cancelGetUsersSource: CancelTokenSource

const cancelGetUsers = () => {
  cancelGetUsersSource && cancelGetUsersSource.cancel(REQUEST_CANCELLED)
}

const getUsers = (): Promise<AxiosResponse<UserModel[]>> => {
  cancelGetUsersSource = axios.CancelToken.source()
  return call<UserModel[]>('api/users', 'GET', null, cancelGetUsersSource.token)
}

// GetUserById --------------------------------------------------

let cancelGetUserByIdSource: CancelTokenSource

const cancelGetUserById = () => {
  cancelGetUserByIdSource && cancelGetUserByIdSource.cancel(REQUEST_CANCELLED)
}

const getUserById = (id: string): Promise<AxiosResponse<UserModel>> => {
  cancelGetUserByIdSource = axios.CancelToken.source()
  return call<UserModel>(
    `api/users/${id}`,
    'GET',
    null,
    cancelGetUserByIdSource.token
  )
}

// PostUserFilter --------------------------------------------------

let cancelPostUserFilterSource: CancelTokenSource

const cancelPostUserFilter = () => {
  cancelPostUserFilterSource &&
    cancelPostUserFilterSource.cancel(REQUEST_CANCELLED)
}

const postUserFilter = (
  post: QueryFilter
): Promise<AxiosResponse<IUserListPayload>> => {
  cancelPostUserFilterSource = axios.CancelToken.source()

  return call<IUserListPayload>(
    `/api/Users/filtered`,
    'POST',
    post,
    cancelPostUserFilterSource.token
  )
}

// PostUser --------------------------------------------------

let cancelPostUserSource: CancelTokenSource

const cancelPostUser = () => {
  cancelPostUserSource && cancelPostUserSource.cancel(REQUEST_CANCELLED)
}

const postUser = (
  user: CreateUserModel
): Promise<AxiosResponse<CreateUserModel>> => {
  cancelPostUserSource = axios.CancelToken.source()

  return call<CreateUserModel>(
    'api/users',
    'POST',
    user,
    cancelPostUserSource.token
  )
}

// PutUser --------------------------------------------------

let cancelPutUserSource: CancelTokenSource

const cancelPutUser = () => {
  cancelPutUserSource && cancelPutUserSource.cancel(REQUEST_CANCELLED)
}

const putUser = (
  id: string,
  user: CreateUserModel
): Promise<AxiosResponse<CreateUserModel>> => {
  cancelPutUserSource = axios.CancelToken.source()

  return call<CreateUserModel>(
    `api/users/${id}`,
    'PUT',
    user,
    cancelPutUserSource.token
  )
}

// PostLockUser --------------------------------------------------

let cancelPostLockUserSource: CancelTokenSource

const cancelPostLockUser = () => {
  cancelPostLockUserSource && cancelPostLockUserSource.cancel(REQUEST_CANCELLED)
}

const postLockUser = (
  subsId: string,
  status: number
): Promise<AxiosResponse<boolean>> => {
  cancelPostLockUserSource = axios.CancelToken.source()

  return call<boolean>(
    `/api/Users/${subsId}/lock/${status}`,
    'POST',
    null,
    cancelPostLockUserSource.token
  )
}

// UserPasswordReset --------------------------------------------------

let cancelUserPasswordResetSource: CancelTokenSource

const cancelUserPasswordReset = () => {
  cancelUserPasswordResetSource &&
    cancelUserPasswordResetSource.cancel(REQUEST_CANCELLED)
}

const userPasswordReset = (userId: string): Promise<AxiosResponse> => {
  cancelUserPasswordResetSource = axios.CancelToken.source()

  return call(
    `api/Users/${encodeURIComponent(userId)}/resetpassword`,
    'POST',
    null,
    cancelUserPasswordResetSource.token
  )
}

// GetProducts --------------------------------------------------

let cancelGetProductsSource: CancelTokenSource

const cancelGetProducts = () => {
  cancelGetProductsSource && cancelGetProductsSource.cancel(REQUEST_CANCELLED)
}

const getProducts = (): Promise<AxiosResponse<IProduct[]>> => {
  cancelGetProductsSource = axios.CancelToken.source()

  return call<IProduct[]>(
    'api/products',
    'GET',
    null,
    cancelGetProductsSource.token
  )
}

// GetProductById --------------------------------------------------

let cancelGetProductByIdSource: CancelTokenSource

const cancelGetProductById = () => {
  cancelGetProductByIdSource &&
    cancelGetProductByIdSource.cancel(REQUEST_CANCELLED)
}

const getProductById = (id: string): Promise<AxiosResponse<IProduct>> => {
  cancelGetProductByIdSource = axios.CancelToken.source()

  return call<IProduct>(
    `api/products/${id}`,
    'GET',
    null,
    cancelGetProductByIdSource.token
  )
}

// GetProductOptions --------------------------------------------------

let cancelGetProductOptionsSource: CancelTokenSource

const cancelGetProductOptions = () => {
  cancelGetProductOptionsSource &&
    cancelGetProductOptionsSource.cancel(REQUEST_CANCELLED)
}

const getProductOptions = (): Promise<AxiosResponse<IProductOptions>> => {
  cancelGetProductOptionsSource = axios.CancelToken.source()

  return call<IProductOptions>(
    `api/Products/options`,
    'GET',
    null,
    cancelGetProductOptionsSource.token
  )
}

// PostProductFilter --------------------------------------------------

let cancelPostProductFilterSource: CancelTokenSource

const cancelPostProductFilter = () => {
  cancelPostProductFilterSource &&
    cancelPostProductFilterSource.cancel(REQUEST_CANCELLED)
}

const postProductFilter = (
  post: QueryFilter
): Promise<AxiosResponse<IProductPayload>> => {
  cancelPostProductFilterSource = axios.CancelToken.source()

  return call<IProductPayload>(
    `api/Products/filtered`,
    'POST',
    post,
    cancelPostProductFilterSource.token
  )
}

// PostProduct --------------------------------------------------

let cancelPostProductSource: CancelTokenSource

const cancelPostProduct = () => {
  cancelPostProductSource && cancelPostProductSource.cancel(REQUEST_CANCELLED)
}

const postProduct = (Product: IProduct): Promise<AxiosResponse<IProduct>> => {
  cancelPostProductSource = axios.CancelToken.source()

  return call<IProduct>(
    `api/Products`,
    'POST',
    Product,
    cancelPostProductSource.token
  )
}

// PutProduct --------------------------------------------------

let cancelPutProductSource: CancelTokenSource

const cancelPutProduct = () => {
  cancelPutProductSource && cancelPutProductSource.cancel(REQUEST_CANCELLED)
}

const putProduct = (Product: IProduct): Promise<AxiosResponse<IProduct>> => {
  cancelPutProductSource = axios.CancelToken.source()

  return call<IProduct>(
    `api/Products`,
    'PUT',
    Product,
    cancelPutProductSource.token
  )
}

// PutProductStatus --------------------------------------------------

let cancelPutProductStatusSource: CancelTokenSource

const cancelPutProductStatus = () => {
  cancelPutProductStatusSource &&
    cancelPutProductStatusSource.cancel(REQUEST_CANCELLED)
}

const putProductStatus = (
  productId: number,
  productRevisionId: number,
  productRevisionStatusId: number
): Promise<AxiosResponse<boolean>> => {
  cancelPutProductStatusSource = axios.CancelToken.source()

  return call<boolean>(
    `api/Products/${productId}/productRevision/${productRevisionId}/status/${productRevisionStatusId}`,
    'PUT',
    null,
    cancelPutProductStatusSource.token
  )
}

export {
  getValues,
  cancelGetValues,
  getOrders,
  cancelGetOrders,
  getOrderById,
  cancelGetOrderById,
  getActiveOrderCount,
  cancelGetActiveOrderCount,
  postOrderFilter,
  cancelPostOrderFilter,
  printOrders,
  cancelPrintOrders,
  postOrder,
  cancelPostOrder,
  putOrder,
  cancelPutOrder,
  getStatusOptions,
  cancelGetStatusOptions,
  putOrderStatus,
  cancelPutOrderStatus,
  putOrderNote,
  cancelPutOrderNote,
  getOrganisations,
  cancelGetOrganisations,
  getOrganisationsById,
  cancelGetOrganisationsById,
  postOrganisation,
  cancelPostOrganisation,
  postOrganisationFilter,
  cancelPostOrganisationFilter,
  putOrganisation,
  cancelPutOrganisation,
  getUsers,
  cancelGetUsers,
  getUserById,
  cancelGetUserById,
  postUser,
  cancelPostUser,
  putUser,
  cancelPutUser,
  postUserFilter,
  cancelPostUserFilter,
  userPasswordReset,
  cancelUserPasswordReset,
  postLockUser,
  cancelPostLockUser,
  getProducts,
  cancelGetProducts,
  getProductById,
  cancelGetProductById,
  getProductOptions,
  cancelGetProductOptions,
  postProductFilter,
  cancelPostProductFilter,
  postProduct,
  cancelPostProduct,
  putProduct,
  cancelPutProduct,
  putProductStatus,
  cancelPutProductStatus
}

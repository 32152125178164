import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/string'
import 'core-js/features/array'
import 'core-js/features/object'
import 'core-js/modules/es.promise'
import 'core-js/modules/es.promise.finally'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { unregister } from './serviceWorker'
import App from './App'
import { AppContextProvider } from './context'
import { isIE11OrLess } from './components/utility/browserTypeDetection'
import { ASMinSpecWarning } from './components/ASMinSpecWarning'
import { LogIn } from './pages/LogIn'
import { ASPrivateRoute } from './components/ASPrivateRoute'

const isBelowMinBrowserRequirements = isIE11OrLess()

const redirectUrl = window.location.href
ReactDOM.render(
  <AppContextProvider>
    <>
      {isBelowMinBrowserRequirements ? (
        <Router>
          <ASMinSpecWarning />
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route
              path={['/', '/login']}
              exact
              render={() => <LogIn redirectUrl={redirectUrl} />}
            />
            <ASPrivateRoute exact component={App} />
          </Switch>
        </Router>
      )}
    </>
  </AppContextProvider>,
  document.getElementById('root')
)

unregister()

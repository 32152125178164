import React, { FunctionComponent, useContext } from 'react'
import {
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  Button,
  Typography,
  Box,
  IconButton
} from '@material-ui/core'
import { ASProductsSelectTableContainer } from '../ASProductsSelectTable'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { AppContext } from '../../context'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflowX: 'auto',
    height: '100%',
    paddingTop: theme.spacing(6)
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 4, 3, 4),

    '&:focus': {
      outline: 'none'
    }
  }
}))

interface IASProductsModalProps {
  isOpen?: boolean
}

export const ASProductsModal: FunctionComponent<IASProductsModalProps> = ({
  isOpen = false
}) => {
  const { activeOrder } = useContext(AppContext)
  const classes = useStyles()
  const [open, setOpen] = React.useState<boolean>(isOpen)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        disabled={activeOrder && activeOrder?.orderLines.length >= 5}
        className="find-products-button"
        disableElevation={true}
        variant="contained"
        onClick={handleOpen}
      >
        Find Products
        <Box marginLeft="5px" display="flex">
          <SearchIcon fontSize="small" color="inherit" />
        </Box>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`products-search-modal ${classes.modal}`}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box
              mb={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              minWidth="480px"
            >
              <Typography
                color="primary"
                variant="h4"
                style={{ marginBottom: 0 }}
              >
                Products
              </Typography>
              <IconButton className="close-modal" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
            <ASProductsSelectTableContainer cancelRequest={handleClose} />
          </div>
        </Fade>
      </Modal>
    </>
  )
}

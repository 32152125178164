import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react'
import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
  Typography,
  Box,
  TableCell,
  TableRow,
  TableHead,
  Table,
  InputAdornment
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import InfoIcon from '@material-ui/icons/Info'
import { IOrder, OrderLineModelExtended } from '../../../../models'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { ASStatus } from '../../../../components/ASStatus'
import { findOrderStatusTypeByNumber } from '../../../../types/StatusTypes'
import { ASProductsModal } from '../../../../components/ASProductsModal'
import { AppContext } from '../../../../context/AppContext'
import { setActiveOrder } from '../../../../features'
import {
  OrderLineModel,
  ProductRouteModel
} from '../../../../models/swaggerTypesModified'
import { ASCancelConfirmationModal } from '../../../../components/ASCancelConfirmationModal'
import { object, string, boolean, number, array } from 'yup'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment'
import {
  ASTableBody,
  ASTableContainerOverflow
} from '../../../../components/ASTable'
import TimeField from 'react-simple-timefield'

interface IOrderDetailProps {
  /* The order passed down into the detail */
  order?: IOrder
  /* Whether the order is being new or not */
  isNewOrder?: boolean
  /* Whether the order uses details from a previous order and posted as new */
  isRepeat?: boolean
  /* Values for the vehicle dropdown within the products table */
  unitOfMeasureOptions?: ProductRouteModel[]
  /* passes sumbmission object to parent to handle */
  submitOrder: (org: IOrder, inProgress: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

const ASHeaderCell = withStyles((theme) => ({
  root: {
    border: 'none',
    lineHeight: 'inherit',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
  },
  head: {
    backgroundColor: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    border: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const ASTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    minWidth: '100px'
  }
}))(TableCell)

const ASTableCellRemove = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  }
}))(TableCell)

const ASHeaderTableCellRemove = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: theme.spacing(2)
  },
  head: {
    backgroundColor: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    border: 'none'
  }
}))(TableCell)

const ASTableInputRow = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  }
}))(TableRow)

const blankOrder: any = {
  id: 0,
  uid: '',
  organisationId: 0,
  patientReference: '',
  patientName: '',
  patientNhsNumber: '',
  hospitalNumber: '',
  waitingBloods: false,
  callBeforeCompounding: false,
  orderedByUserId: 0,
  contactId: 0,
  orderCreateDate: '2020-07-29T10:13:15.355Z',
  deliveredDate: '2020-07-29T10:13:15.355Z',
  recentExternalNote: null,
  recentInternalNote: null,
  requiredDate: '',
  patientTreatmentStartDate: '',
  patientTreatmentStartTime: '00:00',
  note: '',
  orderNotes: [],
  orderLines: [],
  orderStatus: {
    id: 1,
    description: ''
  }
}

const validateStartEndTime = (value: string) => {
  const format = 'HH:mm',
    time = moment(`${value}`, format),
    afterTime = moment(`08:59`, format),
    beforeTime = moment('18:01', format)
  return time.isBetween(afterTime, beforeTime)
}

const errorMessage = {
  dropdown: 'Please select an option',
  required: 'Required field',
  concentration: 'Value required',
  minValue: 'Minimum of 1 item required',
  whiteSpace: 'Please remove white space'
}

const orderSchema = object().shape({
  orderId: number(),
  patientReference: string().trim().required(errorMessage.required),
  patientName: string().trim().required(errorMessage.required),
  hospitalNumber: string().trim().required(errorMessage.required),
  waitingBloods: boolean(),
  callBeforeCompounding: boolean(),
  note: string()
    .trim()
    .test(
      'len',
      'Please use less than 350 characters',
      (val) => val.length < 351
    ),
  orderCreateDate: string().trim(),
  deliveredDate: string().trim(),
  requiredDate: string().trim().required(errorMessage.required),
  patientTreatmentStartDate: string().required(errorMessage.required),
  patientTreatmentStartTime: string().test({
    name: 'time validation',
    message: 'Set a value between 9.00am and 6.00pm',
    test: (value) => validateStartEndTime(value)
  }),
  orderLines: array()
    .compact()
    .min(1)
    .of(
      object().shape({
        productRevisionId: number(),
        quantity: number().min(1, errorMessage.minValue),
        dose: string().trim().required(errorMessage.required),
        volume: string().trim().required(errorMessage.required)
        // unitOfMeasurement: number().min(1, errorMessage.minValue)
      })
    )
})

export const OrderDetailForm: FunctionComponent<IOrderDetailProps> = ({
  order = blankOrder,
  isNewOrder = false,
  isRepeat = false,
  unitOfMeasureOptions,
  submitOrder
}) => {
  const { dispatch, activeOrder } = useContext(AppContext)
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [products, setProducts] = useState<OrderLineModel[]>(order.orderLines)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false)
  const [timeIsSet, setTimeIsSet] = useState<boolean>(!isNewOrder)

  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
    control,
    setValue,
    unregister
  } = useForm<IOrder>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: order,
    resolver: yupResolver(orderSchema)
  })

  if (order && isRepeat) {
    order.recentExternalNote = null
    order.recentInternalNote = null
    order.patientReference = ''
    order.patientTreatmentStartDate = ''
    order.requiredDate = ''
  }

  const recentNote = order.recentExternalNote?.text
  const initialNote = recentNote === null ? '' : recentNote
  const dirtyFields = Object.entries(formState.dirtyFields).length !== 0
  const watchTreatmentStartDate: string = watch('patientTreatmentStartDate')
  const watchTreatmentStartTime: string =
    watch('patientTreatmentStartTime') || ''
  const watchRequiredDate: string = watch('requiredDate')
  const watchNote: string = watch('note') || ''
  const watchNoteLength = watchNote ? watchNote.split('').length : 0
  const watchAllFields = watch()

  const handleCancel = (formState: boolean) => {
    if (formState) {
      setConfirmCancelModal(true)
    } else {
      history.push('/orders')
    }
  }

  const handleReject = () => {
    setConfirmCancelModal(false)
  }

  const submitAndProcessing = (order: IOrder) => {
    if (products && products.length > 0) {
      submitOrder(order, inProgress)
    }
  }

  const formatDateForPost = useCallback(
    (value: string, fieldName: string) => {
      const format =
        watchTreatmentStartTime !== '00:00'
          ? moment(new Date(value)).format('YYYY-MM-DDTHH:mm')
          : `${moment(new Date(value)).format('YYYY-MM-DD')}T00:00`

      setValue(fieldName, format)
    },
    [setValue, watchTreatmentStartTime]
  )

  const handleTimeChange = (time: string) => {
    setValue(
      'patientTreatmentStartDate',
      `${moment(new Date(watchTreatmentStartDate)).format(
        'YYYY-MM-DD'
      )}T${time}`,
      {
        shouldDirty: true,
        shouldValidate: true
      }
    )
    setValue('patientTreatmentStartTime', time, {
      shouldDirty: true,
      shouldValidate: true
    })
  }
  useEffect(() => {
    watchTreatmentStartDate &&
      formatDateForPost(
        watchTreatmentStartDate.toString(),
        'patientTreatmentStartDate'
      )
  }, [watchTreatmentStartDate, formatDateForPost])

  useEffect(() => {
    watchRequiredDate &&
      formatDateForPost(watchRequiredDate.toString(), 'requiredDate')
  }, [watchRequiredDate, formatDateForPost])

  const handleProductRemoval = (productIndex: number) => {
    const updatedProducts: any =
      products && products.filter((item, index) => index !== productIndex)
    unregister([
      `orderLines[${products.length - 1}].dose`,
      `orderLines[${products.length - 1}].volume`,
      `orderLines[${products.length - 1}].quantity`
    ])
    setProducts([...updatedProducts])
    const updatedOrder = { ...order }
    updatedOrder.orderLines = [...(updatedProducts || [])]
    setActiveOrder(dispatch, updatedOrder)
  }

  const handleProductUpdate = (
    productIndex: number,
    propertyName: string,
    value: string | number
  ) => {
    const updatedProducts =
      products &&
      products.map((item, index) => {
        if (index === productIndex) {
          item[propertyName] = value
        }
        return item
      })
    setProducts([...updatedProducts])
  }

  useEffect(() => {
    activeOrder?.orderLines && setProducts(activeOrder?.orderLines)
  }, [dispatch, activeOrder])

  useEffect(() => {
    watchAllFields &&
      products.forEach((item: OrderLineModelExtended, index): void => {
        register({ name: `orderLines[${index}].productRevisionId` })
        setValue(
          `orderLines[${index}].productRevisionId`,
          item.productRevisionId,
          {
            shouldDirty: true
          }
        )

        register({ name: `orderLines[${index}].dose` })
        setValue(`orderLines[${index}].dose`, item.dose, {
          shouldDirty: true
        })

        register({ name: `orderLines[${index}].volume` })
        setValue(`orderLines[${index}].volume`, item.volume, {
          shouldDirty: true
        })

        register({ name: `orderLines[${index}].quantity` })
        setValue(`orderLines[${index}].quantity`, item.quantity, {
          shouldDirty: true
        })
      })
  }, [dispatch, products, register, setValue])

  useEffect(() => {
    setValue(
      'patientTreatmentStartTime',
      `${moment(new Date(order.patientTreatmentStartDate)).format('HH:mm')}`,
      {
        shouldDirty: true
      }
    )
  }, [])

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={theme.breakpoints.values.lg * 0.75}
      marginX="auto"
      mb={theme.spacing(1)}
    >
      <ASCancelConfirmationModal
        open={confirmCancelModal}
        reject={handleReject}
      />
      <Grid container>
        <Grid item>
          <form onSubmit={handleSubmit(submitAndProcessing)}>
            <div style={{ display: 'none' }}>
              <TextField
                name="orderId"
                inputRef={register({})}
                value={isNewOrder || isRepeat ? 0 : order.id}
                error={errors.id?.message ? true : false}
                helperText={errors.id?.message}
              />
              <TextField name="orderCreateDate" inputRef={register({})} />
              <TextField name="deliveredDate" inputRef={register({})} />
              <TextField
                name="waitingBloods"
                inputRef={register({})}
                value={false}
              />
            </div>
            <Button
              onClick={() => handleCancel(dirtyFields)}
              startIcon={<LeftIcon />}
            >
              Go Back
            </Button>

            <Box pt={2}>
              <Box mb={3}>
                <Grid container spacing={3} justify="space-between">
                  <Grid item>
                    {!isNewOrder && (
                      <Typography variant="h3" color="primary">
                        Order {order.id}
                      </Typography>
                    )}
                    {isNewOrder && (
                      <Typography variant="h3" color="primary">
                        New Order
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {!isNewOrder && (
                      <ASStatus
                        status={
                          findOrderStatusTypeByNumber[order.orderStatus.id]
                        }
                      />
                    )}
                    {isNewOrder && (
                      <ASStatus status={findOrderStatusTypeByNumber[1]} />
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box
                bgcolor={theme.palette.text.hint}
                borderRadius={3}
                p={2}
                mb={2}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="patientReference"
                      inputRef={register({})}
                      variant="outlined"
                      label="Your reference"
                      fullWidth
                      error={errors.patientReference?.message ? true : false}
                      helperText={errors.patientReference?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Patient name"
                      name="patientName"
                      inputRef={register({})}
                      variant="outlined"
                      fullWidth
                      error={errors.patientName?.message ? true : false}
                      helperText={errors.patientName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Hospital number / NHS number"
                      name="hospitalNumber"
                      inputRef={register({})}
                      variant="outlined"
                      fullWidth
                      error={errors.hospitalNumber?.message ? true : false}
                      helperText={errors.hospitalNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="callBeforeCompounding"
                      control={control}
                      render={({ onChange, value }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(value) =>
                                onChange(value.currentTarget.checked)
                              }
                              checked={value}
                            />
                          }
                          label="Call before compounding"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2} mt={3}>
                <Typography variant="h5" color="primary">
                  Delivery
                </Typography>
                <Box
                  mt={2}
                  style={{
                    display: 'inline-flex'
                  }}
                >
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <InfoIcon fontSize="small" />
                  </span>
                  Delivery address will be automatically set to your
                  organisation address.
                </Box>
              </Box>
              <Box
                bgcolor={theme.palette.text.hint}
                borderRadius={3}
                p={2}
                mb={2}
              >
                <Grid style={{ flexGrow: 1 }} container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        name="patientTreatmentStartDate"
                        as={
                          <DatePicker
                            variant="inline"
                            label="Treatment start date"
                            margin="none"
                            fullWidth={true}
                            format="dd-MM-yyyy"
                            onChange={() => {}}
                            value={order.patientTreatmentStartTime}
                            error={
                              errors.patientTreatmentStartDate?.message
                                ? true
                                : false
                            }
                            helperText={
                              errors.patientTreatmentStartDate?.message
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon fontSize="small" />
                                </InputAdornment>
                              )
                            }}
                          />
                        }
                        control={control}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="patientTreatmentStartTime"
                      inputRef={register({})}
                      style={{ display: 'none' }}
                    />
                    <TimeField
                      onChange={(e, value) => (
                        handleTimeChange(value), setTimeIsSet(true)
                      )}
                      value={
                        (timeIsSet && watchTreatmentStartDate
                          ? moment(watchTreatmentStartDate).format('HH:mm')
                          : null) || '09:00'
                      }
                      style={{ width: '100%' }}
                      input={
                        <TextField
                          label="Treatment start time"
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true
                          }}
                          color="primary"
                          error={
                            errors.patientTreatmentStartTime?.message
                              ? true
                              : false
                          }
                          helperText={errors.patientTreatmentStartTime?.message}
                          disabled={watchTreatmentStartDate === ''}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon fontSize="small" />
                              </InputAdornment>
                            )
                          }}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        name="requiredDate"
                        as={
                          <DatePicker
                            variant="inline"
                            label="Required date"
                            format="dd-MM-yyyy"
                            margin="none"
                            fullWidth={true}
                            value={order.requiredDate}
                            onChange={() => {}}
                            error={errors.requiredDate?.message ? true : false}
                            helperText={errors.requiredDate?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon fontSize="small" />
                                </InputAdornment>
                              )
                            }}
                          />
                        }
                        control={control}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="note"
                      inputRef={register({})}
                      fullWidth
                      label={`Notes ${
                        watchNoteLength > 0
                          ? watchNoteLength > 1
                            ? `(${watchNoteLength} characters)`
                            : `(${watchNoteLength} character)`
                          : ''
                      }`}
                      defaultValue={initialNote}
                      helperText="Please provide a maximum of 350 characters"
                      variant="outlined"
                      placeholder="Enter notes here"
                      multiline
                      rows={2}
                      rowsMax={4}
                      inputProps={{ maxLength: 350 }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3} mt={4}>
                <Box mb={2} mt={3}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Box display="flex" alignContent="flex-end">
                        <Typography variant="h5" color="primary">
                          Products
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignContent="center"
                      >
                        <ASProductsModal />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ overflow: 'visible!important' }}
                >
                  <ASTableContainerOverflow>
                    <Table size="small" aria-label="products table">
                      <TableHead>
                        <ASTableInputRow>
                          <ASHeaderCell>Product</ASHeaderCell>
                          <ASHeaderCell>Dose</ASHeaderCell>
                          <ASHeaderCell>Vehicle</ASHeaderCell>
                          <ASHeaderCell>Volume</ASHeaderCell>
                          <ASHeaderCell>Route</ASHeaderCell>
                          <ASHeaderCell>Container</ASHeaderCell>
                          <ASHeaderCell>Qty</ASHeaderCell>
                          <ASHeaderTableCellRemove></ASHeaderTableCellRemove>
                        </ASTableInputRow>
                      </TableHead>
                      <ASTableBody>
                        {products &&
                          products.map((item, index: number) => {
                            return (
                              <ASTableInputRow key={`product-row-${index}`}>
                                <ASTableCell component="th" scope="row">
                                  <TextField
                                    inputRef={register({})}
                                    style={{ display: 'none' }}
                                    name={`orderLines[${index}].productName`}
                                    value={item.productName}
                                  />
                                  <TextField
                                    style={{ display: 'none' }}
                                    name={`orderLines[${index}].productRevisionId`}
                                    value={item.productRevisionId}
                                    inputRef={register({})}
                                    type="number"
                                  />
                                  {item.productName}
                                </ASTableCell>

                                {unitOfMeasureOptions && (
                                  <ASTableCell>
                                    <TextField
                                      style={{ display: 'none' }}
                                      name={`orderLines[${index}].unitOfMeasurement`}
                                      inputRef={register({})}
                                      type="number"
                                    />
                                    <TextField
                                      onChange={(event) =>
                                        handleProductUpdate(
                                          index,
                                          'dose',
                                          event.target.value
                                        )
                                      }
                                      value={item.dose}
                                      error={
                                        errors?.orderLines &&
                                        errors?.orderLines[index]?.dose
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors?.orderLines &&
                                        errors?.orderLines[index]?.dose?.message
                                      }
                                    ></TextField>
                                  </ASTableCell>
                                )}
                                <ASTableCell>
                                  {item.productRevision.vehicle.description}
                                </ASTableCell>

                                <ASTableCell>
                                  <TextField
                                    onChange={(event) =>
                                      handleProductUpdate(
                                        index,
                                        'volume',
                                        event.target.value
                                      )
                                    }
                                    value={item.volume}
                                    error={
                                      errors?.orderLines &&
                                      errors?.orderLines[index]?.volume
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.orderLines &&
                                      errors?.orderLines[index]?.volume?.message
                                    }
                                  ></TextField>
                                </ASTableCell>
                                <ASTableCell>
                                  {
                                    item.productRevision.productRoute
                                      .description
                                  }
                                </ASTableCell>
                                <ASTableCell>
                                  {item.productRevision.container.description}
                                </ASTableCell>
                                <ASTableCell>
                                  <TextField
                                    type="number"
                                    onChange={(event) =>
                                      handleProductUpdate(
                                        index,
                                        'quantity',
                                        event.target.value
                                      )
                                    }
                                    value={item.quantity}
                                    error={
                                      errors?.orderLines &&
                                      errors?.orderLines[index]?.quantity
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.orderLines &&
                                      errors?.orderLines[index]?.quantity
                                        ?.message
                                    }
                                  ></TextField>
                                </ASTableCell>
                                <ASTableCellRemove
                                  onClick={() => {
                                    handleProductRemoval(index)
                                  }}
                                >
                                  Remove
                                </ASTableCellRemove>
                              </ASTableInputRow>
                            )
                          })}
                      </ASTableBody>
                    </Table>
                  </ASTableContainerOverflow>
                  <Box mt={2}>
                    {formState.isSubmitted && products.length === 0 && (
                      <Typography
                        color="error"
                        component="div"
                        style={{ fontSize: 12 }}
                      >
                        At least one product is required
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Box>

              <Grid
                style={{ flexGrow: 1, justifyContent: 'flex-end' }}
                container
                spacing={3}
              >
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={() =>
                      handleCancel(
                        Object.entries(formState.dirtyFields).length !== 0
                      )
                    }
                    disableElevation={true}
                    variant="outlined"
                  >
                    GO BACK
                  </Button>
                  <Button
                    type="submit"
                    disableElevation={true}
                    variant="contained"
                    color={
                      formState.touched &&
                      formState.isSubmitted &&
                      activeOrder?.orderLines.length !== 0 &&
                      formState.dirtyFields.orderLines
                        ? 'default'
                        : 'primary'
                    }
                  >
                    Save & Close
                  </Button>

                  <Button
                    onClick={() => setInProgress(true)}
                    disableElevation={true}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Place Order
                  </Button>
                </div>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(formState, null, 2)}</pre>
      <pre>{JSON.stringify(watchAllFields, null, 2)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre> */}
    </Box>
  )
}

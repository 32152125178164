import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, Snackbar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IProduct, IError, IProductOptions, ISuccess } from '../../../models'
import {
  getProductById,
  getProductOptions,
  putProduct,
  putProductStatus
} from '../../../services/ApiService'
import { ProductDetail } from './ProductDetail'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { TProductStatus, findProductStatusNumber } from '../../../types'

interface IProductRouteParams {
  /** The id of this user passed through via the route params */
  id: string
}

interface IProductDetailContainerProps
  extends RouteComponentProps<IProductRouteParams> {}

export const ProductDetailContainer: FunctionComponent<IProductDetailContainerProps> = ({
  match
}) => {
  const [product, setProduct] = useState<IProduct | undefined>(undefined)
  const [options, setOptions] = useState<IProductOptions | undefined>(undefined)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isRequestingStatus, setIsRequestingStatus] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [success, setSuccess] = useState<ISuccess>({
    hasSuccess: false
  })
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()

  const { id } = match.params
  const handleEditRequest = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      getProductById(id)
        .then((response) => {
          setProduct(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to load product'
          })
        })
      getProductOptions()
        .then((response) => {
          setOptions(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to load product option values'
          })
        })
    }
  }, [match.params, id])

  const handleSubmission = (product: IProduct) => {
    putProduct(product)
      .then(() => {
        history.goBack()
      })
      .catch((error) => {
        console.log(error)
        setError({
          hasError: true,
          errorMessage: 'failed to update existing product'
        })
      })
  }

  const handleStatusChangeRequest = (value: TProductStatus) => {
    if (product) {
      setIsRequestingStatus(true)
      const productRevision =
        product.productRevisions[product.productRevisions.length - 1]
      const productStatusNumber = findProductStatusNumber[value]

      putProductStatus(product.id, productRevision.id, productStatusNumber)
        .then((response) => {
          response.data === true &&
            setSuccess({
              hasSuccess: true,
              successMessage: `The status has been updated successfully`
            })
          setIsRequestingStatus(false)
        })
        .then(() => {
          getProductById(id)
            .then((response) => {
              setProduct(response.data)
              setIsLoading(false)
            })
            .catch((error) => {
              console.log(error)
              setError({
                hasError: true,
                errorMessage: 'failed to load product'
              })
            })
        })
        .catch((error) => {
          console.log(error)
          setError({
            hasError: true,
            errorMessage: 'failed to modify user status'
          })
        })
    }
  }

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
    setSuccess({ hasSuccess: false, successMessage: '' })
  }

  return (
    <>
      {id && id !== 'new' && (
        <>
          {isLoading && (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}

          {!isLoading && product && (
            <ProductDetail
              handleEditRequest={handleEditRequest}
              product={product}
              options={options}
              isEditing={isEditing}
              isRequestingStatus={isRequestingStatus}
              submitProduct={handleSubmission}
              requestStatusChange={handleStatusChangeRequest}
            />
          )}

          {success.hasSuccess && (
            <Snackbar
              open={success.hasSuccess}
              autoHideDuration={3000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="success">
                {success.successMessage}
              </Alert>
            </Snackbar>
          )}

          {error.hasError && (
            <Snackbar
              open={error.hasError}
              autoHideDuration={4000}
              onClose={handleNotificationClose}
            >
              <Alert onClose={handleNotificationClose} severity="error">
                {error.errorMessage}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  )
}

import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import { ITableHeader, IProduct } from '../../../models'
import { TSortDirection } from '../../../types/SortTypes'
import { ASAdminTable } from '../../../components/ASAdminTables'

interface IProductsProps {
  // Requires an array of products
  products: IProduct[]
  // Table header values are provided by its parent including sort direction state
  tableHeaders: ITableHeader[]
  // Provides the parent with an updated table header which includes a change sort direction
  requestSort: (columnName: string, sortDirection: TSortDirection) => void
  // Provides the parent updated table headers that include search values
  requestSearch: (tableHeaders: ITableHeader[]) => void
}
export const Products: FunctionComponent<IProductsProps> = ({
  products,
  tableHeaders,
  requestSort,
  requestSearch
}) => {
  return (
    <>
      <Box mt={3} mb={3} className="products-list">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item lg={7}>
            <Typography variant="h2" color="primary">
              Products
            </Typography>
          </Grid>
          <Grid item lg={5}>
            <Box display="flex" justifyContent="flex-end" alignContent="center">
              <Button
                disableElevation={true}
                color="primary"
                variant="contained"
                component={Link}
                to="products/new"
              >
                Add Product
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ASAdminTable
        tableHeaders={tableHeaders}
        requestSort={(columnName, sortDirection) =>
          requestSort(columnName, sortDirection)
        }
        requestSearch={(tableHeaders) => requestSearch(tableHeaders)}
        collection={products}
        itemPath="products"
        tableWidth="2072px"
      />
    </>
  )
}

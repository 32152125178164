import { FunctionComponent, useContext } from 'react'
import React from 'react'
import {
  makeStyles,
  List,
  ListItem,
  withStyles,
  useTheme,
  darken
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'

export const MenuList = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignSelf: 'stretch',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))(List)

export const MenuListItem = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(ListItem)

interface IASHorizonalMenuLinkProps {
  to: string
  text: string
}

export const ASHorizonalMenuLink: FunctionComponent<IASHorizonalMenuLinkProps> = ({
  to,
  text
}) => {
  const { adminState } = useContext(AppContext)
  const theme = useTheme()
  const useStyles = makeStyles({
    navlink: {
      display: 'flex',
      alignItems: 'center',
      color: adminState
        ? theme.palette.common.white
        : theme.palette.text.primary,
      textDecoration: 'none',
      height: '100%',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      transition: 'border-bottom 0.3s',

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2)
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(4)
      },

      '&:after': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        content: `''`,
        width: '100%',
        height: 0,
        background: adminState
          ? theme.palette.common.white
          : theme.palette.primary.main,
        transition: 'all 0.2s'
      },

      '&:hover, &.is-active': {
        backgroundColor: darken(theme.palette.primary.dark, 0.1),
        '&:after': {
          height: '3px'
        }
      },
      '&.is-active': {
        color: adminState
          ? theme.palette.common.white
          : theme.palette.primary.main
      }
    }
  })

  const classes = useStyles()

  return (
    <MenuListItem button>
      <NavLink
        className={`nav-link ${classes.navlink}`}
        activeClassName="is-active"
        to={to}
      >
        <span>{text}</span>
      </NavLink>
    </MenuListItem>
  )
}

export const ASHorizonalMenu: FunctionComponent = ({ children }) => {
  return <MenuList>{children}</MenuList>
}

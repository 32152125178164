import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Checkbox,
  createStyles,
  Grid,
  TextField,
  Theme,
  Typography,
  Box,
  Button,
  FormControlLabel
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import { IOrder, OrderStatusModel } from '../../../../models'
import { useForm, Controller } from 'react-hook-form'
import { findOrderStatusTypeByNumber, TOrderStatus } from '../../../../types'
import { ASStatus } from '../../../../components/ASStatus'
import { ASStatusButtonGroup } from '../../../../components/ASStatusButtonGroup'
import { ASProductsTable } from '../../../../components/ASProductsTable'
import moment from 'moment'

interface IAdminOrderDetailProps {
  order: IOrder
  orderStatusOptions: OrderStatusModel[]
  isRequestingStatus?: boolean
  submitOrder: (note: string | '') => void
  requestStatusChange?: (item: TOrderStatus) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    solidHightLightFormField: {
      '& label.MuiFormLabel-root': {
        color: theme.palette.text.primary
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.text.primary
      }
    },
    buttonContainer: {
      zIndex: 1,
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    table: {
      maxHeight: 300
    }
  })
)

export const AdminOrderDetail: FunctionComponent<IAdminOrderDetailProps> = ({
  order,
  orderStatusOptions,
  isRequestingStatus,
  submitOrder,
  requestStatusChange
}) => {
  const adminInternalNote = order?.recentInternalNote?.text || null
  const adminExternalNote = order?.recentExternalNote?.text || ''
  const [note, setNote] = useState<string | null>(adminInternalNote)
  const classes = useStyles()
  const theme = useTheme()
  const { register, control } = useForm<IOrder>({
    defaultValues: order
  })

  const notesUpdated = note !== adminInternalNote

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      maxWidth={theme.breakpoints.values.md}
      marginX="auto"
      mb={theme.spacing(1)}
    >
      <Box mb={2}>
        <Grid container={true} justify="space-between" alignItems="center">
          <Grid item={true}>
            <Button component={Link} startIcon={<LeftIcon />} to="/orders">
              Go Back
            </Button>
          </Grid>
          <Grid item={true}>
            {order.orderStatus.id && (
              <ASStatus
                status={findOrderStatusTypeByNumber[order.orderStatus.id]}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box pl={0}>
        <Box mb={4} position="relative" zIndex={1}>
          <Grid container={true} justify="space-between" alignItems="center">
            <Grid item={true}>
              <Typography variant="h3" color="primary">
                Order detail
              </Typography>
            </Grid>

            <Grid item={true}>
              <div className={classes.buttonContainer}>
                {orderStatusOptions.length > 0 && (
                  <ASStatusButtonGroup
                    isRequestingStatus={isRequestingStatus}
                    handleSelectedOption={(status: TOrderStatus) =>
                      requestStatusChange && requestStatusChange(status)
                    }
                    selectedStatus={
                      findOrderStatusTypeByNumber[order.orderStatus.id]
                    }
                    statusOptions={orderStatusOptions}
                  />
                )}

                <Button
                  disabled={!notesUpdated}
                  disableElevation={true}
                  variant="contained"
                  color={!notesUpdated ? 'default' : 'primary'}
                  onClick={() => submitOrder(note === null ? '' : note)}
                >
                  update order
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid
        container={true}
        spacing={3}
        style={{ zIndex: 0, position: 'relative' }}
      >
        <Grid item={true} xs={12}>
          <TextField
            fullWidth={true}
            onChange={(event) => setNote(event.currentTarget.value)}
            value={note}
            label="Internal Note"
            variant="outlined"
            placeholder="Enter notes here"
            multiline={true}
            rows={1}
          />
        </Grid>
      </Grid>
      {order.orderLines && order.orderLines.length > 0 && (
        <Grid sm={12} item={true}>
          <Box mb={2} mt={3}>
            <Typography variant="h4" color="primary">
              Products
            </Typography>
          </Box>
          <Box mb={4}>
            <ASProductsTable products={order.orderLines} />
          </Box>
        </Grid>
      )}
      <Box>
        <form>
          <Grid container={true} spacing={2}>
            <Grid sm={12} md={6} item={true}>
              <Box
                bgcolor={theme.palette.text.hint}
                borderRadius={3}
                p={2}
                height="100%"
              >
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Box mb={2}>
                      <TextField
                        name="id"
                        label="Online Order"
                        inputRef={register({})}
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="orderStatus.description"
                        inputRef={register({})}
                        label="Current Status"
                        variant="outlined"
                        value={
                          findOrderStatusTypeByNumber[order.orderStatus.id]
                        }
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="submitDate"
                        label="Date Submitted"
                        value={
                          order.submitDate
                            ? moment(order.submitDate).format(
                                'DD-MM-YYYY HH:mm'
                              )
                            : ' '
                        }
                        variant="outlined"
                        disabled={true}
                        fullWidth={true}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        name="id"
                        label="Customer Order"
                        inputRef={register({})}
                        variant="outlined"
                        disabled={true}
                        fullWidth={true}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        label="Patient name"
                        name="patientName"
                        variant="outlined"
                        inputRef={register({
                          required: true,
                          maxLength: 20,
                          minLength: 3
                        })}
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>

                    <Box mb={2}>
                      <Controller
                        control={control}
                        name="callBeforeCompounding"
                        label="Call before compounding"
                        inputRef={register({})}
                        as={
                          <FormControlLabel
                            control={<Checkbox name="callBeforeCompounding" />}
                            label="Call before compounding"
                            checked={order.callBeforeCompounding}
                          />
                        }
                        disabled={true}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        label="Date required"
                        name="patientName"
                        variant="outlined"
                        value={moment(order.requiredDate).format(
                          'ddd DD-MMM-YYYY'
                        )}
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        label="Patient Treatment Date and Time"
                        name="patientName"
                        variant="outlined"
                        value={moment(order.patientTreatmentStartDate).format(
                          'ddd DD-MMM-YYYY HH:mm'
                        )}
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid sm={12} md={6} item={true}>
              <Box
                borderColor={theme.palette.grey[200]}
                border={1}
                borderRadius={3}
                p={2}
                height="100%"
              >
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.addressLine1"
                        inputRef={register({})}
                        label="Address Line 1"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.addressLine2"
                        label="Address Line 2"
                        inputRef={register({})}
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.addressLine3"
                        inputRef={register({})}
                        label="Address Line 3"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.town"
                        inputRef={register({})}
                        label="Town"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.county"
                        inputRef={register({})}
                        label="County"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.postCode"
                        inputRef={register({})}
                        label="Post Code"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="organisation.contact[0].address.phoneNumber"
                        label="Phone Number"
                        inputRef={register({})}
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                        type="number"
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="orderedByUser.username"
                        label="Person Ordering"
                        inputRef={register({})}
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    </Box>
                    <TextField
                      fullWidth={true}
                      label="Customer Note"
                      variant="outlined"
                      value={adminExternalNote}
                      placeholder="Enter notes here"
                      multiline={true}
                      rows={5}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  ITableHeader,
  IOrganisationPayload,
  QueryFilter,
  Filter,
  IOrganisation,
  IError
} from '../../../models'
import { postOrganisationFilter } from '../../../services/ApiService'
import { TSortDirection, SortDirectionType } from '../../../types/SortTypes'
import { OrganisationsList } from './OrganisationsList'
import { useDebounce } from '../../../components/utility/utility'
import { capitalizeFirstLetter, updateSearch } from '../../../utility'
import { TablePagination, Grid, Box, Snackbar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isArray } from 'util'
import { InitialState, ColWidthsType } from '../../../constants'
import { Alert } from '@material-ui/lab'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'

export const OrganisationHeaders: ITableHeader[] = [
  {
    name: 'id',
    displayName: 'Reference',
    sortDirection: SortDirectionType.None,
    type: 'number',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.SM
  },
  {
    name: 'name',
    displayName: 'Name',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.XL
  },
  {
    name: 'headerAddress',
    displayName: 'Address',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'medium',
    searchValue: '',
    searchable: true,
    colWidth: ColWidthsType.XL
  },
  {
    name: 'lastUpdated',
    displayName: 'Last Updated',
    sortDirection: SortDirectionType.None,
    type: 'date',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.MD
  },
  {
    name: 'updatedBy',
    displayName: 'Updated By',
    sortDirection: SortDirectionType.None,
    type: 'text',
    size: 'small',
    searchValue: '',
    searchable: false,
    colWidth: ColWidthsType.MD
  }
]

export const OrganisationsContainer: FunctionComponent = () => {
  const [orgState, setOrgState] = useState<IOrganisationPayload>()
  const [tableHeaders, setTableHeaders] = useState<ITableHeader[]>(
    OrganisationHeaders
  )
  const [pageState, setPageState] = useState<QueryFilter>(InitialState)
  const [searchQuery, setSearchQuery] = useState<Filter | undefined>()
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleSearch = (searchQuery: Filter) => {
    setPageState(updateSearch(pageState, tableHeaders, searchQuery))
  }

  const debounceUpdateSearchQuery = useDebounce<Filter[]>(handleSearch, 200)

  const handleSearchRequest = (tableHeaders: ITableHeader[]) => {
    setTableHeaders(tableHeaders)
  }
  useEffect(() => searchQuery && debounceUpdateSearchQuery(searchQuery), [
    searchQuery
  ])

  const handleSortRequest = (columnName: string, sortDir: TSortDirection) => {
    setTableHeaders(
      tableHeaders.map((item) => {
        if (item.name === columnName) {
          item.sortDirection = sortDir
          const updatedPage = { ...pageState }
          if (updatedPage && updatedPage.sorting) {
            updatedPage.sorting.sortDirection = capitalizeFirstLetter(sortDir)
            updatedPage.sorting.sortBy = capitalizeFirstLetter(columnName)
          }
          setPageState(updatedPage)
        } else {
          item.sortDirection = SortDirectionType.None
        }
        return item
      })
    )
  }

  const updatePage = (value: QueryFilter) => {
    postOrganisationFilter(value)
      .then((response) => {
        const typedOrg = response.data as IOrganisationPayload
        typedOrg.organisations &&
          typedOrg.organisations.length > 0 &&
          typedOrg.organisations.forEach((item: IOrganisation) => {
            if (
              isArray(item.contact) &&
              item.contact[0] &&
              item.contact[0].address
            ) {
              let contactAddress = item?.contact[0].address.fullAddress
              if (contactAddress) {
                item.headerAddress = contactAddress
              } else {
                item.headerAddress = ''
              }
            }
            return item
          })
        setOrgState(typedOrg)
        setIsLoading(false)
      })
      .catch((error) => {
        setError({
          hasError: true,
          errorMessage: 'failed to post customer'
        })
        analyticsServiceSingleton.trackError(error)
      })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.currentPage = newPage
      setPageState(newPageState)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageState = { ...pageState }
    if (newPageState.pagination) {
      newPageState.pagination.count = parseInt(event.target.value, 10)
      setPageState(newPageState)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePage(pageState)
    }, 60000)
    return () => clearInterval(interval)
  }, [pageState])

  useEffect(() => {
    updatePage(pageState)
  }, [pageState])

  useEffect(() => {
    const searchValue = tableHeaders.find((item) => item.searchValue !== '')
    let filter = {
      filterBy: '',
      filterValue: ''
    }
    if (searchValue && searchValue.searchValue) {
      filter = {
        filterBy: searchValue.name,
        filterValue: searchValue.searchValue
      }
    }

    filter && setSearchQuery(filter)
  }, [tableHeaders, setSearchQuery])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
  }

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Grid
          className="organisations-list-container"
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          style={{ overflow: 'auto' }}
        >
          <Grid item style={{ maxWidth: '100%' }}>
            {orgState && pageState && pageState.pagination && (
              <>
                <OrganisationsList
                  tableHeaders={tableHeaders}
                  requestSort={(columnName, sortDirection) =>
                    handleSortRequest(columnName, sortDirection)
                  }
                  requestSearch={(tableHeaders) =>
                    handleSearchRequest(tableHeaders)
                  }
                  organisations={orgState.organisations}
                />

                {pageState.pagination.count && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={orgState.total}
                    rowsPerPage={pageState.pagination.count}
                    page={orgState.currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

import axios from 'axios'
import { Log, User, UserManager, UserManagerSettings } from 'oidc-client'
import config from './ConfigProvider'

const { stsAuthority, clientId, clientRoot, clientScope } = config
const settings: UserManagerSettings = {
  authority: stsAuthority, // eslint-disable-line
  client_id: clientId, // eslint-disable-line
  redirect_uri: `${clientRoot}signin-callback.html`, // eslint-disable-line
  silent_redirect_uri: `${clientRoot}silent-renew.html`, // eslint-disable-line
  post_logout_redirect_uri: `${clientRoot}`, // eslint-disable-line
  response_type: 'code', // eslint-disable-line
  scope: clientScope
}

class AuthService {
  userManager: UserManager = new UserManager(settings)
  public isAuthenticated: boolean = false

  constructor() {
    Log.logger = console
    Log.level = Log.NONE // TODO: Set log level dependant on environment
  }

  getUserAsync = async (): Promise<User | null> => {
    return this.userManager.getUser()
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser().then((user) => {
      this.isAuthenticated = !!user
      // Set headers for all subsequent calls
      if (user && user.access_token) {
        axios.defaults.headers = {
          Accept: 'application/json',
          Authorization: `Bearer ${user.access_token}`
        }
      }
      return user
    })
  }

  public login(url?: string): Promise<void> {
    return this.userManager.signinRedirect(url && { state: url })
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent()
  }

  renewTokenAsync = async (): Promise<User> => {
    return this.userManager.signinSilent()
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }
}

const authService = new AuthService()
export default authService

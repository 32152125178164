import { Dictionary } from '../../types'
import { AnalyticsEvent } from './AnalyticsEvent'

/**
 * AnalyticsService
 * Use for tracking page views, events and errors
 * Register each analytics provider with its custom implementation of each method call
 * Any new provider must implement IAnalyticsProvider interface
 *
 * @usage Use the AnalyticsHelper component outside the switch statement of the router
 * <main role="main">
 *   <AnalyticsHelper />
 *   <Switch>
 *     <Route path="/" exact component={LogIn} />
 *
 * To instantiate the service with any number of providers
 * const mp = new MixPanelProvider()
 * mp.tokenId = config.mixPanelToken
 * analyticsService.registerProvider('MixPanel', mp)
 *
 * const gap = new GoogleAnalyticsManagerProvider()
 * gap.tokenId = config.gaId
 * analyticsService.registerProvider('GoogleAnalyticsManagerProvider',gap)
 * etc...
 *
 * Then call init on all providers
 * analyticsService.initProviders()
 *
 */

export interface IAnalyticsProvider extends Dictionary {
  tokenId: string
  initProvider(): void
  trackEvent(event: AnalyticsEvent, properties?: object): void
  trackPageView(path: string): void
  trackError(error: Error): void
}

class AnalyticsService implements IAnalyticsProvider {
  /** A dictionary of all providers with their name as key */
  providers: { [key: string]: IAnalyticsProvider } = {}
  /** Show logging for all events */
  enableDebugLogging: boolean
  /** Send tracking data */
  sendTrackingData: boolean
  /** The token id for the current service */
  tokenId: string = ''

  constructor(
    enableDebugLogging: boolean = false,
    sendTrackingData: boolean = true
  ) {
    this.enableDebugLogging = enableDebugLogging
    this.sendTrackingData = sendTrackingData
    !this.sendTrackingData && console.warn(`Analytics disabled!`)
  }

  registerProvider = (providerName: string, provider: IAnalyticsProvider) => {
    if (!this.sendTrackingData) {
      return
    }
    this.logEvent(`AnalyticsService registerProvider ${providerName}`)
    this.providers[providerName] = provider
  }

  initProviders = () => {
    this.logEvent(`AnalyticsService initProvider`)
    for (const provider of Object.keys(this.providers)) {
      const providerInstance: IAnalyticsProvider = this.providers[provider]
      providerInstance && providerInstance.initProvider()
    }
  }

  initProvider = () => {
    // Not implemented in base class
  }

  trackEvent = (event: AnalyticsEvent, properties?: object) => {
    this.logEvent(`AnalyticsService trackEvent: ${event}, ${properties}`)

    for (const provider of Object.keys(this.providers)) {
      this.providers[provider].trackEvent(event, properties)
    }
  }

  trackPageView = (path: string) => {
    this.logEvent(`AnalyticsService trackPageView: ${path}`)
    for (const provider of Object.keys(this.providers)) {
      this.providers[provider].trackPageView(path)
    }
  }

  trackError(error: Error) {
    this.logEvent(`AnalyticsService trackError: ${error.message}`)
    for (const provider of Object.keys(this.providers)) {
      this.providers[provider].trackError(error)
    }
  }

  logEvent = (message: string) => {
    this.enableDebugLogging && console.log(message)
  }
}

export default AnalyticsService

/**
 * AnalyticsEvent
 *
 * Provide as many custom events as you wish to pass for your project
 */
export enum AnalyticsEvent {
  LoginSuccess = 'successful_login',
  LoginFail = 'failed_login',
  InviteSent = 'invite_sent',
  InviteSentError = 'invite_failed_to_send',
  RequestRepeatOrder = 'request_repeat_order'
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { IOrder, IError, OrderStatusModel, ISuccess } from '../../../../models'
import {
  getOrderById,
  getStatusOptions,
  putOrderStatus,
  putOrderNote,
  cancelGetOrderById,
  cancelPutOrderStatus,
  cancelGetStatusOptions
} from '../../../../services/ApiService'
import { AdminOrderDetail } from '../Admin/AdminOrderDetail'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { TOrderStatus, findOrderStatusTypeNumber } from '../../../../types'

interface IOrderRouteParams {
  /** The id of this feature passed through via the route params */
  id: string
}
interface IOrderDetailContainerProps
  extends RouteComponentProps<IOrderRouteParams> {}

export const AdminOrderFormContainer: FunctionComponent<IOrderDetailContainerProps> = ({
  match
}) => {
  const [order, setOrder] = useState<IOrder | undefined>(undefined)
  const [orderStatusOptions, setOrderStatusOptions] = useState<
    OrderStatusModel[]
  >()
  const [isRequestingStatus, setIsRequestingStatus] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [success, setSuccess] = useState<ISuccess>({
    hasSuccess: false
  })
  const [error, setError] = useState<IError>({
    hasError: false
  })
  const history = useHistory()
  const { id } = match.params

  const handleSubmission = (note: string | '') => {
    let errors: string[] = []
    order &&
      note !== undefined &&
      putOrderNote(order.id, note)
        .then(() => history.push('/orders'))
        .catch((errorMessage) => {
          errors = [...errors, errorMessage]
          setError({
            hasError: true,
            errorMessage: 'failed to post order status'
          })
        })
    if (errors.length === 0) {
      history.push('/orders')
    }
  }

  const handleStatusChangeRequest = (value: TOrderStatus) => {
    if (order) {
      setIsRequestingStatus(true)
      const orderStatusNumber = findOrderStatusTypeNumber[value]
      putOrderStatus(order.id, orderStatusNumber)
        .then((response) => {
          response.data === true &&
            setSuccess({
              hasSuccess: true,
              successMessage: `The status has been updated successfully`
            })
          setIsRequestingStatus(false)
        })
        .then(() => {
          getOrderById(id)
            .then((response) => {
              setOrder(response.data)
            })
            .then(() => {
              getStatusOptions(parseInt(id))
                .then((response) => {
                  setOrderStatusOptions(response.data)
                  setIsLoading(false)
                })
                .catch((errorMessage) => {
                  console.warn(errorMessage)
                  setError({
                    hasError: true,
                    errorMessage: 'failed to get order status options'
                  })
                })
            })
            .catch((errorMessage) => {
              console.warn(errorMessage)
              setError({
                hasError: true,
                errorMessage: 'failed to get order by id'
              })
            })
        })
        .catch((errorMessage) => {
          console.warn(errorMessage)
          setError({
            hasError: true,
            errorMessage: 'failed to modify user status'
          })
        })
    }
  }

  useEffect(() => {
    getOrderById(id)
      .then((response) => {
        setOrder(response.data)
      })
      .then(() => {
        getStatusOptions(parseInt(id))
          .then((response) => {
            setOrderStatusOptions(response.data)
            setIsLoading(false)
          })
          .catch((errorMessage) => {
            console.warn(errorMessage)
            setError({
              hasError: true,
              errorMessage: 'failed to get order status options'
            })
          })
      })
      .catch((errorMessage) => {
        console.warn(errorMessage)
        setError({
          hasError: true,
          errorMessage: 'failed to get order by id'
        })
      })
  }, [id])

  const handleNotificationClose = () => {
    setError({ hasError: false, errorMessage: '' })
    setSuccess({ hasSuccess: false, successMessage: '' })
  }

  useEffect(() => {
    return () => {
      cancelGetOrderById()
      cancelPutOrderStatus()
      cancelGetStatusOptions()
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && order && orderStatusOptions !== undefined && (
        <AdminOrderDetail
          isRequestingStatus={isRequestingStatus}
          order={order}
          orderStatusOptions={orderStatusOptions}
          submitOrder={handleSubmission}
          requestStatusChange={handleStatusChangeRequest}
        />
      )}
      {success.hasSuccess && (
        <Snackbar
          open={success.hasSuccess}
          autoHideDuration={3000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="success">
            {success.successMessage}
          </Alert>
        </Snackbar>
      )}

      {error.hasError && (
        <Snackbar
          open={error.hasError}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity="error">
            {error.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

import { QueryFilter } from '../models'
import { capitalizeFirstLetter } from '../utility'
import { SortDirectionType } from '../types'

export const InitialState: QueryFilter = {
  sorting: {
    sortBy: 'Id',
    sortDirection: capitalizeFirstLetter(SortDirectionType.Ascending)
  },
  pagination: {
    currentPage: 0,
    pageCount: 0,
    count: 10
  },
  filters: [
    {
      filterBy: '',
      filterValue: ''
    }
  ]
}

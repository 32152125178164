import React, { FunctionComponent, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { ThemeOptions } from '@material-ui/core'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createAsepticTheme } from './aseptic.theme'
import {
  OrderDetailContainer,
  OrdersContainer,
  ProductsListContainer,
  ProductDetailContainer,
  NewProductDetailContainer,
  UserDetailContainer,
  UsersListContainer,
  NewUserDetailContainer,
  AdminOrdersContainer,
  AdminOrderFormContainer
} from './pages'
import { NotFound } from './pages/NotFound'
import { ASMainMenu } from './components/ASMainMenu/ASMainMenu'
import {
  OrganisationsContainer,
  OrganisationDetailContainer,
  NewOrganisationDetailContainer
} from './pages/Organisations'
import { ASFooter } from './components/ASFooter'
import { LogIn } from './pages/LogIn'
import authService from './services/AuthService'
import { TUser, User, findUserType } from './types'
import { AnalyticsHelper } from './services/Analytics/AnalyticsHelper'

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
  }
  body {
    overflow-x: hidden;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  @media print{
    @page {
      size: A4 landscape;
    }
    body  {
      height: auto;
      width: 100%;
    }
  }
`

const themeOpts: ThemeOptions = {}
let asepticTheme = createAsepticTheme(themeOpts)
asepticTheme = responsiveFontSizes(asepticTheme)

const App: FunctionComponent = () => {
  const [userType, setUserType] = useState<TUser>(User.Customer)

  useEffect(() => {
    authService.getUser().then((response) => {
      if (response && response.profile && response.profile.role) {
        setUserType(findUserType[response.profile.role])
      }
    })
  }, [])

  return (
    <Router>
      <MuiThemeProvider theme={asepticTheme}>
        <CssBaseline>
          <GlobalStyle />
          <ASMainMenu isAdmin={userType === User.Admin ? true : false}>
            <AnalyticsHelper />

            <Switch>
              <Route path="/login" exact={true} component={LogIn} />

              {userType && userType === User.Customer && (
                <>
                  <Route
                    path="/orders"
                    exact={true}
                    component={OrdersContainer}
                  />
                  <Route
                    path="/orders/:id"
                    exact={true}
                    component={OrderDetailContainer}
                  />
                  <Route
                    path="/orders/new"
                    exact={true}
                    component={OrderDetailContainer}
                  />
                </>
              )}
              {userType && userType === User.Admin && (
                <>
                  <Route
                    path="/orders"
                    exact={true}
                    component={AdminOrdersContainer}
                  />
                  <Route
                    path="/orders/:id"
                    exact={true}
                    component={AdminOrderFormContainer}
                  />
                  <Route
                    path="/products"
                    exact={true}
                    component={ProductsListContainer}
                    isNew={true}
                  />
                  <Route
                    path="/products/new"
                    exact={true}
                    component={NewProductDetailContainer}
                  />
                  <Route
                    path="/products/:id"
                    exact={true}
                    component={ProductDetailContainer}
                  />
                  <Route
                    path="/customers"
                    exact={true}
                    component={OrganisationsContainer}
                  />
                  <Route
                    path="/customers/:id"
                    exact={true}
                    component={OrganisationDetailContainer}
                  />
                  <Route
                    path="/customers/new"
                    exact={true}
                    component={NewOrganisationDetailContainer}
                  />
                  <Route
                    path="/users/:id"
                    exact={true}
                    component={UserDetailContainer}
                  />
                  <Route
                    path="/users/new"
                    exact={true}
                    component={NewUserDetailContainer}
                  />
                  <Route
                    path="/users"
                    exact={true}
                    component={UsersListContainer}
                  />
                </>
              )}
              <Route path="*" exact={true} component={NotFound} />
            </Switch>
          </ASMainMenu>
          <ASFooter />
        </CssBaseline>
      </MuiThemeProvider>
    </Router>
  )
}

export default App

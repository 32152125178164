import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography, Button, Avatar } from '@material-ui/core'
import { IOrderList, ITableHeader } from '../../../models'
import { TSortDirection } from '../../../types/SortTypes'
import { ASCustomerOrderTable } from '../../../components/ASCustomerOrderTable'

interface IOrderListsProps {
  /* Requires an array of Orders */
  orders: IOrderList[]
  /* Table header values are provided by its parent including sort direction state */
  tableHeaders: ITableHeader[]
  /* value is provided by parent to display active orders */
  activeCount?: number
  /* Provides the parent with an updated table header which includes a change sort direction */
  requestSort: (columnName: string, sortDirection: TSortDirection) => void
  /* Provides the parent updated table headers that include search values */
  requestSearch: (tableHeaders: ITableHeader[]) => void
}

export const Orders: FunctionComponent<IOrderListsProps> = ({
  orders,
  tableHeaders,
  activeCount,
  requestSort,
  requestSearch
}) => {
  const useStyles = makeStyles((theme) => ({
    orderCounter: {
      margin: theme.spacing(0),
      fontSize: theme.typography.fontSize,
      backgroundColor: theme.palette.primary.main,
      width: '30px',
      height: '30px'
    }
  }))
  const classes = useStyles()

  return (
    <>
      {orders && (
        <>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item lg={7}>
                <Typography variant="body1">
                  You have
                  <Box ml={1} mr={1} component="span" display="inline-flex">
                    <Avatar variant="circle" className={classes.orderCounter}>
                      {activeCount ? activeCount : 0}
                    </Avatar>
                  </Box>
                  active order{activeCount && activeCount > 1 ? 's' : ''}
                </Typography>
              </Grid>
              <Grid item lg={5}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignContent="center"
                >
                  <Button
                    disableElevation={true}
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/orders/new"
                  >
                    New order
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <ASCustomerOrderTable
            tableHeaders={tableHeaders}
            requestSort={(columnName, sortDirection) =>
              requestSort(columnName, sortDirection)
            }
            requestSearch={(tableHeaders) => requestSearch(tableHeaders)}
            orders={orders}
          />
        </>
      )}
    </>
  )
}

import { Dictionary } from '.'

export enum ProductExpiryValues {
  Days = 'Days',
  Hours = 'Hours'
}
export type TProductExpiryValues =
  | ProductExpiryValues.Days
  | ProductExpiryValues.Hours

export const findProductExpiryValuesNumber: Dictionary<number> = {
  [ProductExpiryValues.Days]: 1,
  [ProductExpiryValues.Hours]: 2
}

export const findProductExpiryValuesByNumber: Dictionary<TProductExpiryValues> = {
  1: ProductExpiryValues.Days,
  2: ProductExpiryValues.Hours
}

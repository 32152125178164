import React, { FunctionComponent } from 'react'
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  useTheme,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { object, array, string, number, date } from 'yup'
import {
  IOrganisation,
  OrganisationModel,
  OrganisationContactModel
} from '../../../models'
import { isArray } from 'util'

export interface IOrganisationContact extends OrganisationContactModel {
  [key: string]: any
}

interface IOrganisationDetailProps {
  // The organisation passed down into the detail
  organisation?: OrganisationModel
  // Whether the organisation is being edited or not
  isEditing?: boolean
  isNewOrganisation?: boolean
  toggleEditing?: () => void
  submitOrganisation: (org: OrganisationModel) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

const blankOrganisation: any = {
  id: 0,
  name: '',
  hospitalNumber: '',
  contact: [
    {
      id: 0,
      contactTypeId: 0,
      addressId: 0,
      contactType: {
        id: 0,
        description: '',
        order: 0
      },
      address: {
        id: 0,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        postCode: '',
        phoneNumber: '',
        contactPerson: '',
        fullAddress: ''
      }
    }
  ],
  lastUpdated: '0001-01-01T00:00:00'
}

const errorMessage = {
  dropdown: 'Please select an option',
  required: 'Required field'
}

const organisationSchema = object().shape({
  id: string(),
  name: string().trim().required(errorMessage.required),
  description: string().trim(),
  contact: array()
    .compact()
    .of(
      object().shape({
        id: string().trim(),
        contactTypeId: number(),
        addressId: number(),
        contactType: object().shape({
          id: number(),
          description: string().trim(),
          order: number()
        }),
        address: object().shape({
          id: number(),
          addressLine1: string().trim().required(errorMessage.required),
          addressLine2: string().trim(),
          addressLine3: string().trim(),
          town: string().trim().required(errorMessage.required),
          county: string().trim().required(errorMessage.required),
          postCode: string().trim().required(errorMessage.required),
          phoneNumber: string().trim().required(errorMessage.required),
          contactPerson: string().trim().required(errorMessage.required)
        })
      })
    ),
  lastUpdated: date()
})

export const OrganisationDetail: FunctionComponent<IOrganisationDetailProps> = ({
  organisation = blankOrganisation,
  isEditing,
  isNewOrganisation,
  toggleEditing,
  submitOrganisation
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const { register, handleSubmit, errors, formState } = useForm<IOrganisation>({
    defaultValues: organisation,
    resolver: yupResolver(organisationSchema)
  })

  // const watchAllFields = watch()

  const isSubmitDisabled =
    Object.entries(formState.dirtyFields).length === 0 && !formState.isValid

  const contactErrorValues =
    errors &&
    isArray(errors.contact) &&
    errors.contact[0] !== undefined &&
    errors?.contact[0]

  const orgContact = organisation.contact.filter(
    (item: OrganisationContactModel, index: number) => {
      if (index === organisation.contact.length - 1) {
        return item
      } else return null
    }
  )

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={theme.breakpoints.values.md}
      width="100%"
      marginX="auto"
      mb={theme.spacing(1)}
    >
      <form onSubmit={handleSubmit(submitOrganisation)}>
        <Box pt={2}>
          <Box>
            <Button
              startIcon={<LeftIcon />}
              onClick={() => history.push('/customers')}
            >
              Go Back
            </Button>
          </Box>
          <Box p={2} pl={0}>
            {!isNewOrganisation && organisation.id && (
              <Typography variant="h4" color="primary">
                Reference: {organisation.id}
              </Typography>
            )}
            {isNewOrganisation && (
              <Typography variant="h4" color="primary">
                New Customer
              </Typography>
            )}
          </Box>

          {orgContact && (
            <>
              <Box
                bgcolor={theme.palette.text.hint}
                borderRadius={3}
                p={2}
                mb={2}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="id"
                      inputRef={register({})}
                      style={{ display: 'none' }}
                    />
                    <TextField
                      name="hospitalNumber"
                      inputRef={register({})}
                      value=""
                      style={{ display: 'none' }}
                    />

                    <TextField
                      name="name"
                      inputRef={register({})}
                      fullWidth
                      label="Name"
                      variant="outlined"
                      disabled={!isEditing}
                      error={errors.name ? true : false}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.contactPerson`}
                      inputRef={register({})}
                      fullWidth
                      label="Contact Person"
                      variant="outlined"
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.contactPerson
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.contactPerson?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.phoneNumber`}
                      inputRef={register({})}
                      type="tel"
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.phoneNumber
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.phoneNumber?.message
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                bgcolor={theme.palette.text.hint}
                borderRadius={3}
                p={2}
                mb={2}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name={`contact[${organisation.contact.length - 1}].id`}
                      inputRef={register({})}
                      style={{ display: 'none' }}
                    />
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.id`}
                      inputRef={register({})}
                      style={{ display: 'none' }}
                    />
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.addressLine1`}
                      inputRef={register({})}
                      variant="outlined"
                      label="Address Line 1"
                      fullWidth
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.addressLine1
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.addressLine1?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.addressLine2`}
                      inputRef={register({})}
                      variant="outlined"
                      label="Address Line 2"
                      fullWidth
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.addressLine2
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.addressLine2?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.addressLine3`}
                      inputRef={register({})}
                      variant="outlined"
                      label="Address Line 3"
                      fullWidth
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.addressLine3
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.addressLine3?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.town`}
                      inputRef={register({})}
                      variant="outlined"
                      label="Town"
                      fullWidth
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.town
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.town?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.county`}
                      inputRef={register({})}
                      variant="outlined"
                      label="County"
                      fullWidth
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.county
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.county?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name={`contact[${
                        organisation.contact.length - 1
                      }].address.postCode`}
                      inputRef={register({})}
                      variant="outlined"
                      label="Postcode"
                      fullWidth
                      disabled={!isEditing}
                      error={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.postCode
                          ? true
                          : false
                      }
                      helperText={
                        contactErrorValues &&
                        contactErrorValues.address &&
                        contactErrorValues.address.postCode?.message
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {!isNewOrganisation && (
            <Box
              borderColor={theme.palette.grey[200]}
              border={1}
              borderRadius={3}
              p={2}
              mb={2}
            >
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    value={moment(organisation.lastUpdated).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                    variant="outlined"
                    id="last-updated"
                    fullWidth
                    label="Last updated"
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Grid
            style={{ flexGrow: 1, justifyContent: 'flex-end' }}
            container
            spacing={3}
          >
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => history.goBack()}
                disableElevation={true}
                variant="outlined"
              >
                GO BACK
              </Button>
              {isEditing && (
                <Button
                  type="submit"
                  disableElevation={true}
                  variant="contained"
                  disabled={isSubmitDisabled}
                  color={
                    formState.touched && isEditing && !formState.isSubmitted
                      ? 'primary'
                      : 'default'
                  }
                >
                  Save & exit
                </Button>
              )}
              {!isEditing && (
                <Button
                  color="primary"
                  onClick={() => toggleEditing && toggleEditing()}
                  disableElevation={true}
                  variant="contained"
                >
                  edit
                </Button>
              )}
            </div>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}
